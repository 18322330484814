export const accountData=[];

export const availableAccountData  = [
    {
        "account_created": "2021-04-26T11:00:32.835976-07:00",
        "account_full_name": "Unit Test Base",
        "account_id": 109,
        "account_properties": {
            "Description": "Exploring methods for compressing all document and query structures into a tree hierarchy.",
            "Purpose": "Testing Account for Tree Hierarchy Structure.",
            "a": "a",
            "c": "c"
        },
        "account_schema_name": "account_af4c8911_f7f8_4841_81d0_c49b9e0d0bdd",
        "account_short_name": "UTB",
        "account_updated": "2021-04-15T19:51:15.090097-07:00"
    },
    {
        "account_created": "2021-06-02T13:46:02.737129-07:00",
        "account_full_name": "Canadian Natural",
        "account_id": 190,
        "account_properties": {
            "Description": "PVL Services for multiple projects."
        },
        "account_schema_name": "account_64abda01_f83f_47c4_b1ab_9719a4620e6e",
        "account_short_name": "CNRL",
        "account_updated": "2021-08-02T13:46:02.737129-07:00"
    },
    {
        "account_created": "2021-06-24T15:21:50.916795-07:00",
        "account_full_name": "NRG Process Solutions",
        "account_id": 240,
        "account_properties": {
            "Office Address": "3605 29th Street NE, Calgary, Alberta, Canada",
            "Phone": "(403) 945 3561",
            "Website": "https://nrgps.com/",
            "email": "info@nrgps.com"
        },
        "account_schema_name": "account_e54b0599_ac2c_47a3_9a6d_d10b624eb307",
        "account_short_name": "NRG",
        "account_updated": "2021-06-24T15:21:50.916795-07:00"
    },
    {
        "account_created": "2021-06-25T07:37:48.793255-07:00",
        "account_full_name": "nathanTestAccount1",
        "account_id": 241,
        "account_properties": {
            "a": "b"
        },
        "account_schema_name": "account_7001d0dd_efcb_476b_974c_3545df2d226b",
        "account_short_name": "nta1",
        "account_updated": "2021-06-25T07:37:48.793255-07:00"
    },
    {
        "account_created": "2021-06-25T09:39:03.57839-07:00",
        "account_full_name": "testAccount 2",
        "account_id": 242,
        "account_properties": {
            "a": "a"
        },
        "account_schema_name": "account_3cfa34fd_ba21_4dc9_8f1c_f56a82073e06",
        "account_short_name": "TA2",
        "account_updated": "2021-06-25T09:39:03.57839-07:00"
    },
    {
        "account_created": "2021-06-25T09:47:44.765223-07:00",
        "account_full_name": "nathanTestAccount2",
        "account_id": 243,
        "account_properties": {
            "b": "c"
        },
        "account_schema_name": "account_0f27afbc_b8bb_4f83_aa21_12b275d7e112",
        "account_short_name": "nta2",
        "account_updated": "2021-06-25T09:47:44.765223-07:00"
    },
    {
        "account_created": "2021-06-27T09:21:04.143543-07:00",
        "account_full_name": "Larry2",
        "account_id": 245,
        "account_properties": {
            "a": "a"
        },
        "account_schema_name": "account_06f3477c_79bf_400c_b7a2_cb89d7fea138",
        "account_short_name": "lfs2",
        "account_updated": "2021-06-27T09:21:04.143543-07:00"
    },
    {
        "account_created": "2021-06-29T10:05:47.508821-07:00",
        "account_full_name": "LL",
        "account_id": 246,
        "account_properties": {},
        "account_schema_name": "account_a447c033_0384_492a_b3ae_247565263a29",
        "account_short_name": "LL",
        "account_updated": "2021-06-29T10:05:47.508821-07:00"
    },
    {
        "account_created": "2021-06-29T15:51:54.686234-07:00",
        "account_full_name": "ABC Test",
        "account_id": 247,
        "account_properties": {},
        "account_schema_name": "account_303581ac_8a8d_4774_9801_887ee9a24ae9",
        "account_short_name": "ABC",
        "account_updated": "2021-06-29T15:51:54.686234-07:00"
    },
    {
        "account_created": "2021-06-30T12:34:09.535756-07:00",
        "account_full_name": "",
        "account_id": 249,
        "account_properties": {},
        "account_schema_name": "account_403f4c13_f685_4275_9404_2dc0e520eb6b",
        "account_short_name": "lfs1",
        "account_updated": "2021-06-30T12:34:22.472422-07:00"
    },
    {
        "account_created": "2021-07-02T10:42:44.104053-07:00",
        "account_full_name": "Research - Global Temperatures",
        "account_id": 250,
        "account_properties": {
            "Dataset": "Terrestrial Monthly Air Temperature Series",
            "Source": "www.kaggle.com"
        },
        "account_schema_name": "account_75271386_1e0b_458c_ad94_cbf3f4363d6d",
        "account_short_name": "Research - GT",
        "account_updated": "2021-07-02T10:42:44.104053-07:00"
    },
    {
        "account_created": "2021-07-06T08:48:27.661038-07:00",
        "account_full_name": "AA",
        "account_id": 256,
        "account_properties": {},
        "account_schema_name": "account_366a6a47_c1ff_4473_aa13_794196fa7c00",
        "account_short_name": "AA",
        "account_updated": "2021-07-06T08:48:27.661038-07:00"
    },
    {
        "account_created": "2021-07-06T08:50:04.142309-07:00",
        "account_full_name": "ZZZ",
        "account_id": 257,
        "account_properties": {},
        "account_schema_name": "account_69f40868_f1dd_42b7_8dc2_e61a24b2a224",
        "account_short_name": "AAA",
        "account_updated": "2021-07-06T08:50:04.142309-07:00"
    },
    {
        "account_created": "2021-07-07T09:23:16.66946-07:00",
        "account_full_name": "Demonstration Account",
        "account_id": 258,
        "account_properties": {},
        "account_schema_name": "account_099ad235_71f5_45db_ab69_6e6fedc7415b",
        "account_short_name": "DA",
        "account_updated": "2021-07-07T09:23:16.66946-07:00"
    }
];

export const newAccountData  = [
    {
        "account_created": "2021-05-23T11:00:32.835976-07:00",
        "account_full_name": "ABC Compnay",
        "account_id": 300,
        "account_properties": {
            "a": "a",
            "c": "c"
        },
        "account_schema_name": "account_af4c8911_f7f8_4841_81d0_c44dde0d0bdd",
        "account_short_name": "ABC",
        "account_updated": "2021-05-23T11:00:32.835976-07:00"
    },
    {
        "account_created": "2021-05-23T11:00:32.835976-07:00",
        "account_full_name": "GMO Compnay",
        "account_id": 301,
        "account_properties": {
            "b": "b"
        },
        "account_schema_name": "account_64abda01_f55f_47c4_b1ab_9719a4aa0e6e",
        "account_short_name": "GMO",
        "account_updated": "2021-05-23T11:00:32.835976-07:00"
    },
    {
        "account_created": "2021-05-23T11:00:32.835976-07:00",
        "account_full_name": "xyz",
        "account_id": 303,
        "account_properties": {
            "xyz": "xyz",
            "c": "c",
        },
        "account_schema_name": "account_e5cc0599_ac2c_47a3_9a6d_d10b624eb307",
        "account_short_name": "XYZ",
        "account_updated": "2021-05-23T11:00:32.835976-07:00"
    },
    {
        "account_created": "2021-05-23T11:00:32.835976-07:00",
        "account_full_name": "newAccount04",
        "account_id": 304,
        "account_properties": {
            "a": "b"
        },
        "account_schema_name": "account_700199dd_efcb_476b_974c_3545dfcc226b",
        "account_short_name": "NA04",
        "account_updated": "2021-05-23T11:00:32.835976-07:00"
    },
    {
        "account_created": "2021-05-23T11:00:32.835976-07:00",
        "account_full_name": "test Account 04",
        "account_id": 306,
        "account_properties": {
            "a": "a"
        },
        "account_schema_name": "account_3cfaa4fd_ba21_4dc9_8f1c_f56a82073e06",
        "account_short_name": "TA04",
        "account_updated": "2021-05-23T11:00:32.835976-07:00"
    },
];

