export const userInfo = [
  {
    registry_created: "2021-07-07 09:22:28.332008-07:00",
    registry_email: "user@demo.com",
    registry_full_name: "Demonstration User",
    registry_id: 365,
    registry_properties: {},
    registry_updated: "2021-07-07 09:22:28.598460-07:00",
    rolvaliduntil: "2022-07-22 09:22:28.332008-07:00",
  },
  {
    registry_created: "2021-06-02 15:00:38.925528-07:00",
    registry_email: "tmc.user3@tmc.com",
    registry_full_name: "TMC User 4",
    registry_id: 252,
    registry_properties: {
      a: "a",
      asd: "qqww",
      c: "c",
    },
    registry_updated: "2021-06-18 13:03:18.578476-07:00",
    rolvaliduntil: "2022-06-17 15:00:38.925528-07:00",
  },
  {
    registry_created: "2021-06-02 14:51:20.866110-07:00",
    registry_email: "tmc.user2@tmc.com",
    registry_full_name: "Tmc User2",
    registry_id: 251,
    registry_properties: {
      a: "a",
    },
    registry_updated: "2021-06-24 15:24:48.902846-07:00",
    rolvaliduntil: "2022-06-17 14:51:20.866110-07:00",
  },
  {
    registry_created: "2021-06-02 14:50:21.527841-07:00",
    registry_email: "tmc.user1@tmc.com",
    registry_full_name: "TMC User1",
    registry_id: 250,
    registry_properties: {
      a: "a",
      c: "d",
      s: "s",
    },
    registry_updated: "2021-06-24 15:32:06.991708-07:00",
    rolvaliduntil: "2021-06-26 15:53:10-07:00",
  },
  {
    registry_created: "2021-05-12 08:38:12.476687-07:00",
    registry_email: "testuser4@test.com",
    registry_full_name: "Test User 4",
    registry_id: 225,
    registry_properties: {},
    registry_updated: "2021-06-18 12:41:33.385254-07:00",
    rolvaliduntil: "2022-05-27 08:38:12.476687-07:00",
  },
];

export const UserData = [];

export const NewUserData = [
  {
    registry_id: 359,
    registry_email: "zyx.123@test.com",
    registry_full_name: "ZYX 123",
    registry_properties: {},
    registry_created: "2021-06-29 16:03:59.483517-07:00",
    registry_updated: "2021-06-29 16:04:00.115323-07:00",
    rolvaliduntil: "2022-07-14 16:03:59.483517-07:00",
  },
  {
    registry_id: 215,
    registry_email: "user@utb.com",
    registry_full_name: "UNIT TEST BASE User",
    registry_properties: {
      Purpose: "Demo User for UNIT TEST BASE Account.",
      Rank: "Developer",
      a: "a",
      d: "c",
    },
    registry_created: "2021-04-26 11:22:24.537395-07:00",
    registry_updated: "2021-06-22 17:12:32.691801-07:00",
    rolvaliduntil: "2021-12-15 11:11:09-07:00",
  },
  {
    registry_id: 221,
    registry_email: "user@nrgps.com",
    registry_full_name: "NRG Demo User",
    registry_properties: {
      Purpose: "Demo User for NRG Account.",
    },
    registry_created: "2021-05-02 11:51:28.549617-07:00",
    registry_updated: "2021-06-18 13:04:23.174195-07:00",
    rolvaliduntil: "2022-05-17 11:51:28.549617-07:00",
  },
  {
    registry_id: 365,
    registry_email: "user@demo.com",
    registry_full_name: "Demonstration User",
    registry_properties: {},
    registry_created: "2021-07-07 09:22:28.332008-07:00",
    registry_updated: "2021-07-07 09:22:28.598460-07:00",
    rolvaliduntil: "2022-07-22 09:22:28.332008-07:00",
  },
  {
    registry_id: 230,
    registry_email: "test.v6@test.com",
    registry_full_name: "Test User v6 changed",
    registry_properties: {
      1: "1",
      2: "2",
      "Gas card": "$500.00 limit",
      Vehicle: "Ford F250",
      Y: "Y",
      Z: "Z",
      a: "a",
      cell: "403-555-4444",
      "h2s alive": "2021-07-15",
      s: "s",
      skills: "pipefitting, gasfitting",
    },
    registry_created: "2021-05-12 08:41:45.556515-07:00",
    registry_updated: "2021-06-22 23:00:55.095645-07:00",
    rolvaliduntil: "2021-07-01 09:48:50-07:00",
  },
];

export const AvailableUserData = [
  {
    registry_id: 351,
    registry_email: "testuse88@test.com",
    registry_full_name: "YU88",
    registry_properties: {
      a: "a",
    },
    registry_created: "2021-06-25 08:13:40.349905-07:00",
    registry_updated: "2021-06-25 08:13:41.000968-07:00",
    rolvaliduntil: "2022-07-10 08:13:40.349905-07:00",
  },
  {
    registry_id: 246,
    registry_email: "pm.canadiannatural@qu-br.com",
    registry_full_name: "Project Manager",
    registry_properties: {
      Purpose: "Project Manager User for Canadian Natural account.",
    },
    registry_created: "2021-06-02 13:46:03.403096-07:00",
    registry_updated: "2021-06-06 10:34:57.072026-07:00",
    rolvaliduntil: "2022-06-17 13:46:03.403096-07:00",
  },
  {
    registry_id: 357,
    registry_email: "nhbaseuser@test.com",
    registry_full_name: "nathan hopfe",
    registry_properties: {
      test: "value",
    },
    registry_created: "2021-06-28 14:58:18.797562-07:00",
    registry_updated: "2021-06-30 12:12:11.838808-07:00",
    rolvaliduntil: "2022-07-13 14:58:18.797562-07:00",
  },
  {
    registry_id: 356,
    registry_email: "nhaccountadmin@test.com",
    registry_full_name: "nathan hopfe",
    registry_properties: {},
    registry_created: "2021-06-28 14:54:15.980308-07:00",
    registry_updated: "2021-06-28 14:54:16.604700-07:00",
    rolvaliduntil: "2022-07-13 14:54:15.980308-07:00",
  },
  {
    registry_id: 281,
    registry_email: "nathan.hopfe@leanminds.ca",
    registry_full_name: "Nathan Hopfe",
    registry_properties: {
      Company: "Lean Minds Inc.",
      Title: "Developer",
    },
    registry_created: "2021-06-16 14:03:33.829394-07:00",
    registry_updated: "2021-06-24 13:36:20.066128-07:00",
    rolvaliduntil: "9999-12-31 23:59:59.999999+00:00",
  },
  {
    registry_id: 367,
    registry_email: "lfs1@leanminds.ca",
    registry_full_name: "Larry1 Shumlich",
    registry_properties: {},
    registry_created: "2021-08-09 09:03:21.295897-07:00",
    registry_updated: "2021-08-09 09:03:22.428584-07:00",
    rolvaliduntil: "2022-08-24 09:03:21.295897-07:00",
  },
  {
    registry_id: 354,
    registry_email: "larry.user@leanminds.ca",
    registry_full_name: "Larry User",
    registry_properties: {
      a: "a",
    },
    registry_created: "2021-06-27 09:46:50.056757-07:00",
    registry_updated: "2021-06-27 09:46:50.672206-07:00",
    rolvaliduntil: "2022-07-12 09:46:50.056757-07:00",
  },
  {
    registry_id: 319,
    registry_email: "larry.admin@leanminds.ca",
    registry_full_name: "Larry Shumlich",
    registry_properties: {},
    registry_created: "2021-06-18 08:19:31.609735-07:00",
    registry_updated: "2021-06-27 08:24:34.913351-07:00",
    rolvaliduntil: "9999-12-31 23:59:59.999999+00:00",
  },
  {
    registry_id: 355,
    registry_email: "larry.acct@leanminds.ca",
    registry_full_name: "Larry Account",
    registry_properties: {
      a: "a",
    },
    registry_created: "2021-06-27 09:47:26.050240-07:00",
    registry_updated: "2021-06-27 09:47:26.632087-07:00",
    rolvaliduntil: "2022-07-12 09:47:26.050240-07:00",
  },
  {
    registry_id: 353,
    registry_email: "larry2@leanminds.ca",
    registry_full_name: "Larry2 Shumlich",
    registry_properties: {
      a: "a",
    },
    registry_created: "2021-06-27 09:18:26.480370-07:00",
    registry_updated: "2021-06-27 09:18:27.073680-07:00",
    rolvaliduntil: "2022-07-12 09:18:26.480370-07:00",
  },
  {
    registry_id: 280,
    registry_email: "douglas.dougan@leanminds.ca",
    registry_full_name: "Dougas Dougan",
    registry_properties: {
      Company: "Lean Minds Inc.",
      Title: "Developer",
      Skills: "HTML, CSS, Javascript, Typescript, Python, C#, Linux",
    },
    registry_created: "2021-06-16 13:51:36.927064-07:00",
    registry_updated: "2021-06-30 12:48:58.105618-07:00",
    rolvaliduntil: "9999-12-31 23:59:59.999999+00:00",
  },
  {
    registry_id: 214,
    registry_email: "doug.cassidy@pointverge.com",
    registry_full_name: "Doug A Cassidy",
    registry_properties: {
      Director: "President",
      Officer: "CEO",
    },
    registry_created: "2021-04-26 11:18:23.555541-07:00",
    registry_updated: "2021-07-05 12:52:28.454814-07:00",
    rolvaliduntil: "9999-12-31 23:59:59.999999+00:00",
  },
  {
    registry_id: 366,
    registry_email: "aa@test.com",
    registry_full_name: "AA",
    registry_properties: {
      a: "a",
    },
    registry_created: "2021-07-08 10:08:08.576148-07:00",
    registry_updated: "2021-07-08 10:08:09.185098-07:00",
    rolvaliduntil: "2022-07-23 10:08:08.576148-07:00",
  },
  {
    registry_id: 358,
    registry_email: "aaa.123@test.com",
    registry_full_name: "AAA123",
    registry_properties: {},
    registry_created: "2021-06-29 16:03:23.946809-07:00",
    registry_updated: "2021-06-29 16:03:24.591422-07:00",
    rolvaliduntil: "2022-07-14 16:03:23.946809-07:00",
  },
];
