// import csv from 'csv';
import stringify from 'csv-stringify';

export const formatDates = userDate => {
    const date = new Date(userDate);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    const dateString = year + ' / ' + month + ' / ' + day;
    const timeString = hours + ':' + minutes + ':' + seconds;
    return dateString + ' ' + timeString;
};

export const removeOpacityDiv = () => {
    let opacityDiv = document.getElementById('opacityDiv');
    if (opacityDiv) {
        opacityDiv.parentElement.removeChild(opacityDiv);
    }
};

export const yyyy_mm_dd_time = () => {
    var d = new Date();
    const pad2 = (n) => String(n).padStart(2, '0');
    const date_str = `${d.getFullYear().toString()}-${pad2(d.getMonth() + 1)}-${pad2(d.getDate())}` +
        ` ${pad2(d.getHours())}-${pad2(d.getMinutes())}-${pad2(d.getSeconds())}`;
    return date_str;
};

export const viewCardMode = (viewMode, parent) => {
    const appContainer = [];
    appContainer.push(document.body);
    // let appContainer = document.getElementsByClassName(parent)
    let opacityDiv = document.createElement('div');
    opacityDiv.id = 'opacityDiv';
    opacityDiv.style.position = 'fixed';
    opacityDiv.style.margin = '-20px';
    opacityDiv.style.top = '0px';
    opacityDiv.style.width = '110%';
    // opacityDiv.style.width='100%'
    opacityDiv.style.height = '100%';
    opacityDiv.style.backgroundColor = 'white';
    opacityDiv.style.opacity = '0.8';

    if (viewMode === true) {
        const o = document.getElementById('opacityDiv');
        if (o) {
            console.log("We have a problem Huston. 'opacityDiv' already exists");
        }
        // console.log('adding opacityDiv to ', appContainer[0]);
        appContainer[0].appendChild(opacityDiv);
    }
    if (viewMode === false) {
        const o = document.getElementById('opacityDiv');
        if (o) {
            o.parentElement.removeChild(o);
        }
    }
};

export const createTableDescriptions = tableDescription => {
    let description = [];
    if (!tableDescription) {
    } else if (typeof (tableDescription) === "object") {
        for (let [title, body] of Object.entries(tableDescription)) {
            if (title === 'description' && body !== 'None') {
                description.push(body);
            } else if (body === 'None' && title !== 'description') {
                description.push(title);
            } else if (title === 'description' && body === 'None') {
                return;
            } else {
                description.push(title + ': ' + body + ' ');
            }
        }
    } else {
        description.push(toString(tableDescription));
    }
    return description;
};

export const sortNamesGlobal = (column, arrowDirection, userList) => {
    let sortedList;
    if (!userList) return;
    if (arrowDirection) {
        sortedList = userList.sort((name1, name2) =>
            name1[column].toUpperCase() > name2[column].toUpperCase() ? -1 : 1
        );
    } else {
        sortedList = userList.sort((name1, name2) =>
            name1[column].toUpperCase() > name2[column].toUpperCase() ? 1 : -1
        );
    }
    return sortedList;
};

export const sortDescriptionGlobal = (column, arrowDirection, subjectList) => {
    let sortDescription;
    if (!subjectList) return;
    if (arrowDirection) {
        sortDescription = subjectList.sort((user1, user2) =>
            Object.keys(user1[column]).length > Object.keys(user2[column]).length ? 1 : -1
        );
    } else {
        sortDescription = subjectList.sort((user1, user2) =>
            Object.keys(user1[column]).length > Object.keys(user2[column]).length ? -1 : 1
        );
    }
    return sortDescription;
};

export const sortDateGlobal = (column, arrowDirection, userList) => {
    let sortedList;
    if (!userList) return;
    if (arrowDirection) {
        sortedList = userList.sort((name1, name2) => {
            let firstDate = new Date(name1[column]);
            let secondDate = new Date(name2[column]);
            return (secondDate - firstDate);
        });
    } else {
        sortedList = userList.sort((name1, name2) => {
            let firstDate = new Date(name1[column]);
            let secondDate = new Date(name2[column]);
            return (firstDate - secondDate);
        });
    }
    return sortedList;
};


export const exportToCsv = (filename, items, file1Info, file2Info) => {
    const fileInfo = [...file1Info, ...file2Info || []];
    const header = Object.keys(items[0]);
    const csv = [
        ...fileInfo.map(obj => Object.entries(obj)),
        [],
        [],
        header.join(','), // header row first
        [],
    ].join('\r\n');

    const st = csv.toString();

    stringify(items, function (err, output) {
        console.log(output);
        downloadCsv(st + output, filename);
    });
};


const downloadCsv = (csv, filename) => {
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
