import React from "react";
import PlayCookie from "./PlayCookie";
import PlayTryCatch from "./PlayTryCatch";
import PlayCompare from "./PlayCompare";
import PlayWorkInAccount from "./PlayWorkInAccount";
import PlayAccountListContainer from "./PlayAccountListContainer";
import PlayMyProfile from './PlayMyProfile';
import PlayQuery from './PlayQuery';
import PlaySeanTree from './PlaySeanTree';
import PlayAccountParent from "./TableSort/PlayAccountParent"
import PlayUserParent from "./TableSort/PlayUserParent"
import './play.css';

class Play extends React.Component {

    lfs = { lfs: 'lfs' };

    pages = [
        { title: 'None', comp: null },
        { title: 'SeanTree', comp: <PlaySeanTree /> },
        { title: 'Account', comp: <PlayWorkInAccount /> },
        { title: 'Accounts', comp: <PlayAccountListContainer /> },
        { title: 'My Profile', comp: <PlayMyProfile /> },
        { title: 'Queries', comp: <PlayQuery /> },
        { title: 'Try Catch', comp: <PlayTryCatch /> },
        { title: 'Compare', comp: <PlayCompare /> },
        { title: 'Cookie', comp: <PlayCookie /> },
        { title: 'AccountTable', comp: <PlayAccountParent /> },
        { title: 'UserTable', comp: <PlayUserParent /> },
    ]

    // The gray background
    backdropStyle = {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        padding: 50,
        zIndex: 1001,
    };

    backdropStyleHide = {
        position: "fixed",
        top: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        padding: 0,
        zIndex: 101,
    };

    hiddenButton = {
        position: "fixed",
        top: 0,
        right: 0,
        backgroundColor: "rgba(255,0,0,0.3)",
        padding: 10,
        zIndex: 1001
    };

    modalStyle = {
        textAlign: "left",
        backgroundColor: "#fff",
        borderRadius: 5,
        height: "95%",
        margin: "0 auto",
        padding: 30
    };

    state = {
        modalStyle: { display: 'none' },
        backdropStyle: this.backdropStyleHide,
        count: 0,
        show: false,
        component: null,
    };

    isToggle = true;

    onIn = () => {
        this.setState({ show: true });
    }

    onOut = () => {
        this.setState({ show: false });
    }

    onSelected = (e) => {
        const comp = this.pages[e.target.getAttribute('index')].comp;

        if (comp) {
            this.setState({
                modalStyle: this.modalStyle,
                backdropStyle: this.backdropStyle,
            });
        } else {
            this.setState({
                modalStyle: { display: 'none' },
                backdropStyle: this.backdropStyleHide,
            });
        }

        this.setState({component: comp});
    }

    render() {
        const options = this.state.show ?
            this.pages.map((obj, i) => {
                return <h3 key={i} index={i} onClick={this.onSelected} comp={obj.comp}>{obj.title}</h3>
            })
            : null;
        return (

            <div className="backdrop" style={this.state.backdropStyle}>
                <div
                    className="hiddenButton"
                    onMouseEnter={this.onIn}
                    onMouseLeave={this.onOut}
                    style={this.hiddenButton}>
                    {options}
                </div>
                <div style={this.state.modalStyle}>
                    <h1> Just Playing {this.state.count}</h1>
                    {this.state.component}
                </div>
            </div>
        );
    }
}

export default Play;