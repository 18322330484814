import React from 'react';
import './dataOptions.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';

class DataOptions extends React.Component {

  doCompare = () => {
    const queries = this.props.selectedNodes.map(n => n.viewName);
    const queryValues = this.props.selectedNodes.map(n => n.value)
    this.props.doCompare(queries, queryValues);
  }

  doNothing = () => {
    console.log("not yet implemented");
    this.props.notImplemented();
  }

  render() {
    const {selectedNodes, toolBarDisplayOption} = this.props;
    const oneDataNode = (selectedNodes.length === 1 && (selectedNodes[0].type === 'dg' || selectedNodes[0].type === 'doc'));
    const oneDgOrNothing = !selectedNodes[0] || (selectedNodes.length === 1 && selectedNodes[0].type === 'dg');
    const oneDg = selectedNodes.length === 1 && selectedNodes[0].type === 'dg';
    const oneQuery = (selectedNodes.length === 1 && selectedNodes[0].type === 'q');
    const twoQueries = (selectedNodes.length === 2);   // Only queries allows two selected items
    let dataType = "Data Group";
    if (selectedNodes[0]) {
      if (selectedNodes[0].type === "doc") {
        dataType = "Data File";
      }
    }
    const buttons = [
      {text:'Exit', active: true, command: () =>  this.props.onExit()},
      {text:'Create Data Group', active: oneDgOrNothing, command: () => toolBarDisplayOption(true, 'createGroupWindow')},
      {text:'Upload Data File', active: oneDg, command: () => toolBarDisplayOption(true, 'uploadDisplay')},
      {text:'Compare Query', active: twoQueries, command: this.doCompare},
      {text:'Download Query', active: oneQuery, command: () => this.props.singleDownloadHandler(selectedNodes[0])},
      {text:`View ${dataType}`, active: oneDataNode, command: () => toolBarDisplayOption(true, 'dataRegistry')},
      {text:'Move', active: oneDataNode, command: () => toolBarDisplayOption(true, 'moveData')},
      {text:'Delete', active: oneDataNode, command: () => toolBarDisplayOption(true, 'deleteData')},
    ]

    const outButtons = buttons.map((b,i) => 
      <AppButton key= {i} className="narrowButton" enabled={b.active} onClick={b.command}>
          {b.text}
      </AppButton>
    )
    return (
      <div className='dataOptionsContainer'>
        {outButtons}
      </div>
    )
  }
}

export default DataOptions