import React from 'react';
import WorkInAccount from '../accounts/workInAccounts/WorkInAccount';
import { AppContext } from '../../global/app-context';

class PlayWorkInAccount extends React.Component {

    fetch_functions = {
        testMode: true,
        getTreeData(schema) {
            if (false) {
                return errorData;
            }
            return treeData;
        }
    }

    componentDidMount() {
        console.log("PlayWorkInAccount.componentDidMount");
    }

    componentWillUnmount() {
        console.log("PlayWorkInAccount.componentWillUnmount");
    }

    process = (msg) => {
        console.log("WE WOULD PROCESS THIS:", msg);
        return {
            "Result": 4,
            "Message": "Can't find the file or something bad error message",
        }
    }

    inspect = (...args) => {
        // console.log(this.inspect.caller);
        console.log(args);
        return {
            "Result": 0,
            "Message": "All should be good on this end (PlayWorkInAccounts).",
        }
    }
    showSelectedDisplay = (...args) => {
        console.log(args);
    }
    
    render() {
        this.context.fetch_functions = this.fetch_functions;
        this.context.larry = "What Ever";
        console.log(this.context);
        return (
            <div>
                <WorkInAccount
                    selectedAccount={{schema_name:"WhateverSchemaName"}}
                    getTreeData={this.getTreeData}
                    // createDataGroup={() => this.process("createDataGroup")}
                    mainFetch={this.inspect}
                    showSelectedDisplay={this.showSelectedDisplay}
                />
            </div>
        )
    }
}

export default PlayWorkInAccount;
PlayWorkInAccount.contextType = AppContext;


const errorData = 
    {
        "Result": 10,
        "Body": "Can't find the file1 or something",
    };


const treeData =
{
    "Result": 0,
    "Body": [
        {
            "document_group_name": "Audit",
            "document_group_id": 10,
            "document_group_parent_id": 6,
            "document_group_depth": 2,
            "document_group_path": [
                6,
                10
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "lfs1",
            "document_group_id": 2,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                2
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "lfs2",
            "document_group_id": 3,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                3
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "MMU",
            "document_group_id": 11,
            "document_group_parent_id": 10,
            "document_group_depth": 3,
            "document_group_path": [
                6,
                10,
                11
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Audit",
            "query_group_id": 2,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                2
            ],
            "query_name": "Audit BOM - Piping Model",
            "query_id": 2,
            "document_query_view_name": "document_26_query_2"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Commodities",
            "query_id": 3,
            "document_query_view_name": "document_26_query_3"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Field Parsing",
            "query_id": 4,
            "document_query_view_name": "document_26_query_4"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_26_query_1"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Singles",
            "query_group_id": 5,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                5
            ],
            "query_name": "Pipe - Singles - Base Query",
            "query_id": 5,
            "document_query_view_name": "document_26_query_5"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Base Query",
            "query_id": 6,
            "document_query_view_name": "document_26_query_6"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Construction",
            "query_id": 11,
            "document_query_view_name": "document_26_query_11"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering",
            "query_id": 8,
            "document_query_view_name": "document_26_query_8"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering Materials",
            "query_id": 7,
            "document_query_view_name": "document_26_query_7"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Estimating",
            "query_id": 9,
            "document_query_view_name": "document_26_query_9"
        },
        {
            "document_group_name": "MMU-2020.11.01",
            "document_group_id": 12,
            "document_group_parent_id": 11,
            "document_group_depth": 4,
            "document_group_path": [
                6,
                10,
                11,
                12
            ],
            "document_name": "Could be an MMU",
            "document_id": 26,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Purchasing",
            "query_id": 10,
            "document_query_view_name": "document_26_query_10"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Mechanical Commodity Codes",
            "document_id": 4,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_4_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Nut Diameter Codes",
            "document_id": 12,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_12_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Nut Material Codes",
            "document_id": 13,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_13_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe Coating Codes",
            "document_id": 19,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_19_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe End Condition Codes",
            "document_id": 17,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_17_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe Fabrication Codes",
            "document_id": 18,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_18_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe Material Codes",
            "document_id": 15,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_15_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe Schedule Codes",
            "document_id": 14,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_14_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe Size Codes",
            "document_id": 20,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_20_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Pipe Specification and Grade Codes",
            "document_id": 16,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_16_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Structural Commodity Codes",
            "document_id": 5,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_5_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Stud Bolt Diameter Codes",
            "document_id": 9,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_9_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Stud Bolt Length Codes",
            "document_id": 10,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_10_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": "Stud Bolt Material Codes",
            "document_id": 11,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_11_query_1"
        },
        {
            "document_group_name": "Part Codes",
            "document_group_id": 1,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                1
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "Pricing",
            "document_group_id": 5,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                5
            ],
            "document_name": "Stud Bolt and Nut Quote Rev A",
            "document_id": 22,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_22_query_1"
        },
        {
            "document_group_name": "Pricing",
            "document_group_id": 5,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                5
            ],
            "document_name": "Valve Quote rev A",
            "document_id": 21,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_21_query_1"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Audit",
            "query_group_id": 2,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                2
            ],
            "query_name": "Audit BOM - Piping Model",
            "query_id": 2,
            "document_query_view_name": "document_23_query_2"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Commodities",
            "query_id": 3,
            "document_query_view_name": "document_23_query_3"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Field Parsing",
            "query_id": 4,
            "document_query_view_name": "document_23_query_4"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_23_query_1"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Singles",
            "query_group_id": 5,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                5
            ],
            "query_name": "Pipe - Singles - Base Query",
            "query_id": 5,
            "document_query_view_name": "document_23_query_5"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Base Query",
            "query_id": 6,
            "document_query_view_name": "document_23_query_6"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Construction",
            "query_id": 11,
            "document_query_view_name": "document_23_query_11"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering",
            "query_id": 8,
            "document_query_view_name": "document_23_query_8"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering Materials",
            "query_id": 7,
            "document_query_view_name": "document_23_query_7"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Estimating",
            "query_id": 9,
            "document_query_view_name": "document_23_query_9"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model",
            "document_id": 23,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Purchasing",
            "query_id": 10,
            "document_query_view_name": "document_23_query_10"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Audit",
            "query_group_id": 2,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                2
            ],
            "query_name": "Audit BOM - Piping Model",
            "query_id": 2,
            "document_query_view_name": "document_25_query_2"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Commodities",
            "query_id": 3,
            "document_query_view_name": "document_25_query_3"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Field Parsing",
            "query_id": 4,
            "document_query_view_name": "document_25_query_4"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_25_query_1"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Singles",
            "query_group_id": 5,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                5
            ],
            "query_name": "Pipe - Singles - Base Query",
            "query_id": 5,
            "document_query_view_name": "document_25_query_5"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Base Query",
            "query_id": 6,
            "document_query_view_name": "document_25_query_6"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Construction",
            "query_id": 11,
            "document_query_view_name": "document_25_query_11"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering",
            "query_id": 8,
            "document_query_view_name": "document_25_query_8"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering Materials",
            "query_id": 7,
            "document_query_view_name": "document_25_query_7"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Estimating",
            "query_id": 9,
            "document_query_view_name": "document_25_query_9"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model for Doug",
            "document_id": 25,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Purchasing",
            "query_id": 10,
            "document_query_view_name": "document_25_query_10"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Audit",
            "query_group_id": 2,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                2
            ],
            "query_name": "Audit BOM - Piping Model",
            "query_id": 2,
            "document_query_view_name": "document_24_query_2"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Commodities",
            "query_id": 3,
            "document_query_view_name": "document_24_query_3"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Base",
            "query_group_id": 3,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                3
            ],
            "query_name": "Field Parsing",
            "query_id": 4,
            "document_query_view_name": "document_24_query_4"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_24_query_1"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Singles",
            "query_group_id": 5,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                5
            ],
            "query_name": "Pipe - Singles - Base Query",
            "query_id": 5,
            "document_query_view_name": "document_24_query_5"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Base Query",
            "query_id": 6,
            "document_query_view_name": "document_24_query_6"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Construction",
            "query_id": 11,
            "document_query_view_name": "document_24_query_11"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering",
            "query_id": 8,
            "document_query_view_name": "document_24_query_8"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Engineering Materials",
            "query_id": 7,
            "document_query_view_name": "document_24_query_7"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Estimating",
            "query_id": 9,
            "document_query_view_name": "document_24_query_9"
        },
        {
            "document_group_name": "Project Larry 101",
            "document_group_id": 6,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                6
            ],
            "document_name": "Overall Collector Model - no WH 8",
            "document_id": 24,
            "query_group_name": "Pipe Totals",
            "query_group_id": 6,
            "query_group_parent_id": 4,
            "query_group_depth": 2,
            "query_group_path": [
                4,
                6
            ],
            "query_name": "Pipe - Totals - Purchasing",
            "query_id": 10,
            "document_query_view_name": "document_24_query_10"
        },
        {
            "document_group_name": "Reference",
            "document_group_id": 4,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                4
            ],
            "document_name": "Nominal Pipe Size Chart - Customary Units",
            "document_id": 6,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_6_query_1"
        },
        {
            "document_group_name": "Reference",
            "document_group_id": 4,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                4
            ],
            "document_name": "Nominal Pipe Size Chart - SI Units",
            "document_id": 7,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_7_query_1"
        },
        {
            "document_group_name": "Reference",
            "document_group_id": 4,
            "document_group_parent_id": null,
            "document_group_depth": 1,
            "document_group_path": [
                4
            ],
            "document_name": "Weld Factors",
            "document_id": 8,
            "query_group_name": "Base Queries",
            "query_group_id": 1,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                1
            ],
            "query_name": "As Imported",
            "query_id": 1,
            "document_query_view_name": "document_8_query_1"
        },
        {
            "document_group_name": "Rev A",
            "document_group_id": 7,
            "document_group_parent_id": 6,
            "document_group_depth": 2,
            "document_group_path": [
                6,
                7
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "Rev B",
            "document_group_id": 8,
            "document_group_parent_id": 6,
            "document_group_depth": 2,
            "document_group_path": [
                6,
                8
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": "Rev C",
            "document_group_id": 9,
            "document_group_parent_id": 6,
            "document_group_depth": 2,
            "document_group_path": [
                6,
                9
            ],
            "document_name": null,
            "document_id": null,
            "query_group_name": null,
            "query_group_id": null,
            "query_group_parent_id": null,
            "query_group_depth": null,
            "query_group_path": null,
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        },
        {
            "document_group_name": null,
            "document_group_id": null,
            "document_group_parent_id": null,
            "document_group_depth": null,
            "document_group_path": null,
            "document_name": null,
            "document_id": null,
            "query_group_name": "Pipe",
            "query_group_id": 4,
            "query_group_parent_id": null,
            "query_group_depth": 1,
            "query_group_path": [
                4
            ],
            "query_name": null,
            "query_id": null,
            "document_query_view_name": null
        }
    ]
};