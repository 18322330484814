import React from 'react';
import '../../../../css/style.css';

class CompareData extends React.Component {

    state = { selected: null };
    selectBackground = '#EDEEF2';

    doToggle = e => {
        this.props.doToggle(this.props.index, e.target.getAttribute('value'), e.target.getAttribute('type'));
    }

    render() {

        const { data, title, selected } = this.props;
        let headers = [];
        let content = [];
        // protect against empty files
        if (data && data.length) {
            headers = Object.keys(data[0])
            content = data.map((k) => {
                return Object.values(k)
            });
        }

        return (
            <div>
                {data ?
                    <>
                        <span className='appHeading'>{title}</span>
                        <table className='appTable'>
                            <thead>
                                <tr>
                                    {headers.map((v, i) => {
                                        return (
                                            <th onClick={this.doToggle} value={v} type={v.type_code} key={i}
                                                style={{ backgroundColor: selected === v ? this.selectBackground : null }}
                                            >
                                                {v}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {content.map((r, i) => {
                                    return (
                                        <tr key={i}>
                                            {headers.map((v, i) => {
                                                return (
                                                    <td className='cell' onClick={this.doToggle} value={r} type={v.type_code} key={i}
                                                        style={{ backgroundColor: selected === v ? this.selectBackground : null }}
                                                    >
                                                        {r[i]}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* {output} */}
                    </>
                    : null
                }
            </div>
        )
    }
}

export default CompareData;