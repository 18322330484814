/*
    This Component is only used (especially used) for testing
    the compare function. It creates dummy data and calles
    the compare functions.
*/
import React from 'react';
import CompareMain from "../accounts/workInAccounts/compare/CompareMain";

class PlayCompare extends React.Component {

    done = (v) => {
        console.log(v);
    }

    toGetRidOfMsg = () => {
        const x = errorData;
        return x
    }
    
    render() {
        return (
            <div>
                <CompareMain
                    data={data}
                    files={['file number 1', 'file number 2']}
                    done={this.done}
                />
            </div>
        )
    }
}

export default PlayCompare;

const errorData = [
    {
        "Result": 10,
        "Body": "Can't find the file1 or something",
    },
    {
        "Result": -1,
        "Body": "Can't find the file2 or something",
    },
]

const data =
    [
        {
            "Result": 0,
            "Body": [
                {
                    "pointverge_export_row": 1,
                    "size": "1\"",
                    "dat_file": "B16.5_BLT_300_RF",
                    "db_code": -17.0,
                    "dwg_name": "CAP-SAGD-5000-PIPM-100-01.dwg",
                    "fit_mode": "2T",
                    "flag": 515.0,
                    "length": 159.0,
                    "lib_file": "CAPAC_METRIC_2020.prj",
                    "line_num": "EMULSION HEADER TO CPF",
                    "sort_seq": 830.0,
                    "description": "(16) STUD BOLTS, CL300, RF, ASTM A193-B7 c/w 2 ASTM A194-2H HEAVY HEX NUTS",
                    "spec_file": "_B",
                    "weight": 14.75,
                    "main_act": 273.0,
                    "main_nom": 10.0,
                    "mark": 1383.0,
                    "prgm_code": 24.0,
                    "pt4_x": 179675.0,
                    "pt4_y": 106004.0,
                    "pt4_z": 2184.0,
                    "qty": 1.0,
                    "red_act": null,
                    "red_nom": null,
                    "short_desc": null,
                    "tag": null,
                    "thk_nom": null,
                    "thk_red": null,
                    "pointverge_import_row": 1,
                    "pointverge_import_uuid": "b4477a9f-bf4d-4710-beee-d180411e85da"
                },
                {
                    "pointverge_export_row": 2,
                    "size": "1\"",
                    "dat_file": "B16.5_BLT_300_RF",
                    "db_code": -17.0,
                    "dwg_name": "CAP-SAGD-5000-PIPM-100-01.dwg",
                    "fit_mode": "2T",
                    "flag": 515.0,
                    "length": 159.0,
                    "lib_file": "CAPAC_METRIC_2020.prj",
                    "line_num": "EMULSION HEADER TO CPF",
                    "sort_seq": 830.0,
                    "description": "(16) STUD BOLTS, CL300, RF, ASTM A193-B7 c/w 2 ASTM A194-2H HEAVY HEX NUTS",
                    "spec_file": "_B",
                    "weight": 14.75,
                    "main_act": 273.0,
                    "main_nom": 10.0,
                    "mark": 1384.0,
                    "prgm_code": 24.0,
                    "pt4_x": 180015.0,
                    "pt4_y": 103900.0,
                    "pt4_z": 1583.0,
                    "qty": 1.0,
                    "red_act": null,
                    "red_nom": null,
                    "short_desc": null,
                    "tag": null,
                    "thk_nom": null,
                    "thk_red": null,
                    "pointverge_import_row": 2,
                    "pointverge_import_uuid": "c4be3352-2b11-4559-8420-35c5d1c04a68"
                },
                {
                    "pointverge_export_row": 3,
                    "size": "1\"",
                    "dat_file": "B16.5_BLT_300_RF",
                    "db_code": -17.0,
                    "dwg_name": "CAP-SAGD-5000-PIPM-100-01.dwg",
                    "fit_mode": "2T",
                    "flag": 515.0,
                    "length": 159.0,
                    "lib_file": "CAPAC_METRIC_2020.prj",
                    "line_num": "EMULSION HEADER TO CPF",
                    "sort_seq": 830.0,
                    "description": "(16) STUD BOLTS, CL300, RF, ASTM A193-B7 c/w 2 ASTM A194-2H HEAVY HEX NUTS",
                    "spec_file": "_B",
                    "weight": 14.75,
                    "main_act": 273.0,
                    "main_nom": 10.0,
                    "mark": 1386.0,
                    "prgm_code": 24.0,
                    "pt4_x": 179675.0,
                    "pt4_y": 110172.0,
                    "pt4_z": 2184.0,
                    "qty": 1.0,
                    "red_act": null,
                    "red_nom": null,
                    "short_desc": null,
                    "tag": null,
                    "thk_nom": null,
                    "thk_red": null,
                    "pointverge_import_row": 3,
                    "pointverge_import_uuid": "4f395780-a2ec-42d3-9407-ef1298c05fa1"
                }
            ],
            "Desc": [
                {
                    "name": "pointverge_export_row",
                    "type_code": 20
                },
                {
                    "name": "size",
                    "type_code": 25
                },
                {
                    "name": "dat_file",
                    "type_code": 25
                },
                {
                    "name": "db_code",
                    "type_code": 1700
                },
                {
                    "name": "dwg_name",
                    "type_code": 25
                },
                {
                    "name": "fit_mode",
                    "type_code": 25
                },
                {
                    "name": "flag",
                    "type_code": 1700
                },
                {
                    "name": "length",
                    "type_code": 1700
                },
                {
                    "name": "lib_file",
                    "type_code": 25
                },
                {
                    "name": "line_num",
                    "type_code": 25
                },
                {
                    "name": "sort_seq",
                    "type_code": 1700
                },
                {
                    "name": "description",
                    "type_code": 25
                },
                {
                    "name": "spec_file",
                    "type_code": 25
                },
                {
                    "name": "weight",
                    "type_code": 1700
                },
                {
                    "name": "main_act",
                    "type_code": 1700
                },
                {
                    "name": "main_nom",
                    "type_code": 1700
                },
                {
                    "name": "mark",
                    "type_code": 1700
                },
                {
                    "name": "prgm_code",
                    "type_code": 1700
                },
                {
                    "name": "pt4_x",
                    "type_code": 1700
                },
                {
                    "name": "pt4_y",
                    "type_code": 1700
                },
                {
                    "name": "pt4_z",
                    "type_code": 1700
                },
                {
                    "name": "qty",
                    "type_code": 1700
                },
                {
                    "name": "red_act",
                    "type_code": 25
                },
                {
                    "name": "red_nom",
                    "type_code": 25
                },
                {
                    "name": "short_desc",
                    "type_code": 25
                },
                {
                    "name": "tag",
                    "type_code": 25
                },
                {
                    "name": "thk_nom",
                    "type_code": 25
                },
                {
                    "name": "thk_red",
                    "type_code": 25
                },
                {
                    "name": "pointverge_import_row",
                    "type_code": 23
                },
                {
                    "name": "pointverge_import_uuid",
                    "type_code": 2950
                }
            ]
        },
        {
            "Result": 0,
            "Body": [
                {
                    "pointverge_export_row": 1,
                    "sort_seq": 830.0,
                    "main_act": 273.0,
                    "red_act": null,
                    "size": "1\"",
                    "short_desc": null,
                    "description": "(16) STUD BOLTS, CL300, RF, ASTM A193-B7 c/w 2 ASTM A194-2H HEAVY HEX NUTS",
                    "length": 159.0,
                    "db_code": -17.0,
                    "tag": null,
                    "spec_file": "_B",
                    "qty": 1.0,
                    "dwg_name": "CAP-SAGD-5000-PIPM-100-01.dwg",
                    "line_num": "EMULSION HEADER TO CPF",
                    "pt4_x": 179675.0,
                    "pt4_y": 106004.0,
                    "pt4_z": 2184.0
                },
                {
                    "pointverge_export_row": 2,
                    "sort_seq": 830.0,
                    "main_act": 273.0,
                    "red_act": null,
                    "size": "1\"",
                    "short_desc": null,
                    "description": "(16) STUD BOLTS, CL300, RF, ASTM A193-B7 c/w 2 ASTM A194-2H HEAVY HEX NUTS",
                    "length": 159.0,
                    "db_code": -17.0,
                    "tag": null,
                    "spec_file": "_B",
                    "qty": 1.0,
                    "dwg_name": "CAP-SAGD-5000-PIPM-100-01.dwg",
                    "line_num": "EMULSION HEADER TO CPF",
                    "pt4_x": 180015.0,
                    "pt4_y": 103900.0,
                    "pt4_z": 1583.0
                },
                {
                    "pointverge_export_row": 3,
                    "sort_seq": 830.0,
                    "main_act": 273.0,
                    "red_act": null,
                    "size": "1\"",
                    "short_desc": null,
                    "description": "(16) STUD BOLTS, CL300, RF, ASTM A193-B7 c/w 2 ASTM A194-2H HEAVY HEX NUTS",
                    "length": 159.0,
                    "db_code": -17.0,
                    "tag": null,
                    "spec_file": "_B",
                    "qty": 1.0,
                    "dwg_name": "CAP-SAGD-5000-PIPM-100-01.dwg",
                    "line_num": "EMULSION HEADER TO CPF",
                    "pt4_x": 179675.0,
                    "pt4_y": 110172.0,
                    "pt4_z": 2184.0
                }
            ],
            "Desc": [
                {
                    "name": "pointverge_export_row",
                    "type_code": 20
                },
                {
                    "name": "sort_seq",
                    "type_code": 1700
                },
                {
                    "name": "main_act",
                    "type_code": 1700
                },
                {
                    "name": "red_act",
                    "type_code": 25
                },
                {
                    "name": "size",
                    "type_code": 25
                },
                {
                    "name": "short_desc",
                    "type_code": 25
                },
                {
                    "name": "description",
                    "type_code": 25
                },
                {
                    "name": "length",
                    "type_code": 1700
                },
                {
                    "name": "db_code",
                    "type_code": 1700
                },
                {
                    "name": "tag",
                    "type_code": 25
                },
                {
                    "name": "spec_file",
                    "type_code": 25
                },
                {
                    "name": "qty",
                    "type_code": 1700
                },
                {
                    "name": "dwg_name",
                    "type_code": 25
                },
                {
                    "name": "line_num",
                    "type_code": 25
                },
                {
                    "name": "pt4_x",
                    "type_code": 1700
                },
                {
                    "name": "pt4_y",
                    "type_code": 1700
                },
                {
                    "name": "pt4_z",
                    "type_code": 1700
                }
            ]
        }
    ];