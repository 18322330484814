import React from 'react';
import './accountAssignUser.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';

class SelectedAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            accountUsers: this.props.accountUsers
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountUsers !== prevProps.accountUsers) {
            this.setState({ accountUsers: this.props.accountUsers });
        }
    }

    render() {
        const { accountUsers } = this.state;
        const { selectedAccount, assignWindow, assignWindows } = this.props;
        return (
            <div className='assignDetails'>
                <h2 className='selectedAccountName'>
                    {selectedAccount.account_full_name}<br />
                    ({selectedAccount.account_short_name})
                </h2>
                <hr />
                {accountUsers.length !== 0 ?
                    <>
                        {assignWindow === 'editAssign' ?
                            <AppButton
                                enabled={true}
                                className='editAssignToggleBtn'
                                onClick={() => assignWindows('assignUser')}
                            >
                                Back
                            </AppButton>
                            : null
                        }
                        {assignWindow === 'assignUser' ?
                            <AppButton
                                enabled={true}
                                className='editAssignToggleBtn'
                                onClick={() => assignWindows('editAssign')}
                            >
                                Edit Assignments
                            </AppButton>
                            : null
                        }
                        {assignWindow === 'removeAssign' ?
                            <AppButton
                                enabled={true}
                                className='editAssignToggleBtn'
                                onClick={() => assignWindows('assignUser')}
                            >
                                Back
                            </AppButton>
                            : null
                        }
                    </>
                    : null
                }
                <hr />
                <div className='currentAssignments'>
                    <h2>Assigned Users</h2>
                    <hr />
                    <div className='assignedTableContainer'>
                        <ul className='assignedDetailsList'>
                            {accountUsers.length === 0 ?
                                <li>No users have been assigned to this account</li>
                                :
                                accountUsers.map((user, index) => (
                                    <li key={index}>
                                        {user.user_full_name}
                                        <br />
                                        {user.admin_option ?
                                            <span><b>Admin</b></span>
                                            : null
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectedAccount