import React from 'react';
import { AppButton } from '../../global/globalComponents/AppButton';

import { AppContext } from '../../global/app-context';

class EditAssignMenu extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            adminOption: null,
            isDisabled: true,
            setRadio: false,
        }
    }
        
    componentDidMount() {        
        this.updateAdminValue()
    }

    componentDidUpdate(prevProps) {
        let subject 
        if (this.props.userAccounts !== prevProps.userAccounts) {
            this.updateAdminValue()
        } else if (this.props.selectedAccount !== prevProps.selectedAccount || 
            this.props.selectedUser !== prevProps.selectedUser) {
            subject = this.updateAdminValue()
            subject.admin ? this.props.radioPermission('Admin') : 
                this.props.radioPermission('Base')
        } 
    }

    updateAdminValue = (id) => {
        const { userAccounts, accountUsers, selectedUser,
            assignUser, assignAccount, selectedAccount } = this.props
        let admin
        if (assignAccount) {
            for (let account of userAccounts) {
                if (account.account_id === selectedAccount.account_id) {
                    admin = account.admin_option
                }
            }
        } else if (assignUser) {
            for (let user of accountUsers) {
                if (user.user_id === selectedUser.registry_id) {
                    admin = user.admin_option
                }
            }
        }
        let adminObj={'admin': admin, 'base': !admin}
        return adminObj
    }

    render() {
        const { accountAdmin } = this.context.PERMISSION_LEVELS;
        const { submitConfirmation, rowSelected,
            assignWindow, assignUser, selectedPermission,
            radioBase, radioAdmin, radioPermission } = this.props

        return (
            <div className='editAssignMenu'>
                <div
                    className='assignAccountRoleForm'
                >
                    <div 
                        className={rowSelected ? 
                            'radioPermissionEnable' : 
                            'radioPermissionDisable'}
                        id='radioPermissionDiv'
                    >
                        <span className='radioSpan'
                            onClick={()=>radioPermission('Base')}
                            id='Base'
                        >
                            <input
                                type='radio'
                                name='Base'
                                value={radioBase}
                                onChange={()=>radioPermission('Base')}
                                checked={selectedPermission===radioBase}
                            /> 
                                {radioBase}
                        </span>
                        <span className='radioSpan'
                            id='Admin' 
                            onClick={()=>radioPermission('Admin')}                      
                        >
                            <input
                                type='radio'
                                name='Admin'
                                value={radioAdmin}
                                onChange={()=>radioPermission('Admin')}
                                checked={selectedPermission===radioAdmin}
                            />
                                {radioAdmin}
                        </span>
                    </div>
                    <AppButton 
                        enabled={rowSelected}
                        securityRequired={accountAdmin}
                        onClick={() => submitConfirmation('editAssign')}
                    >
                        {assignWindow ? 
                            'Change Role':
                            'Assign Account To User'
                        }
                    </AppButton>
                </div>
                <AppButton
                    enabled={rowSelected}
                    securityRequired={accountAdmin}
                    onClick={() => submitConfirmation('removeAssign')}
                >
                    {assignUser ? 
                        'Revoke User': 
                        'Revoke Account'
                    }
                </AppButton>
            </div>
        )
    }
}

export default EditAssignMenu;

EditAssignMenu.contextType = AppContext;