import React from 'react';
import { AppButton } from '../../global/globalComponents/AppButton';
import './Tags.css';

export class Tags extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: this.props.tags,
            tagKey: '',
            tagValue: ''
        }
    }

    componentDidUpdate() {
        if (this.props.tags !== this.state.tags) {
            this.setState({ tags: this.props.tags });
        }
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    addPropertyClick = () => {
        if (this.state.tagKey !== "" && this.state.tagValue !== "") {
            const tags = this.state.tags;
            tags[this.state.tagKey] = this.state.tagValue;
            this.setState({
                tags,
                updateResponse: '',
                tagKey: '',
                tagValue: ''
            });
        }
    }

    removeProperty = () => {
        let tempProperties = this.state.tags;
        delete tempProperties[this.state.tagKey];
        this.setState({
            tags: tempProperties,
            updateResponse: '',
            tagKey: '',
            tagValue: ''
        });
    }

    onClickTag = (e) => {
        const key = e.target.id;
        this.setState({
            tagKey: key,
            tagValue: this.state.tags[key]
        });
    }

    clearInputs = () => {
        this.setState({
            tagKey: '',
            tagValue: ''
        });
    }

    render() {
        
        return (
            <div className="account-tags">
                <div>
                    <h3>Tags:</h3>
                    <div id="tagInputs">
                        <div className="grid-5">
                            <label htmlFor="tagKey">Key:</label>
                            <input 
                                tabIndex ={this.props.tabIndex[0]} onChange={this.onChangeHandler} value={this.state.tagKey} type="text" name="tagKey" id="tagKey" placeholder={"Key"} />
                            <AppButton tabIndex={this.props.tabIndex[2]} enabled={true} className="tag-button" onClick={this.addPropertyClick} title={'Add'}>+</AppButton>
                            <AppButton tabIndex={this.props.tabIndex[3]} enabled={true} className="tag-button" onClick={this.removeProperty} title={'Remove'}>-</AppButton>
                            <AppButton tabIndex={this.props.tabIndex[4]} enabled={true} className="tag-button" onClick={this.clearInputs} title={'Clear'}>x</AppButton>
                        </div>
                        <div className="grid-2">
                            <label htmlFor="tagValue">Value:</label>
                            <input 
                                tabIndex ={this.props.tabIndex[1]} onChange={this.onChangeHandler} value={this.state.tagValue} type="text" name="tagValue" id="tagValue" placeholder={"Value"} />
                        </div>
                    </div>
                </div>
                <div id="tags" className="tag-container">
                    <ul>
                        {
                            Object.keys(this.state.tags).map((key, i) => (
                                <li onClick={this.onClickTag} id={key} data-value={this.state.tags[key]} className="tag-pill" key={key}>
                                    <span>{key}</span><span>:</span><span>{this.state.tags[key]}</span></li>))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}