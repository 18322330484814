import React from 'react';
import {
    accountData,
    newAccountData,
    availableAccountData,
} from '../sampleData/sampleAccountInfo';
import AccountTable from '../../accounts/accountList/AccountTable';

class PlayAccountParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignUser: '',
            selectedAccounts: {},
            acctData: accountData,
            newAcctData: newAccountData, // list for adding new accounts
            allowMultipleSelection: false,
        };
    }

    componentDidMount() {
        this.setState({
            acctData: [...accountData],
        });
    }

    loadAccountData = () => {
        const acctData = [...availableAccountData];
        const newAcctData = [...newAccountData];
        this.setState({
            acctData,
            newAcctData,
        });
    };

    unLoadAccountData = () => {
        this.setState({
            acctData: [],
            selectedAccounts: {},
        });
    };
    
    onSelectedAccount = (accountNum, selected) => {
        this.setState({rerender: true});
    };

    allowMultiple = (allowMultipleSelection) => {
        this.setState({allowMultipleSelection});
    };

    onAddAccount = () => {
        if (this.state.newAcctData.length >= 1) {
            let acc = this.state.newAcctData.pop();
            if (this.state.acctData) {
                let arr = this.state.acctData;
                arr.push(acc);
                this.setState({
                    accountData: arr,
                });
            }
        }
    };

    onRemoveAccount = () => {
        if (this.state.acctData.length >= 1) {
            let acc = this.state.acctData.pop();
                const selectedAccounts = { ...this.state.selectedAccounts };
                delete selectedAccounts[acc.account_id];
                this.setState({selectedAccounts});
            
            if (this.state.newAcctData) {
                let arr = this.state.newAcctData;
                arr.push(acc);
                this.setState({
                    newAccountData: arr,
                });
            }
        }
    };

    render() {
        const { selectedAccounts, acctData } = this.state;
        const keys = Object.keys(selectedAccounts);
        const data = [];
        if (keys.length > 0) {
            for (let k = 0; k <= keys.length - 1; k++) {
                let postfix = this.state.allowMultipleSelection ? ', ' : '';
                 data.unshift(
                    acctData[acctData.findIndex(x => x.account_id.toString() === keys[k])].account_short_name + postfix
                );
            }
        }

        return (
            <div>
                <div>
                    <label htmlFor="multipleSelection">
                        Allow Multiple
                        <input
                            id="multipleSelection"
                            name="multipleSelection"
                            type="checkbox"
                            onChange={(e) => {
                                this.allowMultiple(e.currentTarget.checked);
                            }}
                        />
                    </label>
                </div>
                <div>
                    <input
                        type="button"
                        value="Load Accounts"
                        onClick={this.loadAccountData}
                    />
                    <input
                        type="button"
                        value="unLoad Accounts"
                        onClick={this.unLoadAccountData}
                    />
                </div>
                <div>
                    <input
                        type="button"
                        value="Add Account"
                        onClick={this.onAddAccount}
                    />
                    <input
                        type="button"
                        value="Remove Account"
                        onClick={this.onRemoveAccount}
                    />
                </div>
                <div>
                    <input type="button" value={`${keys.length} selected`} />
                </div>
                <div
                    style={{
                        minHeight: '2em',
                        lineHeight: '2em',
                        fontSize: '1em',
                    }}
                >
                    Number of accounts: {this.state.acctData.length}
                </div>
                <div
                    style={{
                        minHeight: '2em',
                        lineHeight: '2em',
                        fontSize: '1em',
                    }}
                >
                    {data}
                </div>
                <AccountTable
                    assignAccount={true}
                    data={this.state.acctData}
                    onSelectedAccount={this.onSelectedAccount}
                    selectedAccounts={this.state.selectedAccounts}
                    allowMultipleSelection={this.state.allowMultipleSelection}
                />
            </div>
        );
    }
}

export default PlayAccountParent;

