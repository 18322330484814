/* 
    1) Change release date to current date with version number(n) yyyy-mm-dd.n
    2) Set backend and environment to correct ones
    3) Save and continue with the release process
    4) After release, change backend and environment back to local and add ++ to version number
*/

const RELEASE = '2021-11-05.1';

// const BACKEND = 'http://127.0.0.1:5000';
// const ENV = 'local';

// const BACKEND = 'http://material-inspector-three.canadacentral.cloudapp.azure.com/';
// const ENV = 'dev';

 const BACKEND = 'https://www.qubr.ca'
 const ENV = 'prod';


const backend_route = (route) => {
    return BACKEND + route
}

const obj = { backend_route, ENV, RELEASE };
export default obj;