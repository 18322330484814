import React from 'react';
import {
    UserData,
    NewUserData,
    AvailableUserData,
} from '../sampleData/sampleUserInfo';
import UserTable from '../../users/userList/UserTable';

class PlayUserParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignUser: '',
            selectedUsers: {},
            userData: UserData,
            newUserData: NewUserData, // list for adding new users
            allowMultipleSelection: false,
        };
    }
    componentDidMount() {
        this.setState({ userData: [...UserData] });
    }

    loadUserData = () => {
        const userData = [...AvailableUserData];
        const newUserData = [...NewUserData];
        this.setState({
            userData,
            newUserData,
        });
    };
    unLoadUserData = () => {
        this.setState({
            userData: [],
            selectedUsers: {},
        });
    };

    onSelectedUser = (user, selected) => {
        this.setState({ rerender: true });
    };

    allowMultiple = (allowMultipleSelection) => {
        this.setState({ allowMultipleSelection });
    };

    onAddUser = () => {
        if (this.state.newUserData.length >= 1) {
            let acc = this.state.newUserData.pop();
            if (this.state.userData) {
                let arr = this.state.userData;
                arr.push(acc);
                this.setState({
                    userData: arr,
                });
            }
        }
    };

    onRemoveUser = () => {
        if (this.state.userData.length >= 1) {
            let user = this.state.userData.pop();
            const selectedUsers = { ...this.state.selectedUsers };
            delete selectedUsers[user.registry_id];
            this.setState({selectedUsers});

            if (this.state.newUserData) {
                let arr = this.state.newUserData;
                arr.push(user);
                this.setState({
                    newUserData: arr,
                });
            }
        }
    };

    render() {
        let length = Object.keys(this.state.selectedUsers).length;
        const { selectedUsers, userData } = this.state;
        const keys = Object.keys(selectedUsers);
        const data = [];
        if (keys.length > 0) {
            for (let k = 0; k <= length - 1; k++) {
                let postfix = this.state.allowMultipleSelection ? ', ' : '';
                data.unshift(
                    userData[
                        userData.findIndex((x) => x.registry_id.toString() === keys[k])
                    ].registry_full_name + postfix
                );
            }
        }

        return (
            <div>
                <div>
                    <label htmlFor='multipleSelection'>
                        Allow Multiple
                        <input
                            id='multipleSelection'
                            name='multipleSelection'
                            type='checkbox'
                            onChange={(e) => {
                                this.allowMultiple(e.currentTarget.checked);
                            }}
                        />
                    </label>
                </div>
                <div>
                    <input type='button' value='Load Users' onClick={this.loadUserData} />
                    <input
                        type='button'
                        value='unLoad Users'
                        onClick={this.unLoadUserData}
                    />
                </div>
                <div>
                    <input type='button' value='Add User' onClick={this.onAddUser} />
                    <input
                        type='button'
                        value='Remove User'
                        onClick={this.onRemoveUser}
                    />
                </div>
                <div>
                    <input type='button' value={`${keys.length} selected`} />
                </div>
                <div style={{ minHeight: '2em', lineHeight: '2em', fontSize: '1em' }}>
                    Number of users: {this.state.userData.length}
                </div>
                <div style={{ minHeight: '2em', lineHeight: '2em', fontSize: '1em' }}>
                    {data}
                </div>
                <UserTable
                    assignUser={true}
                    data={this.state.userData}
                    onSelectedUser={this.onSelectedUser}
                    selectedUsers={this.state.selectedUsers}
                    allowMultipleSelection={this.state.allowMultipleSelection}
                />
            </div>
        );
    }
}

export default PlayUserParent;


