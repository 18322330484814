import React from 'react';
// import User from '../users/singleUser/User';

class PlayMyProfile extends React.Component {

    render() {
        
        return (
            <div>
                <h1>PlayMyProfile Sample</h1>
            </div >
        )
    }
}

export default PlayMyProfile;