import React from 'react';
import './accountStyles/accountRegistry.css';
import { AppContext } from '../../global/app-context';
import { Tags } from '../tag/Tags';
import { AppButton } from '../../global/globalComponents/AppButton';
import FocusLock from 'react-focus-lock';

class AccountRegistry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            shortName: '',
            fullName: '',
            registerResponse: '',
            registerBtnDisplay: this.props.buttonText,
            tags: {},
            buttonsOn: true
        }
    }

    componentDidMount() {
        if (this.props.componentName === "viewProfileDisplay") {
            this.setState({
                shortName: this.props.selectedAccount.account_short_name,
                fullName: this.props.selectedAccount.account_full_name,
                tags: this.props.selectedAccount.account_properties
            });
        }
    }

    onChangeHandler = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    resetPage = () => {
        this.setState({
            shortName: '',
            fullName: '',
            registerResponse: '',
            registerBtnDisplay: this.props.buttonText
        })
    }

    onClickHandler = () => {
        if (this.props.componentName === "viewProfileDisplay") {
            this.editAccount();
        } else {
            this.submitAccount();
        }
    }

    submitAccount = async () => {
        this.setState({ registerBtnDisplay: 'Registering...', registerResponse: '', buttonsOn: false });
        const { shortName, fullName, tags } = this.state;

        const data = await this.context.fetch_functions.fetch_registry_insert(
            shortName, fullName, JSON.stringify(tags));

        this.setState({
            registerBtnDisplay: this.props.buttonText,
            registerResponse: data,
            buttonsOn: true
        })
    }

    editAccount = async () => {
        this.setState({ registerBtnDisplay: 'Updating...', registerResponse: '', buttonsOn: false });
        const { shortName, fullName, tags } = this.state;

        const data = await this.context.fetch_functions.fetch_updateAccount(
            this.props.selectedAccount.account_id, shortName, fullName, JSON.stringify(tags));

        this.setState({
            registerBtnDisplay: this.props.buttonText,
            registerResponse: data,
            buttonsOn: true
        })
    }

    closeComponent = () => {
        this.props.showSelectedDisplay(false, this.props.componentName);
        this.props.loadAccountData();
    }

    render() {
        const { shortName, fullName, registerResponse, registerBtnDisplay, tags } = this.state
        return (
            <div className='accountRegistry'>
                <h1 className='accountRegistryHeading'>
                    Account Registration
                </h1>
                <FocusLock>
                    <div className='accountRegistryForm'>
                        <label>Short Name</label>
                        <input
                            id='arShortName'
                            type='text'
                            placeholder='Short Name'
                            name='shortName'
                            value={shortName}
                            onChange={this.onChangeHandler}
                            required                            
                            autoFocus
                            tabIndex={0}
                        />
                        <label>Full Name</label>
                        <input
                            id='arFullName'
                            type='text'
                            placeholder='Full Name'
                            name='fullName'
                            value={fullName}
                            onChange={this.onChangeHandler}
                            required
                            tabIndex={1}
                        />
                        <Tags tabIndex={[2, 3, 4, 5, 6]}
                            tags={tags}
                        />
                        <span className='acctSeparation'></span>
                        {registerResponse === '' ? null :
                            <div className='accountRegistryResult'>
                                <span
                                    className='accountRegistryResponseMessage'
                                    style={{
                                        color: registerResponse.status ? 'red' : 'green',
                                    }}
                                >
                                    {registerResponse.body ? registerResponse.body.Status.message : registerResponse.msg}
                                </span>
                            </div>
                        }
                        <AppButton
                            tabIndex={7}
                            className="accountRegButton"
                            onClick={this.onClickHandler}
                            enabled={this.state.buttonsOn}
                        >
                            {registerBtnDisplay}
                        </AppButton>
                        {this.props.componentName === "accountRegDisplay" ?
                            <AppButton
                                tabIndex={-1}
                                className="accountRegButton"
                                onClick={this.resetPage}
                                enabled={this.state.buttonsOn}
                            >
                                Clear
                            </AppButton>
                            : null}
                        <AppButton

                            tabIndex={-1}
                            className="accountRegButton"
                            onClick={this.closeComponent}
                            enabled={this.state.buttonsOn}
                        >
                            Close
                        </AppButton>
                    </div>
                </FocusLock>
            </div>
        )
    }
}

export default AccountRegistry

AccountRegistry.contextType = AppContext;
