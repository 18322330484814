import React from 'react';
import AccountListContainer from '../../components/accounts/accountList/AccountListContainer';
import {userInfo} from './sampleData/sampleUserInfo';

class PlayAccountListContainer extends React.Component {

    render() {
        
        return (
            <div>
                <h1>PlayAccountListContainer Sample</h1>
                <AccountListContainer
                    userInfo={userInfo}
                 />
            </div >
        )
    }
}

export default PlayAccountListContainer;