import React from 'react'
import './userExpirySet.css'
import { formatDates } from '../../../../global/functions'
import { AppContext } from '../../../../global/app-context';

class UserExpirySet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isError: null,
            responseMessage: '',
            setBtnText: 'Set',
            userExpDate: this.props.selectedUser.rolvaliduntil
        }
    }

    setExpiryDate = async e => {
        try {
            e.preventDefault()
            let dateSelected = document.getElementById('expDate').value
            if (!dateSelected) {
                throw Error('Date must be entered.');
            }
            let date = new Date()
            let timeString = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
            let newExpiryDate = dateSelected + ' ' + timeString
            this.setState({ setBtnText: 'Submitting...' })

            const data = await this.context.fetch_functions.fetch_expiry_set(
                this.props.selectedUser.registry_id, newExpiryDate);

            if (data.status) {
                throw Error(data.msg);
            }

            let newDateFromDb = data.body.Parameters.password_expires.toString()
            let convertedDate = new Date(newDateFromDb).toUTCString()
            this.props.selectedUser['rolvaliduntil'] = convertedDate
            this.props.updateUser(this.props.selectedUser)
            this.setState({
                setBtnText: 'Set',
                isError: false,
                userExpDate: convertedDate,
                responseMessage: data.body.Status.message
            })

        } catch (e) {
            this.setState({
                isError: true,
                responseMessage: e.message,
                setBtnText: 'Set'
            })
        }


    }

    render() {
        const { isError, responseMessage } = this.state
        return (
            <div className='viewExpirySetCard'>
                <form onSubmit={this.setExpiryDate} className='expirySetForm'>
                    <div className='expUserDetails'>
                        <p className='expUserName'><b>{this.props.selectedUser.registry_full_name}</b></p>
                        <p className='expUserDate'>
                            <b>Expiry Date: </b>
                            {formatDates(this.state.userExpDate)}
                        </p>
                    </div>
                    <div className='expSetDateDiv'>
                        <label><b>Set New Expiry Date: </b></label>
                        <input type='date' id='expDate' />
                    </div>
                    <div className='expFormBtnDiv'>
                        <button
                            className='expCloseBtn'
                            type='button'
                            onClick={() => this.props.showSelectedDisplay(false, 'setExpiryDisplay')}
                        >
                            Close
                        </button>
                        <button type='submit' className='expFormSetBtn'>{this.state.setBtnText}</button>
                    </div>
                </form>
                <div className='expResponseDiv'>
                    {responseMessage !== null ?
                        <p style={{ color: isError ? '#A40707' : 'black' }}>
                            {responseMessage}
                        </p>
                        : null}
                </div>

            </div>
        )
    }
}

export default UserExpirySet;

UserExpirySet.contextType = AppContext;