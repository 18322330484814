import React from 'react';
import { AppButton } from '../globalComponents/AppButton';
import './AssignAccountSubmitForm.css';

import { AppContext } from '../app-context';

class AssignAccountSubmitForm extends React.Component {
    render() {
        const { accountAdmin } = this.context.PERMISSION_LEVELS;
        const { submitConfirmation, rowSelected, radioBase, assignAccount, radioAdmin,
            radioPermission, selectedPermission } = this.props;
        let assignType = assignAccount ? 'assignAccount' : 'assignUser'
        return (
            <div
                className='assignAccountRoleForm'
            >
                <div 
                    className={rowSelected ? 'radioPermissionEnable' : 'radioPermissionDisable'}
                >
                    <span 
                        className='radioSpan'
                        onClick={()=>radioPermission('Base')}
                    >
                        <input
                            type='radio'
                            name='Base'
                            value={radioBase}
                            onChange={()=>radioPermission('Base')}
                            checked={selectedPermission===radioBase}
                            disabled={rowSelected ? false : true}
                        /> 
                            {radioBase}
                    </span>
                    <span 
                        className='radioSpan'
                        onClick={()=>radioPermission('Admin')}
                    >
                        <input
                            type='radio'
                            name='Admin'
                            value={radioAdmin}
                            onChange={()=>radioPermission('Admin')}
                            checked={selectedPermission===radioAdmin}
                            disabled={rowSelected ? false : true}
                        />
                            {radioAdmin}
                    </span>
                </div>
                <AppButton 
                    enabled={rowSelected}
                    securityRequired={accountAdmin}
                    onClick={() => submitConfirmation(assignType)}
                    className="assignBtn"
                >
                    Assign
                </AppButton>
            </div>
        )
    }
}

export default AssignAccountSubmitForm;

AssignAccountSubmitForm.contextType = AppContext;