import React from 'react';
import { AppContext } from '../../global/app-context';
import stringify from 'csv-stringify';

class PlayTryCatch extends React.Component {

    constructor() {
        super()
        this.state = {};
    }


    myFetch = async (api) => {
        const res = await fetch(api, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-type': 'application/json' },
            // body: JSON.stringify({"hello":"World"}),
        })
        // const jsonDat = await res.json();
        const text = await res.text();
        return text;
    }

    onGet = async () => {
        let msg;
        try {
            const resp = await this.myFetch('http://localhost:5000/lfsget');
            msg = JSON.stringify(resp);
        } catch (e) {
            console.log(e);
            msg = String(e);
        }
        this.setState({ msg });
    }

    onSet = async () => {
        const val = await this.myFetch('http://localhost:5000/lfsset');
        console.log(val);
        console.log(document.cookie);
    }

    onThrow = async (i) => {
        let msg;
        console.log("onThrow - Start ");
        if (i === 1) {
            console.log("onThrow about to throw");
            throw new Error("This is a thrown error");
            // console.log("onThrow after throw");
            // throw new Error("This is a thrown error");
        }
        console.log("onThrow End");
        this.setState({ msg });
    }

    onTry = async () => {
        let msg;
        try {
            console.log("onTry Start");
            await this.onThrow(1);
            console.log("onTry After Throw");
        } catch (e) {
            console.log("onTry in catch:::", e);
            msg = String(e);
        }
        this.setState({ msg });
    }

    onTestFetch = async () => {
        console.log("onTestFetch", this.context);
        let msg;
        try {
            const { doStuff, doMore, others } = this.context.fetch_functions;
            doStuff();
            doMore();
            others();
        } catch (e) {
            msg = String(e);
        }
        this.setState({ msg });
    }

        // onEqual = () => {
        //     console.log('onEqual');
        //     console.log((1 === '1'));
        //     console.log((1 == '1'));

        //     console.log((1 === 1));
        //     console.log((1 == 1));

        //     const o1 = { a: 1 };
        //     const o2 = { a: 1 };

        //     console.log(o1 == o2);
        //     console.log(o1 === o2);

        //     console.log(o1.a == o2.a);
        //     console.log(o1.a === o2.a);

        //     const oo1 = o1;
        //     oo1['b'] = 2;

        //     console.log(oo1 === o1);
        //     console.log(oo1 == o1);

        //     console.log(o1);
        //     console.log(oo1);

        //     o1['c'] = 3;

        //     console.log(oo1 === o1);
        //     console.log(oo1 == o1);

        //     console.log(o1);
        //     console.log(oo1);

        //     let a = 1;
        //     this.whateverVal(a);
        //     console.log(a);

        //     a = [1];
        //     this.whateverArr(a);
        //     console.log(a);

        //     a = [1];
        //     this.whateverArr2(a);
        //     console.log(a);
        // }

    whateverVal = (x) => {
        x = 5;
        console.log(x);
    }

    whateverArr = (x) => {
        x[0] = 5;
    }

    whateverArr2 = (x) => {
        x = [1,2,3];
        console.log(x);
    }

    onCsvStringify = () => {
        console.log('Stringify', stringify);

        stringify([
            [ [1,2,3,4], '2', '3', '4' ],
            [ 'a"a', 'b', 'c', 'd' ]
          ], function(err, output){
              console.log(output);
            // assert.equal(output, '1,2,3,4\na,b,c,d\n')
          });
    }

    render() {
        return (
            <div>
                <button onClick={this.onGet}>Get</button>
                <button onClick={this.onSet}>Set</button>
                <button onClick={this.onTry}>Try</button>
                <button onClick={this.onCatch}>Catch</button>
                <br />
                <button onClick={this.onTestFetch}>Test Fetch</button>
                <br />
                <button onClick={this.onEqual}>equal == ===</button>
                <br />
                <button onClick={this.onCsvStringify}>Stringify</button>
                <br />
                <p>{this.state.msg}</p>
            </div>
        )
    }
}

export default PlayTryCatch;

PlayTryCatch.contextType = AppContext;
