import React from 'react';
import { functions } from '../../../../global/fetch';
import './deleteData.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';


class DeleteData extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            deleteMessage: `Are you sure you want to delete this ${this.props.selectedNodes[0].type === 'dg' ? "document group" : "document"}?`
        }
    }

    confirmedDelete = async () => {
        const node = this.props.selectedNodes[0];
        const func = node.type === "dg" ? functions.fetch_delete_document_group : functions.fetch_delete_document;
        const resp = await func(this.props.selectedAccount.account_schema_name, node.id);
            if (resp.msg === "Ok") {
                this.props.toolBarDisplayOption(false, "deleteData");
                this.props.refresh();
            } else {
                this.setState({ deleteMessage: resp.msg });
            }
    }

    canceledDelete = () => {
        this.props.toolBarDisplayOption(false, "deleteData");
        this.props.refresh();
    }

    render() {
        return (
            <div className="deleteDataContainer">
                <div className="deleteDataNameDisplay">
                    {this.props.selectedNodes[0].type === 'dg' ? 
                    "Document group" : "Document"} selected for deletion: {this.props.selectedNodes[0].label}
                </div>
                <div className="deleteMessage">
                    {this.state.deleteMessage}
                </div>
                <div className="deleteDataButtonsContainer">
                    <AppButton enabled={true} onClick={this.confirmedDelete}>
                        Yes
                    </AppButton>
                    <AppButton enabled={true} onClick={this.canceledDelete}>
                        No
                    </AppButton>
                </div>
            </div>
        )
    }
}

export default DeleteData;