import React from 'react'
import './login.css'
import PasswordReset from './forgotPassword/PasswordReset'
// import {fetch_login} from '../../global/fetch'
  
import { AppContext } from "../../global/app-context";

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginEmail: '',
            loginPassword: '',
            forgotPassword: false,
            errorMsg: '',
            msg: '',
        }
    }
    // componentDidUpdate
    componentDidMount() {
        // If there was an error from above display it.
        // console.log(this.context.errorMsg);
        const {errorMsg} = this.context;
        if (errorMsg) {
            this.setState({errorMsg});
        }
    }

    onChangeHandler = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    submitLogin = async e => {
        // console.log("submitLogin",e.target);
        e.preventDefault();
        let errorMsg = "";
        let msg = "";
        try {
            // const resp = await fetch_login({email:this.state.loginEmail, password:this.state.loginPassword});
            const resp = await this.context.fetch_functions.fetch_login({email:this.state.loginEmail, password:this.state.loginPassword});
            if (resp.status) {
                msg = resp.msg;
            } else {
                this.context.setUserInfo(resp.userInfo);
            }

            // const rval = await this.context.login(this.state.loginEmail, this.state.loginPassword);
            // if (!rval) {
            //     this.setState({ loginResponse: 'failed' });
            // };
        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!In Login: with an error", error);
            errorMsg = error.message + " Check the console log in your browser and contact support."
        }
        if (msg !== "" || errorMsg !== "") {
            this.setState({errorMsg, msg});
        }
    }

    render() {
        // const {errorMsg} = this.context;
        const {errorMsg, msg} = this.state;
        return (
            <div className='loginContainer'>
                <>
                    <form action='/login' className='loginForm' onSubmit={this.submitLogin}>
                        <div className='loginEmailDiv'>
                            <label className='loginEmailLabel'>
                                Enter Email
                                </label>
                            <input
                                className='loginEmailInput'
                                type='email'
                                name='loginEmail'
                                value={this.state.loginEmail}
                                onChange={this.onChangeHandler}
                                data-testid='loginEmailInput'
                                required
                                autoFocus
                                autoComplete='username'
                            />
                        </div>
                        <div className='loginPasswordDiv'>
                            <label className='loginPasswordLabel'>
                                Password
                                </label>
                            <input
                                className='loginPasswordInput'
                                type='password'
                                name='loginPassword'
                                value={this.state.loginPassword}
                                onChange={this.onChangeHandler}
                                required
                                autoComplete='current-password'
                            />
                        </div>
                        {errorMsg ?
                            <p className='errorMessage'>
                                {errorMsg}
                            </p>
                            :
                            <>
                                <div className='invalidEmailDiv'>
                                    {msg ?
                                        <p className='invalidEmailPass'>{msg}</p>
                                        : null
                                    }
                                </div>
                                <button
                                    id='loginSubmitBtn'
                                    className='loginSubmitBtn'
                                    type='submit'
                                >
                                    Login
                                </button>
                                <div className='forgotPasswordDiv'>
                                    {this.state.loginResponse === 'failed' ?
                                        <p
                                            className='forgotPassword'
                                            onClick={() => this.setState({ forgotPassword: true })}
                                        >
                                            Forgot Password?
                                        </p>
                                        : null
                                    }
                                </div>
                            </>
                        }
                    </form>
                </>
                {this.state.forgotPassword ?
                    <div style={{ 'position': 'absolute', 'left': '50%', 'top': '25%' }}>
                        <PasswordReset />
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default Login

Login.contextType = AppContext;
