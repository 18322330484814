import React from 'react'
import './uploadDisplay.css'
import { viewCardMode } from '../../../../global/functions'

import UploadPreview from './uploadPreview/UploadPreview'
import UploadConfirm from './uploadConfirm/UploadConfirm'

class UploadDisplay extends React.Component {
    state = {
        fileName: null,
        fileContent: '',
        selectedFile: null,
        preview: [],
        previewMessage: '',
        selectedHeader: null,
        // selectedGroup: null,
        uploadConfirmDisplay: false,
        nameFile: '',
    }

    uploadOnChange = e => {
        if (!e || !e.target.files[0]) return
        const file = e.target.files[0]
        this.readFile(e.target.files[0], (e) => {
            const fileContent = e.target.result.toString()
            const lines = fileContent.split("\n");
            // console.log(fileContent);
            const fileName = file.name.split('.').slice(0, -1).join('.')
            this.setState({
                preview: lines,
                fileContent: fileContent,
                fileName: fileName,
                previewMessage: '',
            })
        })
        
        this.setState({ selectedFile: e.target.files[0] })
        const fileExtension = e.target.files[0].name.split('.').pop()
        if (fileExtension !== 'txt') {
            return this.setState({
                preview: [],
                previewMessage: 'Please select a .txt file'
            })
        }
        
    }

    readFile = (file, callback) => {
        const fileReader = new FileReader()
        fileReader.onload = callback
        fileReader.readAsText(file)
    }

    headerRow = (row) => {
        this.setState({ selectedHeader: row })
    }

    dataFileConfirm = e => {
        // console.log('dataFileConfirm');
        e.preventDefault()
        const { selectedHeader } = this.state
        const nameInput = this.nameInput.value
        // console.log(selectedHeader);
        // const selectedParent = parseInt(this.parentGroup.value)
        // const parentGroup = this.props.dataGroups.findIndex(({ group_id }) => group_id === selectedParent)
        if (nameInput && this.props.groupNode && (selectedHeader !== null)) {
            // console.log('dataFileConfirm not should show UploadConfirm');
            viewCardMode(true, 'uploadDisplay')
            this.setState({
                uploadConfirmDisplay: true,
                nameFile: nameInput,
                // selectedGroup: this.props.dataGroups[parentGroup] || this.state.selectedGroup
            })
        }
    }

    closeConfirm = () => {
        this.setState({ uploadConfirmDisplay: false })
        viewCardMode(false, 'uploadDisplay')
    }

    render() {
        const { groupNode, selectedAccount } = this.props
        return (
            <div className='uploadDisplay'>
                <form
                    id='dataForm'
                    className='dataFileForm'
                    onSubmit={this.dataFileConfirm}
                >
                    <div className='alignContainer'>
                        <UploadPreview
                            selectedFile={this.state.selectedFile}
                            previewMessage={this.state.previewMessage}
                            preview={this.state.preview}
                            selectedHeader={this.state.selectedHeader}
                            headerRow={this.headerRow}
                            uploadOnChange={this.uploadOnChange}
                            handleUploadClick={this.handleUploadClick}
                            nameRef={ref => (this.nameInput = ref)}
                            isPreviewLoaded={this.state.isPreviewLoaded}
                            selectParentDropDownChange={this.selectParentDropDownChange}
                            // selectedGroupFocus={this.state.selectedGroupFocus}
                            groupNode={groupNode}
                            dataGroups={this.props.dataGroups}
                            parentRef={ref => (this.parentGroup = ref)}
                        />
                        <div className='uploadDataFileBtns'>
                            <button
                                className='closeUploadDataFile'
                                type='button'
                                onClick={() => this.props.toolBarDisplayOption(false, 'uploadDisplay')}
                            >
                                Close
                            </button>
                            <button
                                type='submit'
                                className='uploadDataFileSubmitBtn'
                            >
                                Upload Data File
                            </button>
                        </div>
                    </div>
                </form>
                {this.state.uploadConfirmDisplay ?
                    <UploadConfirm
                        selectedAccount={selectedAccount}
                        selectedFile={this.state.selectedFile}
                        fileName={this.state.fileName}
                        fileContent={this.state.fileContent}
                        nameFile={this.state.nameFile}
                        headerRow={this.state.preview[this.state.selectedHeader]}
                        selectedHeader={this.state.selectedHeader}
                        groupNode={groupNode}
                        closeConfirm={this.closeConfirm}
                        refresh={this.props.refresh}
                        mainFetch={this.props.mainFetch}
                        toolBarDisplayOption={this.props.toolBarDisplayOption}
                    />
                    : null
                }
            </div>
        )
    }
}

export default UploadDisplay