import React from 'react'
import './passwordReset.css'

class PasswordReset extends React.Component {
    render() {
        return (
            <div className='passwordResetDiv'>
                <p>Click on reset password to have a temporary password sent to your email</p>
                <button>Reset Password</button>
            </div>
        )
    }
}

export default PasswordReset