import React from 'react'
import searchIcon from '../../images/searchIcon.png'

class AssignSearch extends React.Component {
    render() {

        const { rowSelected, selectedUser, handleSearchInput, 
            submitSearch, assignAccount, selectedAccount } = this.props

        let searchName = "";
        if(selectedAccount&& selectedUser){

            searchName =assignAccount ? selectedAccount.full_name :
            selectedUser.registry_full_name
        }
        
        let defaultPlaceholder = assignAccount ? 'Search for Account' :
            'Search for User'
        return (
            <div className='assignSearchQueryDiv'>
                <input
                    className='assignSearchBar'
                    type='search'
                    onChange={handleSearchInput}
                    results='0'
                    placeholder={rowSelected ? searchName : defaultPlaceholder}
                    style={{color: rowSelected ? '#3B55E6' : '#7C7F8B'}} 
                />
                <button
                    className='assignSearchBtn'
                    type='button'
                    onClick={submitSearch}
                >
                    <img className='searchIcon' src={searchIcon} alt='Search User'/>
                </button>
            </div>
        )
    }
}

export default AssignSearch