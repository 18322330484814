import React from 'react';
import { AppContext } from '../../../../../global/app-context';
import './uploadConfirm.css';

class UploadConfirm extends React.Component {
    state = {
        responseMessage: '',
        confirmBtn: 'Confirm'
    }

    confirmUpload = async () => {
        try {
            this.changeBtnPropertiesOnSubmit()
            const request = {
                'schema': this.props.selectedAccount.account_schema_name,
                'fileName': this.props.fileName,
                'fileContent': this.props.fileContent,
                'headerRow': this.props.selectedHeader,
                'newFileName': this.props.nameFile,
                'group': this.props.groupNode.id,
            }

            const data = await this.context.fetch_functions.fetch_uploadDataFile(request)
            if (data.status === 0) {
                this.props.refresh();
                this.props.toolBarDisplayOption(false, 'uploadDisplay');
            } else {
                this.setState({
                    responseMessage: data.msg,
                })
            }
        } catch (error) {
            this.setState({
                responseMessage: error.toString(),
                confirmBtn: 'Confirm'
            })
        }
    }

    changeBtnPropertiesOnSubmit = () => {
        document.getElementById('uploadConfirmBtn').disabled = true
        document.getElementById('uploadConfirmCancels').disabled = true
        this.setState({ confirmBtn: 'Uploading...' })
    }

    render() {
        const { nameFile, headerRow,selectedFile } = this.props
        const { confirmBtn, responseMessage } = this.state
        return (
            <div className='uploadConfirm'>
                {responseMessage === '' ?
                    <>
                        <div className='uploadConfirmBody'>
                            <h2>Please confirm you want to upload:</h2>
                            <p><span className='descriptor'>File</span>:{' '}
                                {selectedFile.name}{' '}
                                <span className='descriptor'>as</span>{' '}
                                {nameFile}
                            </p>
                            <p>
                                <span className='descriptor'>Under Data Group:</span>{' '}
                                {this.props.groupNode.label}
                            </p>
                            <p>
                                <span className='descriptor'>Selected Row as Header:</span>{' '}
                                {headerRow}
                            </p>
                        </div>
                        <div className='uploadButtons'>
                            <button
                                id='uploadConfirmCancels'
                                className='uploadConfirmCancels'
                                type='button'
                                onClick={this.props.closeConfirm}
                            >
                                Cancel
                            </button>
                            <button
                                id='uploadConfirmBtn'
                                className='uploadConfirmBtn'
                                type='button'
                                onClick={this.confirmUpload}
                            >
                                {confirmBtn}
                            </button>
                        </div>
                        <p id='uploadResponse'>{this.state.responseMessage}</p>
                    </>
                    :
                    <div className='uploadFinished'>
                        <p>{responseMessage}</p>
                        <button
                            className='uploadConfirmBtn'
                            type='button'
                            onClick={this.props.closeConfirm}
                        >
                            Close
                        </button>
                    </div>
                }
            </div>
        )
    }
}

export default UploadConfirm

UploadConfirm.contextType = AppContext;