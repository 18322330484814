import React from 'react';
import './listOfUsers.css';
import UserTable from '../../users/userList/UserTable';
import UserListOptions from './UserListOptions';
import {
    sortNamesGlobal,
    sortDescriptionGlobal,
    sortDateGlobal,
    removeOpacityDiv,
} from '../../../global/functions';
import LoadingSpinner from '../../../global/globalComponents/LoadingSpinner';

import { AppContext } from '../../../global/app-context';
class ListOfUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: [],
            rowSelected: false,
            selectedUsers: {},
            msg: '',
            currentColumn: '',
            currentArrowDirection: true,
            loading: false,
            allowMultipleSelection: false,
        }
    }

    componentDidMount() {
        this.loadUserData();        
    }

    loadUserData = async () => {
        this.setState({ 
            rowSelected: false,
            selectedIndex: null,
            selectedUser: null,
            msg: '',
            loading: true,
        })
        const resp = await this.context.fetch_functions.fetch_all_users();
        if (!resp.status) {
            this.setState({ userData: resp.body });
        } else {
            this.setState({ msg: resp.msg });
        }
        if (this.state.currentColumn !== '') {
            this.sortListOfUsers(this.state.currentColumn, this.state.currentArrowDirection);
        }
        this.setState({ loading: false });
        this.setState({ render: true });
    }

    sortListOfUsers = (column, arrowDirection) => {
        const { userData } = this.state;
        let sortedList;
        if (column === 'registry_profile') {
            sortedList = sortDescriptionGlobal(column, arrowDirection, userData);
        } else if (column === 'registry_created'
            || column === 'registry_updated'
            || column === 'rolvaliduntil') {
            sortedList = sortDateGlobal(column, arrowDirection, userData);
        } else {
            sortedList = sortNamesGlobal(column, arrowDirection, userData);
        }
        this.setState({ 
            userData: sortedList,
            currentColumn: column,
            currentArrowDirection: arrowDirection
        });
    }

    onSelectedUser = (userNum, selected) => {
        const rowSelected = Object.keys(this.state.selectedUsers).length > 0 ? true : false;
        this.setState({
            rowSelected,
        });
    };

    updateUser = (updateValue) => {
        let updatedList = this.state.userData.map(user =>
            user.registry_id === updateValue.registry_id ?
                user = updateValue : user
        )

        this.setState({ userData: updatedList });
    }

    render() {

        // just in case it was left by a prev page
        removeOpacityDiv();
        const { userData, loading, selectedUsers } = this.state;     
        const keys = Object.keys(selectedUsers);
        const idx = userData.findIndex(x => x.registry_id.toString() === keys[0]);
       
        return (
            <div className='listOfUsers'>
                <div className="clError" >{this.state.msg}</div>
                {loading ? 
                    <LoadingSpinner
                        className="usersLoadingSpinner"
                    /> :
                    <div className='userListContainer'>
                        <UserListOptions
                            {...this.props}
                            rowSelected={this.state.rowSelected}
                            selectedUser={userData[idx]}
                            updateUser={this.updateUser}
                            reRenderTable={this.reRenderTable}
                            accountList={this.props.accountList}
                            loadUserData={this.loadUserData}
                        />
                        <UserTable
                            assignUser={true}
                            data={this.state.userData}
                            onSelectedUser={this.onSelectedUser}
                            selectedUsers={this.state.selectedUsers}
                            allowMultipleSelection={this.state.allowMultipleSelection}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default ListOfUsers;

ListOfUsers.contextType = AppContext;