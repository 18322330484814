import React from 'react';
import CheckboxTree from 'react-checkbox-tree';

import checkedBox from '../../images/checked_box.png';
import neutralCheckbox from '../../images/neutral_checkbox.png'
import filledCheckBox from '../../images/filled_checkbox.png'
import triangleRight from '../../images/triangle_right.png'
import triangleDown from '../../images/downwards_triangle.png'
import greyFolder from '../../images/folder_data_group.png'
import documentData from '../../images/document_data_group.png'



class PlaySeanTree extends React.Component {
    state = {
        checked: [],
        expanded: [],
        root: [],
    };

    componentDidMount() {
        this.setState({ root: seanData.dgroot });
    }


    render() {

        console.log(this.state.root);

        return (
            <div>
                <h1>Hello Sean</h1>
                <CheckboxTree
                    nodes={this.state.root}
                    checked={this.state.checked}
                    expanded={this.state.expanded}
                    onCheck={this.onChecked}
                    onExpand={expanded => this.setState({ expanded })}
                    noCascade={true}
                    icons={{
                        check: <img src={checkedBox} alt='checked' />,
                        uncheck: <img src={neutralCheckbox} alt='checkbox' />,
                        halfCheck: <img src={filledCheckBox} alt='half checked' />,
                        expandClose: <img src={triangleRight} id='tsRightArrow' alt='closed' />,
                        expandOpen: <img src={triangleDown} alt='open' />,
                    }}
                />

            </div>
        )
    }
}

export default PlaySeanTree;

const seanData = {

    "dgroot": [
        {
            "id": 2,
            icon: <img src={greyFolder} alt='data folder' />,
            // "icon": "",
            "type": "dg",
            "label": "Fox Creek",
            "value": "dg2",
            "children": [
                {
                    "id": 3,
                    icon: <img src={greyFolder} alt='data folder' />,
                    "type": "dg",
                    "label": "Expansion III",
                    "value": "dg3",
                    "children": [
                        {
                            "id": 1,
                            icon: <img src={documentData} alt='document' />,                            "type": "doc",
                            "label": "Document 1",
                            "value": "qg1",
                            "children": [
                                
                            ]
                        }
        
                    ]
                }
            ]
        },
        {
            "id": 6,
            icon: <img src={greyFolder} alt='data folder' />,
            "type": "dg",
            "depth": 1,
            "label": "Blizzard",
            "order": 1,
            "value": "dg6",
            "parent": "dgroot",
            "keypath": [
                "dg6"
            ],
            "numpath": [
                6
            ],
            "children": [
                {
                    "id": 7,
                    icon: <img src={greyFolder} alt='data folder' />,
                    "type": "dg",
                    "depth": 2,
                    "label": "FEL-2 Estimate",
                    "order": 1,
                    "value": "dg7",
                    "parent": "dg6",
                    "keypath": [
                        "dg6",
                        "dg7"
                    ],
                    "numpath": [
                        6,
                        7
                    ],
                }
            ]
        }
    ]
}