import React from 'react';

import {yyyy_mm_dd_time} from "../../global/functions";

class PlayCookie extends React.Component {

    
    myFetch = async (api) => {
        const res = await fetch(api, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-type': 'application/json' },
            // body: JSON.stringify({"hello":"World"}),
        })
        // const jsonDat = await res.json();
        const text = await res.text();
        return text;
    }
    
    onGet = async () => {
        const val = await this.myFetch('http://localhost:5000/lfsget');
        console.log(val);
        console.log(document.cookie);
    }

    onSet = async () => {
        const val = await this.myFetch('http://localhost:5000/lfsset');
        console.log(val);
        console.log(document.cookie);
    }

    onPlay = () => {
        console.log(yyyy_mm_dd_time());
    }

    
    render() {
        return (
            <div>
                <button onClick={this.onGet}>Get</button>
                <button onClick={this.onSet}>Set</button>
                <button onClick={this.onPlay}>Play</button>
            </div>
        )
    }
}

export default PlayCookie;
