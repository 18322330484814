import React from 'react';
import sortArrow from '../../../images/HeaderDropDown.png';
import {
    formatDates,
    createTableDescriptions,
    sortNamesGlobal,
    sortDateGlobal,
} from '../../../global/functions';

const NAME_COLUMN = 'registry_full_name';
const EMAIL_COLUMN = 'registry_email';
const CREATED_COLUMN = 'registry_created';
const UPDATED_COLUMN = 'registry_updated';
const EXPIRY_COLUMN = 'rolvaliduntil';

class UserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            isLoaded: true,
            sortByColumn: NAME_COLUMN,
            sortDecending: false,
        };
    }



    componentDidUpdate() {

        if (this.props.data !== this.state.tableData) {
            this.sortData(this.state.sortByColumn, this.state.sortDecending, this.props.data);
        }
    }

    onSelectUser = (e, user) => {
        const checked = e.currentTarget.checked;
        const id = user.registry_id;
        
        if (!this.props.allowMultipleSelection) {
            for (const i in this.props.selectedUsers) {
                delete this.props.selectedUsers[i];
            }
        }
        
        if (checked) {
            this.props.selectedUsers[id] = checked;
        } else {
            delete this.props.selectedUsers[id];
        }
        
        this.setState({ rerender: true });
        
        this.props.onSelectedUser(user.registry_id, e.currentTarget.checked);
    };
    
    onSortDirectionChange = (sortByColumn) => {
        let sortDecending = sortByColumn !== this.state.sortByColumn
            ? false
            : !this.state.sortDecending;

        this.setState({
            sortByColumn,
            sortDecending,
        });
        this.sortData(sortByColumn, sortDecending, this.props.data);
    };

    sortData = (sortByColumn, sortDecending, dataToSort) => {
        let tableData =
            sortByColumn === 'registry_created' || sortByColumn === 'registry_updated'
                ? sortDateGlobal(sortByColumn, sortDecending, dataToSort)
                : sortNamesGlobal(sortByColumn, sortDecending, dataToSort);
        this.setState({
            tableData,
        });
    };

    expDateBG = (expiryRow) => {
        let oneMonthAgoToday = new Date();
        oneMonthAgoToday.setMonth(oneMonthAgoToday.getMonth() - 1);

        let thirtyDaysBeforeExpire = new Date(expiryRow);
        thirtyDaysBeforeExpire.setMonth(thirtyDaysBeforeExpire.getMonth() - 1);

        let differenceInTime = thirtyDaysBeforeExpire.getTime() - oneMonthAgoToday.getTime();
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);

        if (differenceInDays <= 0) {
            return '#fda2a2';
        } else if (differenceInDays > 0 && differenceInDays <= 31) {
            return '#F7CF81';
        }
    };

    render() {
        const { sortByColumn, sortDecending } = this.state;

        return (
            <div className="userTableContainer">
                <table className="listOfUsersTable">
                    <thead>
                        <tr>
                            <th className="checkboxColumn"></th>
                            <th
                                id="name"
                                onClick={() =>
                                    this.onSortDirectionChange(NAME_COLUMN)
                                }
                            >
                                Name
                                {sortByColumn === NAME_COLUMN ? (
                                    <img
                                        id="name"
                                        src={sortArrow}
                                        className="tableSortArrow"
                                        alt="sorting arrow"
                                        onClick={() =>
                                            this.onSortDirectionChange(
                                                NAME_COLUMN
                                            )
                                        }
                                        style={{
                                            transform: sortDecending
                                                ? 'rotateX(180deg)'
                                                : 'rotateX(0deg)',
                                        }}
                                    />
                                ) : null}
                            </th>
                            <th
                                id="email"
                                onClick={() => this.onSortDirectionChange(EMAIL_COLUMN)}
                            >
                                Email
                                {sortByColumn === EMAIL_COLUMN ? (
                                    <img
                                        id="email"
                                        src={sortArrow}
                                        className="tableSortArrow"
                                        alt="sorting arrow"
                                        onClick={() =>
                                            this.onSortDirectionChange(
                                                EMAIL_COLUMN
                                            )
                                        }
                                        style={{
                                            transform: sortDecending
                                                ? 'rotateX(180deg)'
                                                : 'rotateX(0deg)',
                                        }}
                                    />
                                ) : null}
                            </th>
                            <th id="description">Properties</th>
                            <th
                                id="registry_created"
                                onClick={() => this.onSortDirectionChange(CREATED_COLUMN)}
                            >
                                User Created
                                {sortByColumn === CREATED_COLUMN ? (
                                    <img
                                        src={sortArrow}
                                        className="tableSortArrow"
                                        alt="sorting arrow"
                                        onClick={() => this.onSortDirectionChange(CREATED_COLUMN)}
                                        style={{
                                            transform: sortDecending
                                                ? 'rotateX(180deg)'
                                                : 'rotateX(0deg)',
                                        }}
                                    />
                                ) : null}
                            </th>
                            <th
                                id="registry_updated"
                                onClick={() => this.onSortDirectionChange(UPDATED_COLUMN)}
                            >
                                User Updated
                                {sortByColumn === UPDATED_COLUMN ? (
                                    <img
                                        src={sortArrow}
                                        className="tableSortArrow"
                                        alt="sorting arrow"
                                        onClick={() =>
                                            this.onSortDirectionChange(
                                                UPDATED_COLUMN
                                            )
                                        }
                                        style={{
                                            transform: sortDecending
                                                ? 'rotateX(180deg)'
                                                : 'rotateX(0deg)',
                                        }}
                                    />
                                ) : null}
                            </th>
                            <th
                                id="roleExpiryDate"
                                onClick={() => this.onSortDirectionChange(EXPIRY_COLUMN)}
                            >
                                User Expiry Date
                                {sortByColumn === EXPIRY_COLUMN ? (
                                    <img
                                        src={sortArrow}
                                        className="tableSortArrow"
                                        alt="sorting arrow"
                                        onClick={() =>
                                            this.onSortDirectionChange(
                                                EXPIRY_COLUMN
                                            )
                                        }
                                        style={{
                                            transform: sortDecending
                                                ? 'rotateX(180deg)'
                                                : 'rotateX(0deg)',
                                        }}
                                    />
                                ) : null}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tableData.map((user) => (
                            <tr
                                key={user.registry_id}
                                id={user.registry_id}
                                className="userListTR"
                            >
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={this.props.selectedUsers[user.registry_id] ? true : false}
                                        onChange={(e) => { this.onSelectUser(e, user); }}
                                    />
                                </td>
                                <td className="cell">
                                    {user.registry_full_name}
                                </td>
                                <td className="cell">{user.registry_email}</td>
                                <td className="cell">
                                    {createTableDescriptions(user.registry_properties)}
                                </td>
                                <td className="cell">
                                    {formatDates(user.registry_created)}
                                </td>
                                <td className="cell">
                                    {formatDates(user.registry_updated)}
                                </td>
                                <td
                                    className="cell"
                                    style={{
                                        backgroundColor: this.expDateBG(
                                            user.rolvaliduntil
                                        ),
                                    }}
                                >
                                    {formatDates(user.rolvaliduntil)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default UserTable