// 
// Test with 127.0.0.1:3000 and not localhost
// 

import React from 'react';
import './css/App.css';
import './global/globalStyles.css'
import TopBarNav from './components/navigation/TopBarNav'
import Login from './components/login/Login';
import UserProfile from './components/accounts/profile/UserProfile';
import AccountListContainer from './components/accounts/accountList/AccountListContainer';
import ListOfUsers from './components/users/userList/ListOfUsers'
import pvLogo from './images/pvLogo.png'
import lmLogo from './images/lean-minds.jpg'
import { functions as fetch_functions } from './global/fetch'
import { removeOpacityDiv } from './global/functions'
import Play from './components/play/Play';
import settings from './global/settings.js';
import LoadingSpinner from './global/globalComponents/LoadingSpinner';

import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";

import { AppContext } from './global/app-context';
// import { userInfo } from './components/play/sampleData/sampleUserInfo';

const PERMISSION_LEVELS = {
  systemAdmin: 'System Admin',
  accountAdmin: 'Account Admin',
  baseUser: 'Base',
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      loading: true,
      data: [],
      userMenuDisplay: false,
      accountNav: false,
      userNav: false,
      userPermissionLevel: '',
      userInfo: null,
      errorMessage: false,
      viewPage: 'home',
      // ------- ---------
      setUserInfo: this.setUserInfo,
      logout: this.logout,
      login: this.login,
      msg: "",
      errorMsg: "",
      fetch_functions,
      PERMISSION_LEVELS,
    }

  }

  componentDidMount() {
    this.loginWithSession()
  }

  /*
    If this user has a session id in a cookie, attempt to login with it.
    If the user is changing their email or password handle that as well.
    In most cases, user and password will be null and therefore use the session's.
    If there is an error the server is not running.
  */
  loginWithSession = async (email, password) => {

    try {
      const resp = await this.state.fetch_functions.fetch_login_with_session({ email, password });
      if (resp.status) {
        this.setState({
          errorMsg: resp.msg,
          loading: false,
        });
      } else {
        this.setUserInfo(resp.session);
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({
        errorMsg: "System unavailable contact support@pointverge.com",
        loading: false
      });
    }
  }

  updateData = (data = null) => {

    if (data === null) {
      this.setState({ data: this.state.data })
    } else {
      this.setState({ data: data })
    }
  }

  setUserInfo = userInfo => {
    this.setState({ userInfo: userInfo })
  }

  navViewPage = page => {

    this.setState({ viewPage: page })
    removeOpacityDiv();
  }

  logout = () => {
    this.state.fetch_functions.fetch_logout();
    this.setState({
      userInfo: null,
    })
    removeOpacityDiv();
  }

  updateUserData = (user) => {
    let userInfo = {
      name: user.name,
      email: user.email,
      properties: user.properties,
      user_level: this.state.userInfo.user_level,
      expiry: this.state.userInfo.expiry,
      role_name: this.state.userInfo.role_name
    }
    this.setState({
      userInfo
    });
  }

  render() {
    const { loading } = this.state;
    // just in case it was left by a prev page

    removeOpacityDiv();
    return (
      <AppContext.Provider value={this.state}>
        <Router>
          <div className="App" id="App">
            {loading ? <LoadingSpinner className="loadingHeading" /> :
              <>
                {/* Setup for test and dev and playing */}
                {settings.ENV === 'local' ? <Play /> : null}
                <TopBarNav />
                <div className='clBody'>{/*HOME COMPONENT IS CHILD OF COMPCONTAINER*/}
                  <Authorized>
                    <Switch>
                      <Route path="/myprofile">
                        <UserProfile
                          userInfo={this.state.userInfo}
                          modifySelf={true}
                          loginWithSession={this.loginWithSession}
                          create={false}
                          myProfile={true}

                        />
                      </Route>
                      <Route path="/users"> <ListOfUsers /> </Route>
                      <Route path="/"> <AccountListContainer /> </Route>
                    </Switch>
                  </Authorized>
                  <span>
                    <a href="https://www.leanminds.ca/">
                      <img src={lmLogo} className='lmLogoHome ' alt='lean minds logo' />
                    </a>
                  </span>
                  <span className="pvLogoHome clToolTip">
                    <img src={pvLogo} className='pvLogoHome ' alt='point verge logo' />
                    <span className="clToolTipText clVersion">v:{settings.RELEASE}-{settings.ENV}</span>
                  </span>
                </div>
              </>
            }
          </div>
        </Router>
      </AppContext.Provider>
    )
  }

}

export default App;


function Authorized(props) {

  return (
    <AppContext.Consumer>
      {({ userInfo }) => (
        <>
          {userInfo
            ? props.children
            : <Login />
          }
        </>
      )}
    </AppContext.Consumer>
  )
}
