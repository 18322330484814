import React from 'react'

class EditAssignRemove extends React.Component {
    render() {
        const { selectedUser, selectedAccount,
            responseMessage, errorMessage, disableSubmit } = this.props;
        return (
            <div className='confirmBox'>
                {responseMessage === '' ?
                <>
                    <p>
                        Are you sure you want to remove {' '}
                        {selectedUser.registry_full_name} from {' '} 
                        {selectedAccount.account_full_name}?
                    </p>
                    <div className='confirmBoxBtnDiv'>
                        <button
                            onClick={()=>this.props.confirmBoxSubmit(false)}
                            className='confirmBoxCancelBtn'
                            disabled={disableSubmit}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={()=>this.props.confirmBoxSubmit(true, 'remove')}
                            className='removeAcctSubmit'
                            disabled={disableSubmit}
                        >
                            {disableSubmit ? 'Removing...' : 'Remove'}
                        </button>
                    </div>
                </>
                :
                <div className='assignAccountAfterSubmit'>
                    {errorMessage ?                     
                        <p>{errorMessage}</p>
                        :
                        null
                    }
                    {responseMessage ?
                        <p className='assignAccountGranted'>
                            {responseMessage.message}
                        </p>
                        :
                        null
                        
                    }
                    <button
                        className='confirmBoxCloseBtn'
                        onClick={()=>this.props.confirmBoxSubmit(false)}
                    >
                        Close
                    </button>
                </div>
                }
            </div>
        )
    }
}

export default EditAssignRemove