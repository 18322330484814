import React from 'react';
import { NavLink } from 'react-router-dom';
// import miLogo from '../../images/MI_logo.png';
import "./navigation.css";
import {AppButton} from '../../global/globalComponents/AppButton';

import {AppContext} from "../../global/app-context";

class TopBarNav extends React.Component {

  render() {
    const {userInfo} = this.context;
    const { systemAdmin } = this.context.PERMISSION_LEVELS;
    return (
      <AppContext.Consumer>
        {({userPermissionLevel, logout}) => (
        <div className='navBar'>
          <ul className='navBarList'>
            {/* <li><img src={miLogo} alt="material inspector logo" /></li> */}
            <li style={{fontSize: "40px"}}>QuBR</li>
            {userInfo ?
              <>
                <li> 
                  <span className="clToolTip">({userInfo.user_level}) 
                    <span className="clToolTipText">{userInfo.email}</span>
                  </span>
                </li>
                <li>
                  <NavLink to='/myprofile'>
                    <AppButton enabled={true}>
                      My Profile
                    </AppButton>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/accounts'>
                    <AppButton enabled={true}>
                      Accounts
                    </AppButton>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/users'>
                    <AppButton enabled={true} securityRequired={systemAdmin}>
                      Users
                    </AppButton>
                  </NavLink>
                </li>
                <li>
                  <AppButton enabled={true} className="clLink" onClick={() => logout()}>
                    Logout
                  </AppButton>
                </li>
              </>
            : null}
          </ul>
        </div>
        )}
      </AppContext.Consumer>
    )
  }
}

export default TopBarNav


TopBarNav.contextType = AppContext;
