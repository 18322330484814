import React from 'react';
import { functions } from '../../../../global/fetch';
import './deleteAccount.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';


class DeleteAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            deleteMessage: 'Are you sure you want to delete this account?'
        }
    }

    confirmedDelete = async () => {
        const resp = await functions.fetch_delete_account(this.props.selectedAccount.account_id);
        if (resp.msg === "Ok") {
            this.props.showSelectedDisplay(false, "deleteAccount");
            this.props.loadAccountData();
        } else {
            this.setState({ deleteMessage: resp.msg })
        }
    }

    canceledDelete = () => {
        this.props.showSelectedDisplay(false, "deleteAccount");
    }

    render() {
        return (
            <div className="deleteAccountContainer">
                <div className="accountNameDisplay">
                    Account selected for deletion: {this.props.selectedAccount.account_full_name}
                </div>
                <div className="deleteMessage">
                    {this.state.deleteMessage}
                </div>
                <div className="deleteAccountBtnsContainer">
                    <AppButton enabled={true} className="button" onClick={this.confirmedDelete}>
                        Yes
                    </AppButton>
                    <AppButton enabled={true} className="button" onClick={this.canceledDelete}>
                        No
                    </AppButton>
                </div>
            </div>
        )
    }
}

export default DeleteAccount;