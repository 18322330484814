import React from 'react'

class AssignAccountConfirm extends React.Component {
    render() {
        const { selectedAccount, selectedPermission,
            selectedUser, responseMessage, errorMessage,
            disableSubmit } = this.props
        return (

            <div className='confirmBox'>
                {responseMessage === '' ?
                    <>
                        <div id='assignConfirmBox'>
                            <p>
                                Are you sure you want to assign:
                            <br />
                            User:{' '}
                                <b><span className='confirmBoxUserName'>
                                    {selectedUser.registry_full_name}
                                </span></b>
                                <br />
                            To Account: <b>({selectedAccount.account_short_name}){' '}
                                    {selectedAccount.account_full_name}</b>
                                <br />
                            With Permission: <b>{selectedPermission}</b>
                            </p>
                        </div>
                        <div className='confirmBoxBtnDiv'>
                            <button
                                onClick={() => this.props.confirmBoxSubmit(false)}
                                className='confirmBoxCancelBtn'
                                disabled={disableSubmit}
                            >
                                Cancel
                        </button>
                            <button
                                onClick={() => this.props.confirmBoxSubmit(true, 'add')}
                                className='confirmBoxAssignBtn'
                                disabled={disableSubmit}
                            >
                                {disableSubmit ? 'Assigning...' : 'Assign'}
                            </button>
                        </div>
                    </>
                    :
                    <div className='assignAccountAfterSubmit'>
                        {errorMessage ?
                            <p>{errorMessage}</p>
                            :
                            null
                        }
                        {
                            responseMessage.success === true ?
                                <p className='assignAccountGranted'>
                                    {responseMessage.message}
                                </p>
                                :
                                <p className='assignAccountFailed'>
                                    {responseMessage}
                                </p>
                        }
                        <button
                            className='confirmBoxCloseBtn'
                            onClick={() => this.props.confirmBoxSubmit(false)}
                        >
                            Close
                    </button>

                    </div>
                }
            </div>
        )
    }
}

export default AssignAccountConfirm