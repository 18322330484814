import React from 'react';
import { Tags } from '../../tag/Tags';
import { AppButton } from '../../../global/globalComponents/AppButton';
import { AppContext } from '../../../global/app-context';
import LoadingSpinner from '../../../global/globalComponents/LoadingSpinner';
import './UserProfile.css';
import FocusLock from 'react-focus-lock';
class UserProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            registry_id: 0,
            email: this.props.userInfo.email,
            full_name: this.props.userInfo.name,
            tags: this.props.userInfo.properties || {},
            expiry: this.props.userInfo.expiry,
            user_level: this.props.userInfo.user_level,
            password: "",
            confirmPassword: "",
            updateBtnDisplay: "Update",
            message: null,
            msgType: null,
            buttonOn: true,
            modifySelf: this.props.modifySelf,
            loading: true,
            previousEmail: '',
            previousName: '',
            previousProperties: '',

        }
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onResetForm = this.onResetForm.bind(this);
    }


    async componentDidMount() {
        if (!this.state.registry_id || this.state.registry_id === 0) {
            if (this.state.email !== '') {
                // get registry id, so admin can edit user
                const data = await this.context.fetch_functions.fetch_user_info(this.state.email);

                if (data.status === 0) {
                    this.setState({
                        registry_id: data.body.Parameters.registry_id,
                        updateBtnDisplay: "Update",
                        create: false,
                    });
                }
            } else {

                this.setState({
                    updateBtnDisplay: "Create",
                    create: true
                });
            }
        }
        this.setState({
            user_level: this.props.userInfo.user_level,
            loading: false,
            message: null,
        });
    }




    submitData = async () => {

        let message = '', error = "";
        // update button text
        let btnText = this.state.create ? "Creating..." : "Updating";
        // reset button text and clear error
        this.setState({
            updateBtnDisplay: btnText,
            msgType: null,
            message: null,
        });

        // Full Name cannot be a empty string
        if (this.state.full_name === "") {
            error = "error";
            message = "Full Name is required. ";
        }

        // Email cannot be a empty string
        if (this.state.email === "") {
            error = "error";
            message += "Email is required. ";
        }

        if (error === 'error') {
            this.setState({
                message,
                error
            });
        }
        else {

            // Editing current logged In user
            if (!this.props.create && this.props.myProfile) {
                // update user logged in "uses session to update"
                try {
                    // fetch returns: "Cannot read property 'Status' of undefined"


                    let data = await this.context.fetch_functions.fetch_userEditProfile(this.state.email, this.state.full_name, JSON.stringify(this.state.tags))
                    if (data.status === 0) {
                        this.setState({
                            email: data.body.Parameters.email,
                            previousEmail: data.body.Parameters['previous email'],
                            full_name: data.body.Parameters.name,
                            tags: data.body.Parameters.properties,
                            message: data.body.Status.message,
                            msgType: "info",

                        });
                        // if user change email address, log user in with session
                        if (this.state.previousEmail !== this.state.email) {
                            await this.props.loginWithSession(this.state.email, null);
                        }
                    }
                    else {
                        this.setState({
                            message: data.body.Status.message,
                            msgType: "error",
                        });
                        throw new Error(data.msg);
                    }
                    // end fullName and email change

                    // if user changing password 
                    if (this.state.password !== '') {
                        if (this.validatePassword()) {
                            // if both password and confirm password are valid
                            // uing seesion data to identify user
                            let passwordResult = await this.context.fetch_functions.fetch_password_set_self(this.state.password);

                            if (passwordResult.status === 0) {
                                this.setState({
                                    message: passwordResult.body.Status.message,
                                    msgType: "info",
                                });
                                await this.props.loginWithSession(this.state.email, this.state.password);
                            } else {
                                this.setState({
                                    message: passwordResult.body.Status.message,
                                    msgType: "error",
                                });
                            }
                        }
                        // clear password and confirm password textbox
                        this.clearPasswordFields()
                    } // end of password change

                } catch (error) {
                    this.setState({
                        msgType: 'error',
                        message: error.message
                    })
                }
            }

            // creating a new user
            if (this.props.create && !this.props.myProfile) {

                try {

                    // if (this.state.password !== '') {

                    if (this.validatePassword()) {
                        // create a new user               
                        let data = await this.context.fetch_functions.fetch_user_registry_insert(this.state.email, this.state.full_name, JSON.stringify(this.state.tags));

                        if (data.status === 0) {
                            this.setState({
                                registry_id: data.body.Parameters.registry_id,
                                message: data.body.Status.message,
                                msgType: "info",
                            });
                        } else {
                            this.setState({
                                message: data.msg,
                                msgType: "error",
                            });
                            throw new Error(this.state.message);
                        }

                        data = await this.context.fetch_functions.fetch_password_set(this.state.registry_id, this.state.password);

                        if (data.status === 0) {
                            this.setState({
                                // message: data.body.Status.message, // this overwrites 'user created' message
                                msgType: "info",
                            });
                        } else {
                            this.setState({
                                message: data.msg,
                                msgType: "error",
                            });
                            throw new Error(this.state.message);
                        }
                    }


                } catch (error) {
                    console.log(error.message);
                }
            }

            // updating someones user profile
            if (!this.props.create && !this.props.myProfile) {

                let data = await this.context.fetch_functions.fetch_updateUser(this.state.registry_id, this.state.email, this.state.full_name, JSON.stringify(this.state.tags));
                if (data.status === 0) {
                    this.setState({
                        email: data.body.Parameters.email,
                        full_name: data.body.Parameters.name,
                        tags: data.body.Parameters.properties,
                        message: data.body.Status.message,
                        msgType: "info",
                    });
                } else {
                    this.setState({
                        message: data.msg,
                        msgType: "error",
                    });
                    throw new Error(this.state.message);
                }
                if (this.state.password !== '') {
                    data = await this.context.fetch_functions.fetch_password_set(this.state.registry_id, this.state.password);
                    if (data.status === 0) {
                        this.setState({
                            // message: data.body.Status.message, // this overwrites 'user edited' message
                            msgType: "info",
                        });
                    } else {
                        this.setState({
                            message: data.msg,
                            msgType: "error",
                        });
                        throw new Error(this.state.message);
                    }

                    this.clearPasswordFields()
                }
            }
        }

        btnText = this.state.create ? "Create" : "Update";

        this.setState({
            updateBtnDisplay: btnText
        });
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validatePassword = () => {
        let valid = true, type = 'info', msg = '';
        // 
        if (!this.state.password || !this.state.confirmPassword) {
            type = "error";
            msg += 'Both passwords must be entered. ';
            valid = false;
        }

        if (this.state.password !== this.state.confirmPassword) {
            if (msg) {
                type = "error";
                msg += 'Both passwords must match. ';
            }
            valid = false;
        }

        if (this.state.password.length < 6) {
            type = "error";
            msg += 'Password must be greator than 6 chars. ';
            valid = false;
        }

        if (type === "error") {
            valid = false;
            this.setState({
                message: msg,
                type: "error"
            });
        }
        return valid;
    }


    closeProfile = () => {
        this.props.closeProfile();
    }

    clearPasswordFields = () => {
        this.setState({
            password: '',
            confirmPassword: ''
        });
    }
    onChangeHandler = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onResetForm = (e) => {
        this.setState({
            registry_id: 0,
            email: '',
            full_name: '',
            tags: {},
            expiry: '',
            password: "",
            confirmPassword: "",
            message: null,
            msgType: null
        });

    }

    render() {
        const { full_name, email, password, confirmPassword, updateBtnDisplay, loading } = this.state;
        return (
            <div className="userListOptionsContainer">

                {loading ? <LoadingSpinner /> :
                    <div className='accountUpdating'>
                        <h1 className='accountUpdatingHeading'>
                            {this.state.full_name}<br />Profile
                        </h1>
                        <div className='accountUpdatingForm'>
                            <FocusLock>
                                <form>
                                    <label htmlFor="user_full_name">Full Name:</label>
                                    <input
                                        id="full_name"
                                        type="text"
                                        placeholder="Full Name"
                                        autoComplete="username"
                                        name="full_name"
                                        value={full_name}
                                        tabIndex={0}
                                        onChange={this.onChangeHandler}
                                        autoFocus
                                        required
                                    />

                                    <label htmlFor="email">Email: </label>
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        autoComplete="email"
                                        name="email"
                                        value={email}
                                        tabIndex={1}
                                        onChange={this.onChangeHandler}
                                        required
                                    />

                                    <label htmlFor="password">Password: </label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={full_name}
                                        autoComplete="username"
                                        style={{ display: "none" }}
                                        tabIndex={2}
                                        readOnly
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        autoComplete="new-password"
                                        placeholder="password"
                                        id="password"
                                        value={password}
                                        tabIndex={3}
                                        onChange={this.onChangeHandler}
                                    />
                                    <label htmlFor="confirmPassword">Confirm Password: </label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        autoComplete="new-password"
                                        id="confirmPassword"
                                        placeholder="confirm password"
                                        value={confirmPassword}
                                        tabIndex={4}
                                        onChange={this.onChangeHandler}
                                    />
                                </form>

                                <Tags tabIndex={[8, 9, 10, 11, 12]} tags={this.state.tags}
                                />

                                <span className='acctSeparation'></span>
                                {this.state.message == null ? null :
                                    <div className='accountUpdatingResult'>
                                        <span
                                            className='accountUpdatingResponseMessage'
                                            style={{
                                                color: this.state.msgType === "error" ? 'red' : 'green',
                                            }}
                                        >
                                            {this.state.message}
                                        </span>
                                    </div>
                                }
                                <div className="stackedButtons">
                                    <AppButton
                                        tabIndex={13}
                                        enabled={this.state.buttonOn}
                                        className={'CenterButton accountRegButton'}
                                        onClick={this.submitData}
                                    >
                                        {updateBtnDisplay}
                                    </AppButton>
                                    {this.state.create ?

                                        <AppButton
                                            tabIndex={-1}
                                            enabled={this.state.buttonOn}
                                            className={'CenterButton accountRegButton'}
                                            onClick={e => this.onResetForm(e)}
                                        >
                                            Clear
                                        </AppButton>
                                        : null}
                                    {this.props.closeProfile ?

                                        <AppButton
                                            tabIndex={-1}
                                            enabled={this.state.buttonOn}
                                            className={'CenterButton accountRegButton'}
                                            onClick={this.closeProfile}
                                        >
                                            Close
                                        </AppButton>
                                        : null}
                                </div>
                            </FocusLock>

                        </div>
                    </div>
                }
            </div>

        )
    }
}

export default UserProfile;
UserProfile.contextType = AppContext;