import React from 'react';
import AccountRegistry from '../AccountRegistry';
import DeleteAccount from './displayCards/DeleteAccount';
import AccountAssignUser from './displayCards/AccountAssignUser';
import WorkInAccount from '../workInAccounts/WorkInAccount';
import { viewCardMode } from '../../../global/functions';
import { AppButton } from '../../../global/globalComponents/AppButton';

import { AppContext } from '../../../global/app-context';

class AccountListOptions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            accountRegDisplay: false,
            viewProfileDisplay: false,
            assignUserCard: false,
            deleteAccount: false,
            rowSelected: this.props.rowSelected,
            assignAccount: true,
            openAccount: false,
            errorMessage: '',
            adminAccounts: [],
            isAdmin: false
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.rowSelected !== prevProps.rowSelected) {
            this.setState({ rowSelected: this.props.rowSelected })
        }
    }

    isAdmin = (account) => {
        const { adminAccounts } = this.state;
        let isAdmin;
        if (!account || !this.props.rowSelected) return;
        isAdmin = adminAccounts.some(acct => {
            return acct.account_id === account.id
        })
        return isAdmin
    }

    showSelectedDisplay = (boolView, cardDisplay) => {
        if (cardDisplay === 'accountRegDisplay') {
            viewCardMode(boolView, 'App')
            return this.setState({ accountRegDisplay: boolView })
        }
        if (this.props.rowSelected) {
            switch (cardDisplay) {
                case cardDisplay:
                    this.setState({ [cardDisplay]: boolView })
                    viewCardMode(boolView, 'App')
                    break
                default:
                    return null
            }
        }
    }



    render() {
        const { systemAdmin, accountAdmin } = this.context.PERMISSION_LEVELS;
        return (
            <>
                {this.state.errorMessage === '' ?
                    <div className="accountListOptionsContainer">
                        <div className="openAccountButtonContainer">
                            <AppButton
                                enabled={this.props.rowSelected}
                                onClick={() => this.showSelectedDisplay(true, 'openAccount')}
                            >
                                Open Account
                            </AppButton>
                        </div>
                        <span className='accountOptions'>
                            <AppButton
                                enabled={true}
                                securityRequired={systemAdmin}
                                onClick={() => this.showSelectedDisplay(true, 'accountRegDisplay')}
                            >
                                Create Account
                            </AppButton>
                            <AppButton
                                enabled={this.props.rowSelected}
                                securityRequired={accountAdmin}
                                onClick={() => this.showSelectedDisplay(true, 'viewProfileDisplay')}
                            >
                                View Account
                            </AppButton>
                            <AppButton
                                enabled={this.props.rowSelected}
                                securityRequired={accountAdmin}
                                onClick={() => this.showSelectedDisplay(true, 'assignUserCard')}
                            >
                                Assign User
                            </AppButton>
                            <AppButton
                                enabled={this.props.rowSelected}
                                securityRequired={systemAdmin}
                                onClick={() => this.showSelectedDisplay(true, 'deleteAccount')}
                            >
                                Delete Account
                            </AppButton>
                        </span>
                        {this.state.openAccount ?
                            <WorkInAccount
                                showSelectedDisplay={this.showSelectedDisplay}
                                selectedAccount={this.props.selectedAccount}
                                userInfo={this.props.userInfo}
                            />
                            : null
                        }
                        {this.state.accountRegDisplay ?
                            <AccountRegistry
                                {...this.props}
                                showSelectedDisplay={this.showSelectedDisplay}
                                loadAccountData={this.props.loadAccountData}
                                buttonText="Create"
                                componentName="accountRegDisplay"
                            /> 
                            : null
                        }
                        {this.state.viewProfileDisplay ?
                            <AccountRegistry
                                {...this.props}
                                selectedAccount={this.props.selectedAccount}
                                showSelectedDisplay={this.showSelectedDisplay}
                                loadAccountData={this.props.loadAccountData}
                                buttonText="Update"
                                componentName="viewProfileDisplay"
                            />
                            : null
                        }
                        {this.state.assignUserCard ?
                            <AccountAssignUser
                                assignAccount={this.state.assignAccount}
                                selectedAccount={this.props.selectedAccount}
                                showSelectedDisplay={this.showSelectedDisplay}
                                sortAccounts={this.props.sortAccounts}
                            />
                            : null
                        }
                        {this.state.deleteAccount ?
                            <DeleteAccount
                                selectedAccount={this.props.selectedAccount}
                                showSelectedDisplay={this.showSelectedDisplay}
                                loadAccountData={this.props.loadAccountData}
                            />
                            : null
                        }
                    </div>
                    : <p>{this.state.errorMessage}</p>
                }
            </>
        )
    }
}

export default AccountListOptions;

AccountListOptions.contextType = AppContext;