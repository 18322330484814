import React from 'react';
import CompareData from './CompareData';
import './compareMain.css';
import '../../../../css/style.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';

class CompareMain extends React.Component {

    MATCH = 0;
    TARGET = 1;
    // mode = this.MATCH;
    columns = [
        [],         // Match Array
        [],         // Target Array
    ];

    state = {
        data: null,
        errorMsg: null,
        selected: [{ attr: null }, { attr: null }],
        mode: this.MATCH,
    };

    doToggle = (index, attribute, type_code) => {
        let msg;
        let selected = this.state.selected;
        selected[index] = { attr: attribute, type: type_code };
        if (selected[0].attr && selected[1].attr) {
            if (selected[0].type === selected[1].type) {
                this.columns[this.state.mode].push([selected[0].attr, selected[1].attr])
                selected = [{ attr: null }, { attr: null }];
            } else {
                msg = 'Data types must be the same.';
            }
        }

        this.setState({ selected: selected });
        this.setState({ infoMsg: msg });
        return;
    }

    toggleMode = () => {
        const mode = this.state.mode ? 0 : 1;
        this.setState({ mode: mode });
    }

    doClear = () => {
        this.columns[this.state.mode] = [];
        this.setState({ mode: this.state.mode });
    }

    doDone = () => {
        let msg;
        if (this.columns[this.MATCH].length === 0) {
            msg = "At least one match column must be selected."
        } else if (this.columns[this.TARGET].length === 0) {
            msg = "At least one target column must be selected."
        }
        if (msg) {
            this.setState({ infoMsg: msg });
        } else {
            this.props.done(this.columns);
        }
    }

    doCancel = () => {
        this.props.done();
    }

    render() {
        const { data } = this.props;
        let toDisplay;
        if (this.state.errorMsg) {
            toDisplay = <h1>{this.state.errorMsg}</h1>
        } else if (data) {
            toDisplay =
                <>
                    <div className='shortContainer'>
                        <span className='appWarning'>{this.state.infoMsg}</span>
                    </div>
                    <div className='container'>
                        <div className='left'>
                            <div className='insert'>
                                <CompareData
                                    index={0}
                                    data={data[0]}
                                    title={this.props.files[0]}
                                    doToggle={this.doToggle}
                                    selected={this.state.selected[0].attr}
                                />
                            </div>
                            <div className='insert'>
                                <CompareData
                                    index={1}
                                    data={data[1]}
                                    title={this.props.files[1]}
                                    doToggle={this.doToggle}
                                    selected={this.state.selected[1].attr}
                                />
                            </div>
                        </div>
                        <div className='right'>

                            <div className='insert'>
                                {this.state.mode ? <span className='appHeading'>Select comparision target</span>
                                    : <span className='appHeading'>Select Columns to match on</span>
                                }
                                <br />
                                <span className='appInfo'>Columns Selected</span>
                                <ol>
                                    {this.columns[this.state.mode].map((v, i) => <li key={i}>{v[0]}</li>)}
                                </ol>
                            </div>
                            <div className='insert'>
                                <span className='appInfo'>Columns Selected</span>
                                <ol>
                                    {this.columns[this.state.mode].map((v, i) => <li key={i}>{v[1]}</li>)}
                                </ol>
                                <div className="compareButtonsContainer">
                                    <AppButton enabled={this.props.compareBtnsActive} className="compareButton" onClick={this.doCancel}>Cancel</AppButton>
                                    <AppButton enabled={this.props.compareBtnsActive} className="compareButton" onClick={this.doClear}>Clear</AppButton>
                                    <AppButton enabled={this.props.compareBtnsActive} className="compareButton" onClick={this.doDone}>{this.props.compareBtnText}</AppButton>
                                    {this.state.mode ?
                                        <AppButton enabled={this.props.compareBtnsActive} className="compareButtonLong" onClick={this.toggleMode}>Select Columns to match on</AppButton>
                                        :
                                        <AppButton enabled={this.props.compareBtnsActive} className="compareButtonLong" onClick={this.toggleMode}>Select Comparison Target</AppButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        } else {
            toDisplay = null;
        }

        return (
            <div>
                { toDisplay}
            </div>
        )
    }
}

export default CompareMain;