import React from 'react';
import { functions } from '../../../../global/fetch';
import './moveData.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';
import FocusLock from 'react-focus-lock';

class DataRegistry extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            dataName: '',
            respMessage: null,
            respMessageType: null,
            buttonText: "Move",
            currentParent: {},
            newParentId: '',
        }
    }

    parentOptions

    componentDidMount() {
        const selected = this.props.selectedNodes[0];
        if (selected) {
            const parentArray = Object.values(this.props.dgNodes);
            this.parentOptions = parentArray.map((p, i) => {
                return (
                    <option key={`parentOption${i}`} value={p.id}>{p.label}</option>
                )
            });
            this.setState({ 
                dataName: selected.label, 
                currentParent: selected.parent ? this.props.dgNodes[this.props.selectedNodes[0].parent] : null,
                newParentId: selected.type === "dg" ? '' : this.parentOptions[0].props.value
            });
        }
    }

    changeHandler = (e) => {
        this.setState({ newParentId: e.target.value });
    }

    submit = async () => {
        this.setState({ buttonText: "Moving..." });
        const node = this.props.selectedNodes[0];
        let respMessage = '';
        let respError = false;
        try {
            let data;
            let parentId = this.state.newParentId ? this.state.newParentId : null;
            if (node.type === "dg") {
                data = await functions.fetch_document_group_parent_link(
                    this.props.selectedAccount.account_schema_name,
                    node.id,
                    parentId
                );
            } else {
                if (this.state.currentParent) {
                    data = await functions.fetch_document_group_unlink(
                        this.props.selectedAccount.account_schema_name,
                        node.id,
                        this.state.currentParent.id
                    );

                    if (data.status === 0) {
                        respMessage += data.body.Status.message;
                        data = await functions.fetch_document_group_link(
                            this.props.selectedAccount.account_schema_name,
                            node.id,
                            this.state.newParentId
                        );
                    }
                } else {
                    data = await functions.fetch_document_group_link(
                        this.props.selectedAccount.account_schema_name,
                        node.id,
                        this.state.newParentId
                    );
                }

            }
         
            if (data.status) {
                respMessage += data.msg;
                respError = true;
            } else {
                respMessage += data.body.Status.message;
            }

        } catch (error) {
            respMessage += error.toString();
            respError = true;
        }
        this.setState({ buttonText: "Move" });
        if (respError) {
            this.setState({ respMessage: respMessage, respMessageType: "Error" });
        } else {
            this.setState({ respMessage: respMessage, respMessageType: "Ok" });
        }
    }

    closeMove = async () => {
        await this.props.toolBarDisplayOption(false, "moveData");
        await this.props.refresh();
    }

    render() {
        let dataType = "Document Group";
        if (this.props.selectedNodes[0]) {
            if (this.props.selectedNodes[0].type === 'doc') {
                dataType = "Document";
            }
        }
        return (
            <div className="moveDataContainer">
                <FocusLock>
                    <div className="moveDataName">
                        <label className="moveDataLabelKey">Selected {dataType}:</label>
                        <label className="moveDataLabelValue">{this.state.dataName}</label>
                    </div>
                    <div className="moveDataCurrentParent">
                        <label className="moveDataCurrParKey">Current Parent:</label>
                        {this.state.currentParent ? 
                            <label className="moveDataCurrParValue">{this.state.currentParent.label}</label>
                            :
                            <label className="moveDataCurrParValue">Root</label>
                        }
                    </div>
                    <div className="moveDropDownContainer">
                        <label className="moveDropDownLabel">New Parent:</label>
                        <select className="moveDropDown" onChange={this.changeHandler} value={this.state.newParentId}>
                            {this.props.selectedNodes[0].type === "dg" ? 
                                <option>Root</option> 
                            :   null}
                            {this.parentOptions}
                        </select>                    
                    </div>
                    {this.state.respMessage ?
                        <div className={`dataRespMessage ${this.state.respMessageType}`}>
                            {this.state.respMessage}
                        </div>
                        :
                        null
                    }
                    <div className="moveDataButtonsContainer">
                        <AppButton enabled={true} onClick={this.submit} tabIndex={1}>
                            {this.state.buttonText}
                        </AppButton>
                        <AppButton enabled={true} onClick={this.closeMove} tabIndex={2}>
                            Close
                        </AppButton>
                    </div>
                </FocusLock>
            </div>
        )
    }
}

export default DataRegistry;