import React from 'react';
import sortArrow from '../../../images/HeaderDropDown.png';
import {
    formatDates,
    createTableDescriptions,
    sortNamesGlobal,
    sortDateGlobal,
} from '../../../global/functions';

const SHORT_NAME_COLUMN = 'account_short_name';
const FULL_NAME_COLUMN = 'account_full_name';
const PROFILE_COLUMN = 'account_properties';
const CREATED_COLUMN = 'account_created';
const UPDATED_COLUMN = 'account_updated';


class AccountTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            isLoaded: true,
            sortByColumn: SHORT_NAME_COLUMN,
            sortDecending: false,
        };
    }

    componentDidUpdate() {

        if (this.props.data !== this.state.tableData) {
            this.sortData(this.state.sortByColumn, this.state.sortDecending, this.props.data);
        }
    }


    onSelectAccount = (e, account) => {
        const checked = e.currentTarget.checked;
        const id = account.account_id;

        if (!this.props.allowMultipleSelection) {
            for (const i in this.props.selectedAccounts) {
                delete this.props.selectedAccounts[i];
            }
        }

        if (checked) {
            this.props.selectedAccounts[id] = checked;
        } else {
            delete this.props.selectedAccounts[id];
        }

        this.setState({ rerender: true });

        this.props.onSelectedAccount(account.account_id, e.currentTarget.checked);
    };

    onSortDirectionChange = (sortByColumn) => {
        let sortDecending =
            sortByColumn !== this.state.sortByColumn
                ? false
                : !this.state.sortDecending;

        this.setState({
            sortByColumn,
            sortDecending,
        });
        this.sortData(sortByColumn, sortDecending, this.props.data);
    };

    sortData = (sortByColumn, sortDecending, dataToSort) => {
        const tableData =
            sortByColumn === 'account_created' || sortByColumn === 'account_updated'
                ? sortDateGlobal(sortByColumn, sortDecending, dataToSort)
                : sortNamesGlobal(sortByColumn, sortDecending, dataToSort);
        this.setState({
            tableData,
        });
    };

    render() {
        const { sortByColumn, sortDecending, isLoaded } = this.state;
        const data =
            this.state.tableData === null
                ? null
                : this.state.tableData.map((account) => {
                    return (
                        <tr
                            key={account.account_id}
                            id={account.account_id}
                            className='accountListTR'
                        >
                            <td>
                                <input
                                    type='checkbox'
                                    checked={this.props.selectedAccounts[account.account_id] ? true : false}
                                    onChange={(e) => { this.onSelectAccount(e, account); }}
                                />
                            </td>
                            <td className='cell'>{account.account_short_name}</td>
                            <td className='cell'>{account.account_full_name}</td>
                            <td className='cell'>
                                {createTableDescriptions(account.account_properties)}
                            </td>
                            <td className='cell'>{formatDates(account.account_created)}</td>
                            <td className='cell'>{formatDates(account.account_updated)}</td>
                        </tr>
                    );
                });

        return (
            <div className='accountTableContainer'>
                {isLoaded ? (
                    <>
                        <div className='tableFixHead'>
                            <table className='listOfAccountsTable'>
                                <thead>
                                    <tr>
                                        <th className='checkboxColumn'></th>
                                        <th
                                            id={SHORT_NAME_COLUMN}
                                            onClick={() =>
                                                this.onSortDirectionChange(SHORT_NAME_COLUMN)
                                            }
                                        >
                                            Short Name
                                            {sortByColumn === SHORT_NAME_COLUMN ? (
                                                <img
                                                    id={SHORT_NAME_COLUMN}
                                                    src={sortArrow}
                                                    className='accountTableSortArrow'
                                                    alt='sort arrow'
                                                    onClick={() =>
                                                        this.onSortDirectionChange(SHORT_NAME_COLUMN)
                                                    }
                                                    style={{
                                                        transform: sortDecending
                                                            ? 'rotateX(180deg)'
                                                            : 'rotateX(0deg)',
                                                    }}
                                                />
                                            ) : null}
                                        </th>
                                        <th
                                            id={FULL_NAME_COLUMN}
                                            onClick={() =>
                                                this.onSortDirectionChange(FULL_NAME_COLUMN)
                                            }
                                        >
                                            Full Name
                                            {sortByColumn === FULL_NAME_COLUMN ? (
                                                <img
                                                    id={FULL_NAME_COLUMN}
                                                    src={sortArrow}
                                                    className='accountTableSortArrow'
                                                    alt='sort arrow'
                                                    onClick={() =>
                                                        this.onSortDirectionChange(FULL_NAME_COLUMN)
                                                    }
                                                    style={{
                                                        transform: sortDecending
                                                            ? 'rotateX(180deg)'
                                                            : 'rotateX(0deg)',
                                                    }}
                                                />
                                            ) : null}
                                        </th>
                                        <th id={PROFILE_COLUMN}>Properties</th>
                                        <th
                                            id={CREATED_COLUMN}
                                            onClick={() => this.onSortDirectionChange(CREATED_COLUMN)}
                                        >
                                            Created
                                            {sortByColumn === CREATED_COLUMN ? (
                                                <img
                                                    src={sortArrow}
                                                    className='accountTableSortArrow'
                                                    onClick={() =>
                                                        this.onSortDirectionChange(CREATED_COLUMN)
                                                    }
                                                    alt='sort arrow'
                                                    style={{
                                                        transform: sortDecending
                                                            ? 'rotateX(180deg)'
                                                            : 'rotateX(0deg)',
                                                    }}
                                                />
                                            ) : null}
                                        </th>
                                        <th
                                            id={UPDATED_COLUMN}
                                            onClick={() => this.onSortDirectionChange(UPDATED_COLUMN)}
                                        >
                                            Updated
                                            {sortByColumn === UPDATED_COLUMN ? (
                                                <img
                                                    src={sortArrow}
                                                    className='accountTableSortArrow'
                                                    onClick={() =>
                                                        this.onSortDirectionChange(UPDATED_COLUMN)
                                                    }
                                                    alt='sort arrow'
                                                    style={{
                                                        transform: sortDecending
                                                            ? 'rotateX(180deg)'
                                                            : 'rotateX(0deg)',
                                                    }}
                                                />
                                            ) : null}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='scrollable'>{data}</tbody>
                            </table>
                        </div>
                    </>
                ) : null}
            </div>
        );
    }
}

export default AccountTable;