import React from 'react';
import AccountListOptions from './AccountListOptions';
import AccountTable from './AccountTable';
import { removeOpacityDiv } from '../../../global/functions';
import LoadingSpinner from '../../../global/globalComponents/LoadingSpinner';
import './listOfAccounts.css';

import { AppContext } from "../../../global/app-context";
class AccountListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountData: [],
            rowSelected: false,
            selectedAccounts: {},
            msg: '',
            currentColumn: '',
            currentArrowDirection: true,
            loading: true,
            allowMultipleSelection: false,
        };
    }

    componentDidMount() {
        this.loadAccountData();
    }

    loadAccountData = async () => {
        this.setState({ loading: true });
        let msg = null;
        const resp = await this.context.fetch_functions.fetch_whoami();
        if (resp.status) {
            msg = resp.msg;
        } else {
            this.setState({
                msg: msg,
            });

            if (resp.body.Records) {
                this.setState({
                    accountData: resp.body.Records,
                });
            }

        }

        this.setState({ loading: false });
        this.setState({ rerender: true });
    };

    onSelectedAccount = (accountNum, selected) => {
        const rowSelected = Object.keys(this.state.selectedAccounts).length > 0 ? true : false;
        this.setState({
            rowSelected,
        });
    };

    allowMultiple = (allowMultipleSelection) => {
        this.setState({
            allowMultipleSelection,
        });
    };


    render() {
        // just in case it was left by a prev page
        removeOpacityDiv();
        const { accountData, loading, selectedAccounts } = this.state;
        const { userInfo } = this.context;
        const keys = Object.keys(selectedAccounts);
        const idx = accountData.findIndex(x => x.account_id.toString() === keys[0]);
        return (
            <div className='listOfAccounts'>
                {loading ?
                    <LoadingSpinner
                        spinnerText="Loading Accounts"
                        className="accountsLoadingSpinner"
                    /> :
                    <div className='accountListContainer'>
                        <AccountListOptions
                            {...this.props}

                            rowSelected={this.state.rowSelected}
                            selectedAccount={accountData[idx]}
                            userList={this.props.userList}
                            sortAccounts={this.sortAccounts}
                            loadAccountData={this.loadAccountData}
                            // ------
                            userInfo={userInfo}
                        />

                        {this.state.msg ? <div className="clError"> {this.state.msg} </div> : null}

                        <AccountTable
                            assignAccount={this.state.assignAccount}
                            data={accountData}
                            onSelectedAccount={this.onSelectedAccount}
                            selectedAccounts={this.state.selectedAccounts}
                            allowMultipleSelection={this.state.allowMultipleSelection}
                        />

                    </div>
                }
            </div>
        );
    }
}

export default AccountListContainer;

AccountListContainer.contextType = AppContext;