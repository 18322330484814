import React from 'react';
import './userAssignAccount.css';
import './accountAssignUserDetails.css';
import whiteArrowLeft from '../../../../../images/whiteArrowLeft.png';
import AccountTable from '../../../../accounts/accountList/AccountTable';
import SelectedUser from './SelectedUser';
import AssignSearch from '../../../../../global/globalComponents/AssignSearch';
import AssignAccountSubmitForm from '../../../../../global/globalComponents/AssignAccountSubmitForm';
import EditAssignMenu from '../../../../../global/globalComponents/EditAssignMenu';
import {
    viewCardMode,
    sortDescriptionGlobal,
    sortDateGlobal, sortNamesGlobal
} from '../../../../../global/functions';
import AssignAccountConfirm from './AssignAccountConfirm';
import EditAssignConfirm from '../../../../../global/globalComponents/EditAssignConfirm';
import EditAssignRemove from './EditAssignRemove';
import { AppButton } from '../../../../../global/globalComponents/AppButton';
import LoadingSpinner from '../../../../../global/globalComponents/LoadingSpinner';

import { AppContext } from '../../../../../global/app-context';



const ASSIGN_ACCOUNT_VIEW = 'assignAccount';
const EDIT_ASSIGNED_VIEW = 'editAssign';
const REMOVE_ASSIGNED_VIEW = 'removeAssign';

class UserAssignAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            assignAccount: true,
            errorMessage: '',
            responseMessage: '',
            rowSelected: false,
            selectedIndex: null,
            selectedPermission: 'Base',
            radioBase: 'Base',
            radioAdmin: 'Admin',
            accountSearchQuery: '',
            currentListOfAccounts: [],
            userAccounts: [],
            allAccounts: [],
            unassignedAccounts: [],
            matchUserAccountId: [],
            confirmBox: false,
            assignWindow: ASSIGN_ACCOUNT_VIEW,
            disableSubmit: false,
            filteredAccounts: {},
            selectedAccounts: {},
            selectedAccount: {},
            allowMultipleSelection: false,
        };
    }

    async componentDidMount() {
        await this.loadAllAccounts();
        await this.loadUserAccounts();
        this.determineUnassignedAccounts();        
        this.setState({
            currentListOfAccounts: this.state.unassignedAccounts,
        });
        this.sortListOfAccounts("account_short_name", false);
        this.setState({ isLoaded: true });
        this.setState({ rerender: true });
    }

    async loadAllAccounts() {
        try {
            const data = await this.context.fetch_functions.fetch_whoami();

            if (data.status) {
                throw Error(data.msg);
            }

            this.setState({
                allAccounts: data.body.Records,
                msg: null,
            });

        } catch (e) {
            this.setState({ errorMessage: e.message });
        }
    }

    async loadUserAccounts() {
        try {
            const data = await this.context.fetch_functions.fetch_user_accounts(
                this.props.selectedUser.registry_id);

            if (data.status) {
                throw Error(data.msg);
            }

            this.setState({
                userAccounts: data.body,
            });

        } catch (e) {
            this.setState({ errorMessage: e.message });
        }
    }

    determineUnassignedAccounts() {
        // just grab the ids
        const { allAccounts, userAccounts } = this.state;
        const allIds = allAccounts.map(a => a.account_id);
        const userIds = userAccounts.map(a => a.account_id);
        const unassignedIds = allIds.filter(x => !userIds.includes(x));
        const unassignedAccounts = allAccounts.filter(a => unassignedIds.includes(a.account_id));
        this.setState({ unassignedAccounts });
    }

    findMatchingAccounts = (acctMethod = null, newAssign = null) => {
        this.setState({
            currentListOfAccounts: this.state.unassignedAccounts,
        });
    };

    filterMatchedAccounts = (matchedArr, acctMethod = null) => {
        let accounts = [];
        if (acctMethod === 'add' || !acctMethod) {
            accounts = this.state.allAccounts.filter(account => {
                return !matchedArr.some(userAcct => {
                    return account.id === userAcct;
                });
            });
        } else if (acctMethod === 'remove' || 'changeRole') {
            accounts = this.state.allAccounts.filter(account => {
                return matchedArr.some(userAcct => {
                    return account.id === userAcct;
                });
            });
        }
        return accounts;
    };


    selectPermission = e => {
        this.setState({ selectedPermission: e.target.value });
    };

    radioPermission = permission => {
        this.setState({ selectedPermission: permission });
    };

    assignWindows = window => {

        if (window === EDIT_ASSIGNED_VIEW || window === REMOVE_ASSIGNED_VIEW) {
            this.setState({
                currentListOfAccounts: this.state.userAccounts
            });
        } else {
            this.setState({
                currentListOfAccounts: this.state.unassignedAccounts,
            });
        }

        this.setState({ assignWindow: window });
    };

    submitConfirmation = (window) => {
        viewCardMode(true, 'viewAssignCard');
        this.assignWindows(window);
        this.setState({ confirmBox: true });
    };

    confirmBoxSubmit = (view, submitOption = null) => {
        if (!view) {
            this.setState({
                confirmBox: false,
                responseMessage: '',
                errorMessage: ''
            });
            viewCardMode(false, 'viewAssignCard');
        } else if (submitOption) {
            ;
            let submitJson = {
                'user_reg_id': this.props.selectedUser.registry_id,
                'assign_action': submitOption === 'remove' ? 'REVOKE' : 'GRANT',
                'account_id': this.state.selectedAccount.account_id,
                'admin_option': this.state.selectedPermission
            };
            this.submitRequest(submitJson, submitOption);
            this.setState({ disableSubmit: false });
        }
    };

    submitRequest = async (userJson, submitOption) => {
        const data = await this.context.fetch_functions.fetch_role_assign(
            userJson.user_reg_id,
            userJson.assign_action,
            userJson.account_id,
            userJson.admin_option === 'Base' ? false : true);

        let responseMessage, errorMessage;
        if (data.status) {
            errorMessage = data.msg;
        } else {
            await this.loadUserAccounts();
            this.determineUnassignedAccounts();
            this.assignWindows(this.state.assignWindow);
            responseMessage = data.body.Status;
            errorMessage = '';
        }

        this.setState({
            responseMessage,
            errorMessage,
            disableSubmit: false,
        });
        // this.toggleRow();
    };

    handleSearchInput = e => {
        let query = e.target.value;
        this.setState({
            accountSearchQuery: query
        });
        this.filterAccounts(query);
    };

    filterAccounts = query => {
        const { unassignedAccounts } = this.state;
        let filteredAccounts = [];
        if (query.length > 0) {
            filteredAccounts = unassignedAccounts.filter(account => {
                return account.account_full_name.toLowerCase().includes(query.toLowerCase()) ||
                    account.account_short_name.toLowerCase().includes(query.toLowerCase());
            });
        } else {
            this.findMatchingAccounts();
        }
        this.submitSearch(filteredAccounts, query);
    };

    submitSearch = (filteredAccounts = [], query = "") => {
        if (query.length > 0) {
            this.setState({ currentListOfAccounts: filteredAccounts });
        } else {
            this.setState({
                currentListOfAccounts: this.state.unassignedAccounts
            });
        }
    };

    searchOnButtonClick = () => {
        this.filterAccounts(this.state.accountSearchQuery);
    };

    onSelectedAccount = (userNum, selected) => {
        const{currentListOfAccounts} = this.state;
        const idx = currentListOfAccounts.findIndex(x => x.account_id === userNum);
        this.setState({
            rowSelected: selected,
            selectedAccount: currentListOfAccounts[idx],
        });
    };

    sortListOfAccounts = (column, arrowDirection) => {

        const { unassignedAccounts } = this.state;
        let sortedList;
        if (column === 'profile') {
            sortedList = sortDescriptionGlobal(column, arrowDirection, unassignedAccounts);
        } else if (column === 'created' || column === 'updated') {
            sortedList = sortDateGlobal(column, arrowDirection, unassignedAccounts);
        } else {
            sortedList = sortNamesGlobal(column, arrowDirection, unassignedAccounts);
        }
        this.setState({
            currentListOfAccounts: sortedList,
            currentColumn: column,
            currentArrowDirection: arrowDirection
        });
    };
    render() {
        const { userAccounts, selectedAccount,
            selectedPermission, assignWindow, currentListOfAccounts } = this.state;
   
        return (

            <div className='viewAssignCard'>
                <div className='assignClose'>
                    <AppButton
                        enabled={true}
                        onClick={() => this.props.showSelectedDisplay(false, 'assignAccountCard')}
                    >
                        <img
                            className='whiteArrowLeft'
                            src={whiteArrowLeft}
                            alt='back to user table'
                        />
                        Users Table
                    </AppButton>
                </div>
                <div className='assignAccountUserContainer'>
                    {this.state.isLoaded ?
                        <SelectedUser
                            selectedUser={this.props.selectedUser}
                            userAccounts={userAccounts}
                            assignWindows={this.assignWindows}
                            assignWindow={this.state.assignWindow}
                        /> :
                        <div className='assignAccountToUserDetails'></div>
                    }
                </div>
                <div className='assignMenu'>
                    {assignWindow === ASSIGN_ACCOUNT_VIEW ?
                        <>
                            <AssignSearch
                                handleSearchInput={this.handleSearchInput}
                                submitSearch={this.searchOnButtonClick}
                                rowSelected={this.state.rowSelected}
                                selectedAccount={this.state.selectedAccount}
                                assignAccount={this.state.assignAccount}
                            />
                            <>
                                <AssignAccountSubmitForm
                                    submitConfirmation={this.submitConfirmation}
                                    rowSelected={this.state.rowSelected}
                                    selectedUser={this.props.selectedUser}
                                    selectedAccount={this.state.selectedAccount}
                                    selectedPermission={this.state.selectedPermission}
                                    selectPermission={this.selectPermission}
                                    radioBase={this.state.radioBase}
                                    radioAdmin={this.state.radioAdmin}
                                    assignAccount={this.state.assignAccount}
                                    radioPermission={this.radioPermission}
                                />
                            </>
                        </>
                        :
                        <EditAssignMenu
                            assignAccount={this.state.assignAccount}
                            assignWindow={this.state.assignWindow}
                            assignWindows={this.assignWindows}
                            rowSelected={this.state.rowSelected}
                            selectPermission={this.selectPermission}
                            selectedPermission={this.state.selectedPermission}
                            submitConfirmation={this.submitConfirmation}
                            selectedUser={this.props.selectedUser}
                            userAccounts={userAccounts}
                            selectedAccount={this.state.selectedAccount}
                            removeConfirmation={this.removeConfirmation}
                            radioBase={this.state.radioBase}
                            radioAdmin={this.state.radioAdmin}
                            radioPermission={this.radioPermission}
                        />
                    }
                </div>
                <div className='assignPaddingDiv'>
                    <div className='assignTable'>
                        {this.state.isLoaded ?
                            (currentListOfAccounts.length > 0) ?
                                <AccountTable
                                    assignAccount={this.state.assignAccount}
                                    data={currentListOfAccounts}
                                    onSelectedAccount={this.onSelectedAccount}
                                    selectedAccounts={this.state.selectedAccounts}
                                    allowMultipleSelection={this.state.allowMultipleSelection}
                                /> : null

                            :
                            <LoadingSpinner />
                        }
                    </div>
                </div>
                {this.state.confirmBox ?
                    <>
                        {assignWindow === ASSIGN_ACCOUNT_VIEW ?
                            <AssignAccountConfirm
                                selectedAccount={selectedAccount}
                                selectedPermission={selectedPermission}
                                selectedUser={this.props.selectedUser}
                                confirmBoxSubmit={this.confirmBoxSubmit}
                                responseMessage={this.state.responseMessage}
                                errorMessage={this.state.errorMessage}
                                disableSubmit={this.state.disableSubmit}
                            />
                            : null
                        }
                        {assignWindow === EDIT_ASSIGNED_VIEW ?
                            <EditAssignConfirm
                                selectedAccount={selectedAccount}
                                selectedPermission={selectedPermission}
                                selectedUser={this.props.selectedUser}
                                confirmBoxSubmit={this.confirmBoxSubmit}
                                responseMessage={this.state.responseMessage}
                                errorMessage={this.state.errorMessage}
                                disableSubmit={this.state.disableSubmit}
                            />
                            : null
                        }
                        {assignWindow === REMOVE_ASSIGNED_VIEW ?
                            <EditAssignRemove
                                assignAccount={this.state.assignAccount}
                                assignWindows={this.assignWindows}
                                selectedUser={this.props.selectedUser}
                                selectedAccount={selectedAccount}
                                responseMessage={this.state.responseMessage}
                                errorMessage={this.state.errorMessage}
                                confirmBoxSubmit={this.confirmBoxSubmit}
                                disableSubmit={this.state.disableSubmit}
                            />
                            : null
                        }
                    </>
                    : null
                }
            </div>

        );
    }
}

export default UserAssignAccount;

UserAssignAccount.contextType = AppContext;