import settings from './settings';

const LOGIN_ROUTE = settings.backend_route('/login');
const LOGIN_WITH_SESSION = settings.backend_route('/withsession');
const GENERIC = settings.backend_route('/generic');
const LOGOUT = settings.backend_route('/logout');
const GET_HIERARCHY = settings.backend_route('/get_hierarchy');
const UPLOAD_DATA_FILE = settings.backend_route('/upload_data_file')
const GET_FILES_TO_COMPARE = settings.backend_route('/get_files_to_compare')

export const functions = {

    async fetch_login(req) {
        return mainFetch(LOGIN_ROUTE, req);
    },

    async fetch_login_with_session(req) {
        return mainFetch(LOGIN_WITH_SESSION, req);
    },

    async fetch_logout() {
        return mainFetch(LOGOUT, {});
    },

    // this gives a list of all_accounts (with or without access)
    async fetch_whoami() {
        const request = {
            process: "query",
            params: {
                schema: "users",
                function: "whoami",
                function_arguments: [],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_all_users() {
        const req = {
            process: "view",
            params: {
                schema: "users",
                view: "role_view",
                columns: "registry_id, registry_email, registry_full_name, registry_properties, registry_created, registry_updated, rolvaliduntil",
                clause: "",
                query_arguments: [],
            }
        }
        const resp = await fetch_generic(req);
        return resp;
    },

    async fetch_user_accounts(user_id) {
        const req = {
            process: "view",
            params: {
                schema: "users",
                view: "user_account_view",
                columns: "*",
                clause: `where user_id = %s   `,
                query_arguments: [user_id],
            }
        }
        const resp = await fetch_generic(req);
        return resp;
    },

    async fetch_account_users(account_id) {
        const req = {
            process: "view",
            params: {
                schema: "users",
                view: "user_account_view",
                columns: "*",
                clause: `where account_id = %s   `,
                query_arguments: [account_id],
            }
        }
        const resp = await fetch_generic(req);
        return resp;
    },

    async fetch_role_assign(user_id, assign_action, account_id, admin_option) {
        const request = {
            process: "query",
            params: {
                schema: "users",
                function: "role_assign",
                function_arguments: [user_id, assign_action, account_id, admin_option],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_document_group_insert(schema, name, properties) {
        const request = {
            process: "query",
            params: {
                schema,
                function: "document_group_insert",
                function_arguments: [name, JSON.stringify(properties)],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_registry_insert(short_name, full_name, properties) {
        const request = {
            process: "query",
            params: {
                schema: 'accounts',
                function: "registry_insert",
                function_arguments: [short_name, full_name, properties],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_document_group_link(schema, document_id, document_group_id) {
        const request = {
            process: "query",
            params: {
                schema,
                function: "document_group_link",
                function_arguments: [document_id, document_group_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_document_group_unlink(schema, document_id, document_group_id) {
        const request = {
            process: "query",
            params: {
                schema,
                function: "document_group_unlink",
                function_arguments: [document_id, document_group_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_document_group_parent_link(schema, child_id, parent_id) {
        const request = {
            process: "query",
            params: {
                schema,
                function: "document_group_parent_link",
                function_arguments: [child_id, parent_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_password_set(registry_id, password) {
        const request = {
            process: "query",
            params: {
                schema: 'users',
                function: "password_set",
                function_arguments: [registry_id, password],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },
    async fetch_password_set_self( password) {
        const request = {
            process: "query",
            params: {
                schema: 'users',
                function: "password_set",
                function_arguments:  [password],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },
    
    async fetch_expiry_set(registry_id, password_expires) {
        const request = {
            process: "query",
            params: {
                schema: 'users',
                function: "expiry_set",
                function_arguments: [registry_id, password_expires],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },
    
    async fetch_delete_account(registry_id) {
        const request = {
            process: "query",
            params: {
                schema: 'accounts',
                function: "registry_remove",
                function_arguments: [registry_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_delete_user(registry_id) {
        const request = {
            process: "query",
            params: {
                schema: 'users',
                function: "registry_remove",
                function_arguments: [registry_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_delete_document(schema, document_id) {
        const request = {
            process: "query",
            params: {
                schema: schema,
                function: "document_remove",
                function_arguments: [document_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_delete_document_group(schema, document_id) {
        const request = {
            process: "query",
            params: {
                schema: schema,
                function: "document_group_remove",
                function_arguments: [document_id],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_edit_document(schema, document_id, edit_text, edit_properties) {
        const request = {
            process: "query",
            params: {
                schema: schema,
                function: "document_edit",
                function_arguments: [document_id, edit_text, JSON.stringify(edit_properties)],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_edit_document_group(schema, document_id, edit_text, edit_properties) {
        const request = {
            process: "query",
            params: {
                schema: schema,
                function: "document_group_edit",
                function_arguments: [document_id, edit_text, JSON.stringify(edit_properties)],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_data_download(schema, document_id) {
        const req = {
            process: "view",
            params: {
                schema: schema,
                view: document_id,
                columns: '*',
                clause: "",
                query_arguments: [],
            }
        }
        const resp = await fetch_generic(req);
        return resp;
    },

    async fetch_hierarchy(schema) {
        return await mainFetch(GET_HIERARCHY, {schema})
    },

    async fetch_uploadDataFile(request){
        return await mainFetch(UPLOAD_DATA_FILE, request)
    },

    async fetch_getFilesToCompare(request) {
        return await mainFetch(GET_FILES_TO_COMPARE, request)
    },

    async fetch_user_info (email){
        const request = {
            process: "query",
            params: {
                schema: "users",
                function: "whoami",
                function_arguments: [email],
            }
        }
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_userEditProfile(edit_email, edit_full_name, properties) {
        const request = {
            process: "query",
            params: {
                schema: "users",
                function: "registry_edit",
                function_arguments: [edit_email, edit_full_name, properties],
            }
        }       
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_updateAccount(registry_id, short_name, full_name, properties) {
        const request = {
            process: "query",
            params: {
                schema: "accounts",
                function: "registry_edit",
                function_arguments: [registry_id, short_name, full_name, properties],
            }
        }       
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_user_registry_insert(email, full_name, tags) {
        const request = {
            process: "query",
            params: {
                schema: "users",
                function: "registry_insert",
                function_arguments: [email, full_name, tags],
            }
        }       
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_updateUser(registry_id, email, full_name, tags) {
        const request = {
            process: "query",
            params: {
                schema: "users",
                function: "registry_edit",
                function_arguments: [registry_id, email, full_name, tags],
            }
        }       
        const response = await fetch_generic(request);
        return response;
    },

    async fetch_views_compare(schema, view_1_name, view_2_name, columns) {

        // Convert the columns to the format required by the query
        const view1_rel_cols = [], 
              view2_rel_cols = [],
              view1_comp_cols = [],
              view2_comp_cols = [];
        
        columns[0].forEach(el => {
            view1_rel_cols.push(el[0]);
            view2_rel_cols.push(el[1]);
        });

        columns[1].forEach(el => {
            view1_comp_cols.push(el[0]);
            view2_comp_cols.push(el[1]);
        });
        
        const cols = {
            Relate: {View_1: view1_rel_cols, View_2: view2_rel_cols},
            Compare: {View_1: view1_comp_cols, View_2: view2_comp_cols}
        };

        const cols_string = JSON.stringify(cols)

        const request = {
            process: "query",
            params: {
                schema: schema,
                function: "views_compare",
                function_arguments: [view_1_name, view_2_name, cols_string],
            }
        }       
        const response = await fetch_generic(request);

        return response;
    },

}

const mainFetch = async (route, info) => {
    const resDat = await fetch(route, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(info)
    })
    const jsonDat = await resDat.json();
    return jsonDat;
}

const fetch_generic = async (req) => {
    return mainFetch(GENERIC, req);
}
