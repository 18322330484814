import React from 'react';
import { AppButton } from '../../../../../global/globalComponents/AppButton';

import { AppContext } from '../../../../../global/app-context';


class SelectedUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userAccounts: this.props.userAccounts
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userAccounts !== prevProps.userAccounts) {
            this.setState({ userAccounts: this.props.userAccounts });
        }
    }
    render() {
        const { accountAdmin } = this.context.PERMISSION_LEVELS;
        const { selectedUser, assignWindows, assignWindow } = this.props;
        const { userAccounts } = this.state;
        return (
            <div className='assignDetails'>
                <h2 className='selectedUserName'>
                    {selectedUser.registry_full_name}
                </h2>

                <h3 className='selectedUserInfo'>
                    {selectedUser.registry_email}
                </h3>

                <hr />
                    {userAccounts.length !== 0 ?
                        <>
                            {assignWindow === 'editAssign' ?
                                <AppButton
                                    enabled={true}
                                    className='editAssignToggleBtn'
                                    onClick={() => assignWindows('assignAccount')}
                                >
                                    Back
                                </AppButton>
                                : null
                            }
                            {assignWindow === 'assignAccount' ?
                                <AppButton
                                    enabled={true}
                                    className='editAssignToggleBtn'
                                    securityRequired={accountAdmin}
                                    onClick={() => assignWindows('editAssign')}
                                >
                                    Edit Assignments
                                </AppButton>
                                : null
                            }
                            {assignWindow === 'removeAssign' ?
                                <AppButton
                                    enabled={true}
                                    className='editAssignToggleBtn'
                                    onClick={() => assignWindows('assignAccount')}
                                >
                                    Back
                                </AppButton>
                                : null
                            }
                        </>
                        : null
                    }
                <hr />
                <h2>Assigned Accounts</h2>
                <hr />
                <div className='assignedTableContainer'>
                    <ul className='assignedDetailsList'>
                        {userAccounts.length === 0 ?
                            <li>No accounts have been assigned to user</li>
                            :
                            userAccounts.map((account, index) => (
                                <li key={index}>
                                    {account.account_full_name}{' '}
                                        ({account.account_short_name})
                                    <br />
                                    {account.admin_option === true ?
                                        <span><b>Admin</b></span> : null
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        )
    }
}

export default SelectedUser;

SelectedUser.contextType = AppContext;