// 
// Notes: Example CreateDataGroup
// Use in AccountListOptions
// 
import React, { useContext } from 'react';
import './AppButton.css';

import { AppContext } from '../../global/app-context';

export function AppButton(props) {

    const appContext = useContext(AppContext);
    const { systemAdmin, accountAdmin, baseUser } = appContext.PERMISSION_LEVELS;
    const { user_level } = appContext.userInfo;
    const securityTable = {
        [baseUser]: 0,
        [accountAdmin]: 1,
        [systemAdmin]: 2,
    }
    const onClick = () => props.onClick ? props.onClick() : null;

    let disableButton = false;
    if (props.securityRequired) {
        if (securityTable[user_level] >= securityTable[props.securityRequired]) {
            disableButton = false;
        } else {
            disableButton = true;
        }
    }

    if (!props.enabled) {
        disableButton = true;
    }

    return (
        <>
            <button
                disabled={disableButton}
                title={props.title}
                tabIndex={props.tabIndex}
                className={!disableButton ? `clButtonOn ${props.className}` : `clButtonOff ${props.className}`}
                onClick={onClick}
            >
                {props.children}
            </button>
        </>
    )
}