import React from 'react';
import './workInAccount.css';

import DataOptions from './dataOptions/DataOptions';
import UploadDisplay from './uploadDisplay/UploadDisplay';
import CompareMain from './compare/CompareMain';
import LoadingSpinner from '../../../global/globalComponents/LoadingSpinner';
import { viewCardMode, exportToCsv, removeOpacityDiv, yyyy_mm_dd_time } from '../../../global/functions';
import { AppContext } from '../../../global/app-context';
import DeleteData from './dataOptions/DeleteData';
import MoveData from './dataOptions/MoveData';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import filledCheckBox from '../../../images/filled_checkbox.png';
import checkedBox from '../../../images/checked_box.png';
import triangleRight from '../../../images/triangle_right.png';
import triangleDown from '../../../images/downwards_triangle.png';
import greyFolder from '../../../images/folder_data_group.png';
import neutralCheckbox from '../../../images/neutral_checkbox.png';
import documentData from '../../../images/document_data_group.png';
import queryGroup from '../../../images/report_group_folder.png';
import query from '../../../images/searchIcon.png';
import DataRegistry from './dataOptions/DataRegistry';
import { AppButton } from '../../../global/globalComponents/AppButton';


class WorkInAccount extends React.Component {
    state = {
        isLoaded: false,
        errorMessage: null,
        uploadDisplay: false,
        treeData: [],
        createGroupWindow: false,
        createGroupmessage: '',
        selectedDataGroup: null,
        listDisplayTracker: [],
        sortedDataGroups: null,
        checkedFileStructure: [],
        expandedFileStructure: [],
        fileStructure: [],
        dataStructure: [],
        dataToCompare: null,
        selectedNodes: [],
        dataRegistry: null,
        deleteData: null,
        compareBtnsActive: true,
        compareBtnText: "Done",

        checked: [],
        expanded: [],
        root: [],
        dgNodes: {},

    }

    async componentDidMount() {
        await this.loadData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedDataGroup !== this.state.selectedDataGroup) {
            this.setState({
                checkedFileStructure: [],
                expandedFileStructure: [],
            })
        }
    }

    async loadData() {

        const data = await this.context.fetch_functions.fetch_hierarchy(
            this.props.selectedAccount.account_schema_name);
        
        let msg = null;
        let treeData = [];
        if (data.status) {
            msg = data.msg;
        }
        treeData = data.hierarchy;
        this.processTreeStructure(treeData);        

        this.setState({
            isLoaded: true,
            treeData,
            errorMessage: msg,
        });
    }

    refresh = async (msg) => {
        this.setState({ errorMessage: msg, checked: [], selectedNodes: [], isLoaded: false });
        await this.loadData();
    }

    toolBarDisplayOption = (view, chosenDisplay) => {
        viewCardMode(view, 'workInAccount')
        this.setState({ [chosenDisplay]: view })
    }

    queries = [];
    queryValues = [];
    doCompare = async (queries, queryValues) => {
        try {
            const res = await this.context.fetch_functions.fetch_getFilesToCompare({
                'schema': this.props.selectedAccount.account_schema_name,
                'files': queries,
            });
            this.queryValues = queryValues;
            this.queries = queries;
            this.setState({ dataToCompare: res });
        } catch (e) {
            this.setState({ errorMessage: e.toString() + ' in doCompare function.' });
        }
    }

    compareDone = async (columns) => {

        if (columns) {
            this.setState({ compareBtnsActive: false, compareBtnText: "Working..." });
            
            const res = await this.context.fetch_functions.fetch_views_compare(
                this.props.selectedAccount.account_schema_name, 
                this.queries[0], 
                this.queries[1], 
                columns
            );

            if (res.status === 0 &&
                res.body.Status.success) {
                const fileName = res.body.Parameters.view_name;
                const comparisonName = `comparison_${yyyy_mm_dd_time()}`;
                const returnVal = await this.compareDownloadHandler(
                        fileName, 
                        comparisonName, 
                        this.state.selectedNodes[0], 
                        this.state.selectedNodes[1],
                        columns
                    );
                if (returnVal === 0) {
                    this.setState({ errorMessage: "Compare downloaded" });
                }
            } else {
                this.setState({ errorMessage: this.determineCompareMsg(res) });
            }
        }
        this.setState({ dataToCompare: null, compareBtnsActive: true, compareBtnText: "Done" });
    }

    onExit = () => {
        removeOpacityDiv();
        if (this.state.createGroupWindow || this.state.dataToCompare) {
            this.setState({ createGroupWindow: false, dataToCompare: null });
        } else {
            this.props.showSelectedDisplay(false, 'openAccount');
        }
    }

    determineCompareMsg = res => {
        if (res.Result !== 0) {
            return res.Body;
        }
        if (!res.Body[0].views_compare.Status.success) {
            return res.Body[0].views_compare.Status.message
        }
        return res.Body[0].views_compare.Parameters.view_name
    }

    setExpandedFileStructure = expanded => {
        //for react tree library (CheckBoxTree component)
        this.setState({ expandedFileStructure: expanded })
    }

    downloadFile = async (query, value, file1Info = null, file2Info = null) => {
        const data = await this.context.fetch_functions.fetch_data_download(
            this.props.selectedAccount.account_schema_name, query);

        if (data.status) {
            this.setState({ errorMessage: data.msg });
            return -1;
        } else {
            exportToCsv(value + '.csv', data.body, file1Info, file2Info);
            return 0;
        }
    }

    fileInfoObjectCreator = (node) => {
        return [
            {"Document Group": node.dg_label},            
            {"Document": node.doc_label},
            {"Query Group": node.qg_label},
            {"Query": node.label}
        ]
    }

    singleDownloadHandler = (node) => {
        let fileInfo = this.fileInfoObjectCreator(node);
        fileInfo.unshift({"Report Generated": new Date().toISOString().slice(0, 10)})
        this.downloadFile(node.viewName, node.value, fileInfo)
    }

    compareDownloadHandler = (query, value, node1, node2, comparedColumns) => {
        let file1Info = this.fileInfoObjectCreator(node1);
        file1Info.unshift({"View 1": ""});
        file1Info.unshift({"Comparison Generated": new Date().toISOString().slice(0, 10)});
        let file2Info = this.fileInfoObjectCreator(node2);
        file2Info.unshift({"View 2": ""});
        file2Info.unshift({"----------": "----------"});
        let file1Matched = {"Matched Columns": []};
        let file1Target = {"Target Columns": []};
        let file2Matched = {"Matched Columns": []};
        let file2Target = {"Target Columns": []};
        for (let i = 0; i < comparedColumns[0].length; i++) {
            file1Matched["Matched Columns"].push(comparedColumns[0][i][0]);
            file2Matched["Matched Columns"].push(comparedColumns[0][i][1]);
        }
        for (let i = 0; i < comparedColumns[1].length; i++) {
            file1Target["Target Columns"].push(comparedColumns[1][i][0]);
            file2Target["Target Columns"].push(comparedColumns[1][i][1]);
        }
        file1Info.push(file1Matched);
        file1Info.push(file1Target);
        file2Info.push(file2Matched);
        file2Info.push(file2Target);
        return this.downloadFile(query, value, file1Info, file2Info);
    }

    doClear = () => {
        this.setState({ errorMessage: null });
    }

    setSelectedNodes = nodes => {
        this.setState({ selectedNodes: nodes });
    }
    // ---------------------------------------------

    processTreeStructure(data) {
        this.rootMap = {};
        this.dgNodes = {};
        this.deleted = 1;
        while (this.deleted > 0) {
            this.rootMap = {};
            this.deleted = 0;
            this.recurseTreeStructure(data);
        }
        this.setState({ root: data, dgNodes: this.dgNodes });
    }

    /*
        recurse tree structure to:
            - create root map,
            - assign icons, 
            - remove empty query groups
    */
    recurseTreeStructure(ar) {
        if (ar) {
            ar.sort((a, b) => a.label.localeCompare(b.label));
            let indexToDelete = [];
            ar.forEach((n, i) => {
                this.rootMap[n.value] = n;
                if (n.type === "qg" && n.children.length === 0) {
                    indexToDelete.push(i);
                }
                if (n.type === "dg") {
                    n.icon = <img src={greyFolder} alt='data folder' />
                    this.dgNodes[n.id] = n;
                } else if (n.type === "qg") {
                    n.icon = <img src={queryGroup} alt='query group' />
                } else if (n.type === "q") {
                    n.icon = <img src={query} alt='query' />
                } else if (n.type === "doc") {
                    n.icon = <img src={documentData} alt='document' />
                } else {
                    n.icon = null
                };
                this.recurseTreeStructure(n.children);
            });
            if (indexToDelete !== []) {
                for (let i = indexToDelete.length - 1; i >= 0; i--) {
                    ar.splice(indexToDelete[i], 1);
                    this.deleted++;
                }
            }
        }
    }

    /*
        Only allow one data group to be selected or two queries but not both
    */
    checked = [];
    onChecked = (checked, target) => {

        if (target.checked) {
            this.checked.unshift(target.value);
        } else {
            const i = this.checked.indexOf(target.value);
            this.checked.splice(i,1);
        }

        // 
        // nothing allows 3 items selected
        // 
        if (this.checked.length > 2) {
            this.checked.pop();
        }

        // 
        // Only queries allow two
        // 
        if (this.checked.length > 1) {
            const node = this.rootMap[this.checked[0]];
            if (node.type !== 'q') {
                this.checked.pop();
            }
        }

        // 
        // Both must be queries
        // 
        if (this.checked.length > 1) {
            const node = this.rootMap[this.checked[1]];
            if (node.type !== 'q') {
                this.checked.pop();
            }
        }
        
        // 
        // Create a node array of the selected nodes and send to the parent
        // 
        const selectedNodes = this.checked.map(n => this.rootMap[n]);
        this.setSelectedNodes(selectedNodes);

        this.setState({ checked: [...this.checked] });
    }

    notImplemented = () => {
        this.setState({errorMessage: "Not yet implemented."});
    }

    render() {
        const { uploadDisplay, isLoaded, root, checked, expanded, moveData, dgNodes,
            errorMessage, createGroupWindow, selectedNodes, deleteData, dataRegistry } = this.state;

        let groupNode = null;

        if (selectedNodes.length && (selectedNodes[0].type === 'dg')) {
            groupNode = selectedNodes[0];
        }

        const { selectedAccount } = this.props

        return (
            <div className='workInAccountContainer'>
                {isLoaded ?
                    <>
                        {errorMessage ?
                            <div className='workAccountsLoading'>
                                <p >{this.state.errorMessage}</p>
                                <AppButton enabled={true} onClick={this.doClear}>Clear</AppButton>
                            </div>

                            : <div className='workInAccount'>
                                <DataOptions
                                    selectedAccount={selectedAccount}
                                    onExit={this.onExit}
                                    toolBarDisplayOption={this.toolBarDisplayOption}
                                    selectedNodes={selectedNodes}
                                    doCompare={this.doCompare}
                                    doDelete={this.doDelete}
                                    doEdit={this.doEdit}
                                    singleDownloadHandler={this.singleDownloadHandler}
                                    notImplemented={this.notImplemented}
                                />
                                <>
                                    {moveData ?
                                        <MoveData
                                            selectedNodes={selectedNodes}
                                            selectedAccount={selectedAccount}
                                            dgNodes={dgNodes}
                                            toolBarDisplayOption={this.toolBarDisplayOption}
                                            refresh={this.refresh}
                                        />
                                        :
                                        null
                                    }
                                    {deleteData ?
                                        <DeleteData
                                            selectedNodes={selectedNodes}
                                            selectedAccount={selectedAccount}
                                            toolBarDisplayOption={this.toolBarDisplayOption}
                                            refresh={this.refresh}
                                        />
                                        :
                                        null
                                    }
                                    {dataRegistry ?
                                        <DataRegistry
                                            selectedNodes={selectedNodes}
                                            selectedAccount={selectedAccount}
                                            toolBarDisplayOption={this.toolBarDisplayOption}
                                            refresh={this.refresh}
                                            type={"viewEdit"}
                                        />
                                        :
                                        null    
                                    }
                                    {uploadDisplay ?
                                        <UploadDisplay
                                            toolBarDisplayOption={this.toolBarDisplayOption}
                                            selectedAccount={selectedAccount}
                                            groupNode={groupNode}
                                            refresh={this.refresh}
                                            uploadData={this.props.uploadData}
                                        />
                                        : null
                                    }
                                    {createGroupWindow ?
                                        <DataRegistry
                                            toolBarDisplayOption={this.toolBarDisplayOption}
                                            selectedAccount={selectedAccount}
                                            selectedNodes={selectedNodes}
                                            groupNode={groupNode}
                                            refresh={this.refresh}
                                            type={"create"}
                                        />
                                        : null
                                    }
                                    {this.state.dataToCompare ?
                                        <CompareMain
                                            data={this.state.dataToCompare}
                                            files={this.queryValues}
                                            done={this.compareDone}
                                            compareBtnsActive={this.state.compareBtnsActive}
                                            compareBtnText={this.state.compareBtnText}
                                        />
                                        :
                                        <div className='workInAccountColumns'>
                                            <div style={{ width: '100%', height: '100%', padding: '0 10px' }}>
                                                <div className='fileStructure'>
                                                    <CheckboxTree
                                                        nodes={root}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={this.onChecked}
                                                        onExpand={expanded => this.setState({ expanded })}
                                                        noCascade={true}
                                                        icons={{
                                                            check: <img src={checkedBox} alt='checked' />,
                                                            uncheck: <img src={neutralCheckbox} alt='checkbox' />,
                                                            halfCheck: <img src={filledCheckBox} alt='half checked' />,
                                                            expandClose: <img src={triangleRight} id='tsRightArrow' alt='closed' />,
                                                            expandOpen: <img src={triangleDown} alt='open' />,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            </div>
                        }
                    </>
                    : <LoadingSpinner/>
                }
            </div>
        )
    }
}

export default WorkInAccount;

WorkInAccount.contextType = AppContext;