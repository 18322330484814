import React from 'react';
import { AppButton } from '../globalComponents/AppButton';

class EditAssignRemove extends React.Component {
    render() {
        const { selectedUser, selectedAccount,
            responseMessage, errorMessage, disableSubmit } = this.props
        return (
            <div className='confirmBox'>
                {!responseMessage  ?
                    <>
                        <p>
                            Are you sure you want to remove {' '}
                            {selectedUser.registry_full_name} from {' '} 
                            {selectedAccount.account_full_name}?
                        </p>
                        <div className='confirmBoxBtnDiv'>
                            <AppButton
                                enabled={!disableSubmit}
                                onClick={()=>this.props.confirmBoxSubmit(false)}
                            >
                                Cancel
                            </AppButton>
                            <AppButton
                                enabled={!disableSubmit}
                                onClick={()=>this.props.confirmBoxSubmit(true, 'remove')}
                            >
                                {disableSubmit ? 'Removing...' : 'Remove'}
                            </AppButton>
                        </div>
                    </>
                    :
                    <div className='assignAccountAfterSubmit'>
                        {errorMessage !== '' ? 
                            <p>{errorMessage}</p>
                            :
                            null
                        }
                        {responseMessage ?
                            <p className='assignAccountGranted'>
                                {responseMessage}
                            </p>
                            :
                            null
                        }
                        <AppButton
                            enabled={!disableSubmit}
                            onClick={()=>this.props.confirmBoxSubmit(false)}
                        >
                            Close
                        </AppButton>
                    </div>
                }
            </div>
        )
    }
}

export default EditAssignRemove