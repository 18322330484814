
import React from 'react';
import { AppContext } from '../../global/app-context';

class PlayQuery extends React.Component {

    state = { msg: " ", result: " " };

    queries = [
        { name: "fetch_all_users", query: this.fetch_all_users },
        { name: "fetch_whoami", query: this.fetch_whoami },
        { name: "fetch_generic_account_view", query: this.fetch_generic_account_view },
        { name: "play", query: this.play },
    ]

    async play(context) {
        console.log("Just Playing2", context);
    }

    async fetch_all_users(context) {
        const resp = context.fetch_functions.fetch_all_users();
        // console.log("fetch_all_users", resp);
        return resp;
    }

    async fetch_whoami(context) {
        const resp = context.fetch_functions.fetch_whoami();
        // console.log("fetch_all_users", resp);
        return resp;
    }

    doRunQuery = async (v) => {
        let msg = " ";
        let result = "";
        const index = v.target.getAttribute("index");
        const context = this.context;
        // if (!context.userInfo.registry_id) {
        //     msg = "You must be signed on to run these functions.";
        // } else 
        if (!index) {
            msg = "query not found in list.";
        } else {
            const q = this.queries[index];
            console.log("--- userInfo:", q.name, context.userInfo);
            const resp = await q.query(context);
            result = JSON.stringify(resp, null, 4);
            // console.log(str);
            msg = "Now we do stuff!!!!!!!"
        }
        this.setState({ msg, result });
    }

    render() {
        const display = this.queries.map((q, i) => {
            return <div className="clCard" key={i} index={i}>{q.name}</div>
        })
        return (
            <>
                <h2 className="clMsg">{this.state.msg}</h2>
                <div className="clContainer" onClick={this.doRunQuery}>
                    {display}
                    <div className="clCard">----- just playin past here -----</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                    <div className="clCard">Stuff</div>
                </div>
                <textarea readOnly rows="10" value={this.state.result}></textarea>
            </>
        )
    }
}

export default PlayQuery;

PlayQuery.contextType = AppContext;
