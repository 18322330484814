import React from 'react';
import './accountAssignUser.css';
import SelectedAccount from './SelectedAccount';
import AssignSearch from '../../../../global/globalComponents/AssignSearch';
import AssignAccountSubmitForm from '../../../../global/globalComponents/AssignAccountSubmitForm';
import EditAssignMenu from '../../../../global/globalComponents/EditAssignMenu';
import EditAssignConfirm from '../../../../global/globalComponents/EditAssignConfirm';
import EditAssignRemove from '../../../../global/globalComponents/EditAssignRemove';
import AssignAccountConfirm from '../../../users/userList/displayCards/userAssignAccounts/AssignAccountConfirm';
import UserExpirySet from '../../../users/userList/displayCards/UserExpirySet';
import whiteArrowLeft from '../../../../images/whiteArrowLeft.png';
import { viewCardMode, sortDescriptionGlobal, sortDateGlobal, sortNamesGlobal } from '../../../../global/functions';
import { AppButton } from '../../../../global/globalComponents/AppButton';
import LoadingSpinner from '../../../../global/globalComponents/LoadingSpinner';
import UserTable from '../../../users/userList/UserTable';
import { AppContext } from "../../../../global/app-context";

const ASSIGN_USER_VIEW = 'assignUser';
const EDIT_ASSIGNED_VIEW = 'editAssign';
const REMOVE_ASSIGNED_VIEW = 'removeAssign';


class AccountAssignUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            assignUser: true,
            accountUsers: [], //sidebar assigned users
            unassignedUsers: [],
            currentListOfUsers: [],
            allUsers: [],
            rowSelected: false,
            selectedIndex: null,
            selectedUsers: {},
            selectedUser: {},
            userSearchQuery: '',
            confirmBox: false,
            selectedPermission: 'Base',
            radioBase: 'Base',
            radioAdmin: 'Admin',
            responseMessage: '',
            errorMessage: null,
            disableSubmit: false,
            assignWindow: ASSIGN_USER_VIEW,
            setExpiryDisplay: false,
            allowMultipleSelection: false,
        };
    }

    async componentDidMount() {
        let ok = await this.loadAllUsers();
        if (ok) { ok = await this.loadAccountsData(); };
        if (ok) {
            this.findUnassignedUsers();
            this.updateCurrentUserList(this.state.unassignedUsers);
        }
        this.setState({ isLoaded: true });
        this.setState({ rerender: true });
    }

    loadAllUsers = async () => {
        let ok = true;
        const data = await this.context.fetch_functions.fetch_all_users();
        if (data.status === 0) {
            this.setState({
                allUsers: data.body
            });
        } else {
            ok = false;
            this.setState({
                errorMessage: data.msg
            });
        }
        return ok;
    };

    loadAccountsData = async () => {
        let ok = true;
        const data = await this.context.fetch_functions.fetch_account_users(
            this.props.selectedAccount.account_id);
        if (data.status === 0) {
            this.setState({
                accountUsers: data.body,
                // isLoaded: true
            });
        } else {
            ok = false;
            this.setState({
                errorMessage: data.msg
            });
        }
        return ok;
    };

    updateCurrentUserList = (arrList) => {
        this.setState({ currentListOfUsers: arrList });
    };

    findUnassignedUsers = () => {
        let matchUserIds = [];
        for (let user of this.state.accountUsers) {
            matchUserIds.push(user.user_id);
        }
        let users = [];
        users = this.state.allUsers.filter(user => {
            return !matchUserIds.some(user_id => {
                return user.registry_id === user_id;
            });
        });
        this.setState({ unassignedUsers: users });
    };

    findMatchingUsers = (userMethod = null, newAssign = null) => {
        let matchUserIds = [];
        for (let user of this.state.accountUsers) {
            matchUserIds.push(user.user_id);
        }
        if (userMethod === 'add') {
            matchUserIds.push(newAssign);
        } else if (userMethod === 'remove') {
            let removedUser = matchUserIds.filter(id => id !== newAssign);
            matchUserIds = removedUser;
        }

        let unassignedUsers = this.filterMatchedUsers(matchUserIds, userMethod);
        this.setState({ currentListOfUsers: unassignedUsers });
    };

    filterMatchedUsers = (matchedArr, userMethod = null) => {
        let users = [];
        if (userMethod === 'add' || !userMethod) {
            users = this.state.allUsers.filter(user => {
                return !matchedArr.some(user_id => {
                    return user.registry_id === user_id;
                });
            });
        } else if (userMethod === 'remove' || 'changeRole') {
            users = this.state.allUsers.filter(user => {
                return matchedArr.some(user_id => {
                    return user.registry_id === user_id;
                });
            });
        }
        return users;
    };


    filterUsers = query => {
        const { unassignedUsers } = this.state;
        let filteredUsers = [];
        if (query.length > 0) {
            filteredUsers = unassignedUsers.filter(user => {
                return user.registry_full_name.toLowerCase().includes(query.toLowerCase()) ||
                    user.registry_email.toLowerCase().includes(query.toLowerCase());
            });
        }
        this.submitSearch(filteredUsers, query);
    };

    handleSearchInput = e => {
        let query = e.target.value;
        this.setState({ userSearchQuery: query });
        this.filterUsers(query);
    };

    submitSearch = (filteredUsers = [], query = "") => {
        if (query.length > 0) {
            this.setState({ currentListOfUsers: filteredUsers });
        } else {
            this.updateCurrentUserList(this.state.unassignedUsers);
        }
    };

    searchOnButtonClick = () => {
        this.filterUsers(this.state.userSearchQuery);
    };

    submitConfirmation = (window) => {
        viewCardMode(true, 'viewAssignCard');
        this.assignWindows(window);
        this.setState({ confirmBox: true });
    };

    confirmBoxSubmit = (view, submitOption = null) => {
        if (!view) {
            this.setState({
                confirmBox: false,
                responseMessage: '',
                errorMessage: null,
            });
            viewCardMode(false, 'viewAssignCard');
        } else if (submitOption) {
            let submitJson = {
                'user_reg_id': this.state.selectedUser.registry_id,
                'assign_action': submitOption === 'remove' ? 'REVOKE' : 'GRANT',
                'account_id': this.props.selectedAccount.account_id,
                'admin_option': this.state.selectedPermission === 'Admin' ? true : false,
            };
            this.submitRequest(submitJson, submitOption);
            this.setState({ 
                disableSubmit: true, 
                selectedUsers:{},
            });
        }
    };

    submitRequest = async (userJson, submitOption) => {
        const data = await this.context.fetch_functions.fetch_role_assign(
            userJson.user_reg_id,
            userJson.assign_action,
            userJson.account_id,
            userJson.admin_option
        );

        if (data.status === 0) {
            let updateMatchedUsers = userJson.user_reg_id;
            this.loadAccountsData();
            this.findMatchingUsers(submitOption, updateMatchedUsers);
            this.setState({
                responseMessage: data.body.Status.message,
                disableSubmit: false
            });
        } else {
            this.setState({
                responseMessage: "Error",
                errorMessage: data.msg,
                disableSubmit: false
            });
        }
    };

    radioPermission = permission => {
        this.setState({ selectedPermission: permission });
    };

    assignWindows = window => {
        const { allUsers } = this.state;

        let matchUserIds = [];
        if (window === EDIT_ASSIGNED_VIEW || window === REMOVE_ASSIGNED_VIEW) {
            for (let user of this.state.accountUsers) {
                matchUserIds.push(user.user_id);
            }
            let filteredUsers = allUsers.filter(user => {
                return matchUserIds.some(accountUser => {
                    return user.registry_id === accountUser;
                });
            });
            this.setState({ currentListOfUsers: filteredUsers });
        } else {
            this.findMatchingUsers();
        }
        this.setState({ assignWindow: window });
    };

    selectPermission = e => {
        this.setState({ selectedPermission: e.target.value });
    };

    onSelectedUser = (accountNum, selected) => {
        const{currentListOfUsers} = this.state;
        const idx = currentListOfUsers.findIndex(x => x.registry_id === accountNum);
        this.setState({
            rowSelected: selected,
            selectedUser: currentListOfUsers[idx],
        });
    };

    updateUser = (updateValue) => {
        let updatedList = this.state.currentListOfUsers.map(user =>
            user.registry_id === updateValue.registry_id ?
                user = updateValue : user
        );

        this.setState({ currentListOfUsers: updatedList });
    };

    expirySetDisplay = (displayMode, component) => {
        viewCardMode(displayMode, 'viewAssignCard');
        this.setState({ [component]: displayMode });
    };

    sortListOfUsers = (column, arrowDirection) => {
        const { currentListOfUsers } = this.state;
        let sortedList;
        if (column === 'registry_profile') {
            sortedList = sortDescriptionGlobal(column, arrowDirection, currentListOfUsers);
        } else if (column === 'registry_created'
            || column === 'registry_updated'
            || column === 'rolvaliduntil') {
            sortedList = sortDateGlobal(column, arrowDirection, currentListOfUsers);
        } else {
            sortedList = sortNamesGlobal(column, arrowDirection, currentListOfUsers);

        }
        this.setState({
            currentListOfUsers: sortedList,
            currentColumn: column,
            currentArrowDirection: arrowDirection
        });
    };

    render() {
        const { accountUsers, assignWindow, errorMessage, currentListOfUsers } = this.state;
        // const { userInfo } = this.context;
        return (
            <div className='viewAssignCard'>
                {/* AccountAssignUser */}
                <div className='assignClose'>
                    <AppButton
                        enabled={true}
                        onClick={() => this.props.showSelectedDisplay(false, 'assignUserCard')}
                    >
                        <img
                            className='whiteArrowLeft'
                            src={whiteArrowLeft}
                            alt='back to user table'
                        />
                        Accounts Table
                    </AppButton>
                </div>
                <div className='assignAccountUserContainer'>
                    {this.state.isLoaded ?
                        <SelectedAccount
                            selectedAccount={this.props.selectedAccount}
                            accountUsers={accountUsers}
                            assignWindows={this.assignWindows}
                            assignWindow={this.state.assignWindow}
                        />
                        : null
                    }
                </div>
                <div className='assignMenu'>
                    {assignWindow === ASSIGN_USER_VIEW ?
                        <>
                            <AssignSearch
                                handleSearchInput={this.handleSearchInput}
                                submitSearch={this.searchOnButtonClick}
                                rowSelected={this.state.rowSelected}
                                selectedUser={this.state.selectedUsers}
                                assignUser={this.state.assignUser}
                            />
                            <AssignAccountSubmitForm
                                rowSelected={this.state.rowSelected}
                                assignAccount={this.state.selectedAccount}
                                submitConfirmation={this.submitConfirmation}
                                selectPermission={this.selectPermission}
                                assignUser={this.state.assignUser}
                                selectedPermission={this.state.selectedPermission}
                                radioBase={this.state.radioBase}
                                radioAdmin={this.state.radioAdmin}
                                radioPermission={this.radioPermission}
                            />
                        </>
                        :
                        <EditAssignMenu
                            assignUser={this.state.assignUser}
                            submitConfirmation={this.submitConfirmation}
                            assignWindow={this.state.assignWindow}
                            assignWindows={this.state.assignWindows}
                            selectPermission={this.selectPermission}
                            selectedPermission={this.state.selectedPermission}
                            selectedAccount={this.props.selectedAccount}
                            accountUsers={accountUsers}
                            selectedUser={this.state.selectedUser}
                            rowSelected={this.state.rowSelected}
                            radioBase={this.state.radioBase}
                            radioAdmin={this.state.radioAdmin}
                            radioPermission={this.radioPermission}
                        />
                    }
                </div>
                <div className='assignPaddingDiv'>
                    <div className={this.state.isLoaded ? 'assignTable' : 'assignTableSpinner'}>
                        {this.state.isLoaded ?
                            <>

                                <AppButton
                                    className="expiryButton"
                                    enabled={this.state.rowSelected}
                                    onClick={() => this.expirySetDisplay(true, 'setExpiryDisplay')}
                                >
                                    Set Expiry
                                </AppButton>
                                {errorMessage ?
                                    <div className='clError'>
                                        {errorMessage}
                                    </div>
                                    : null}
                                <UserTable
                                    assignUser={true}
                                    data={currentListOfUsers}
                                    onSelectedUser={this.onSelectedUser}
                                    selectedUsers={this.state.selectedUsers}
                                    allowMultipleSelection={this.state.allowMultipleSelection}
                                />
                            </>
                            :
                            <LoadingSpinner />
                        }
                    </div>
                    {this.state.setExpiryDisplay ?
                        <UserExpirySet
                            selectedUser={this.state.selectedUser}
                            updateUser={this.updateUser}
                            showSelectedDisplay={this.expirySetDisplay}
                        />
                        : null
                    }
                </div>
                {this.state.confirmBox ?
                    <>
                        {assignWindow === ASSIGN_USER_VIEW ?
                            <AssignAccountConfirm
                                selectedAccount={this.props.selectedAccount}
                                selectedPermission={this.state.selectedPermission}
                                selectedUser={this.state.selectedUser}
                                confirmBoxSubmit={this.confirmBoxSubmit}
                                responseMessage={this.state.responseMessage}
                                errorMessage={errorMessage}
                                disableSubmit={this.state.disableSubmit}
                            />
                            : null
                        }
                        {assignWindow === EDIT_ASSIGNED_VIEW ?
                            <EditAssignConfirm
                                assignUser={this.state.assignUser}
                                selectedUser={this.state.selectedUser}
                                selectedPermission={this.state.selectedPermission}
                                confirmBoxSubmit={this.confirmBoxSubmit}
                                responseMessage={this.state.responseMessage}
                                errorMessage={errorMessage}
                                disableSubmit={this.state.disableSubmit}
                            />
                            : null
                        }
                        {assignWindow === REMOVE_ASSIGNED_VIEW ?
                            <EditAssignRemove
                                assignUser={this.state.assignUser}
                                assignWindows={this.assignWindows}
                                selectedUser={this.state.selectedUser}
                                selectedAccount={this.props.selectedAccount}
                                responseMessage={this.state.responseMessage}
                                errorMessage={errorMessage}
                                confirmBoxSubmit={this.confirmBoxSubmit}
                                disableSubmit={this.state.disableSubmit}
                            />
                            : null
                        }
                    </>
                    : null
                }
            </div>
        );
    }
}

export default AccountAssignUser;

AccountAssignUser.contextType = AppContext;