import React from 'react';
import UserExpirySet from './displayCards/UserExpirySet';
import UserAssignAccount from './displayCards/userAssignAccounts/UserAssignAccount';
import { viewCardMode } from '../../../global/functions';
import { AppButton } from '../../../global/globalComponents/AppButton';
import { DeleteUser } from './displayCards/DeleteUser';
import UserProfile from '../../accounts/profile/UserProfile';
import { AppContext } from '../../../global/app-context';
// import {FocusLock} from 'react-focus-lock';

class UserListOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userRegDisplay: false,
            viewProfileDisplay: false,
            setExpiryDisplay: false,
            assignAccountCard: false,
            deleteUser: false,
            rowSelected: this.props.rowSelected,
            assignUser: true,
            selectedUser: null,
            userInfo: null,
            focusIsSet: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedUser) {
            const {
                registry_email,
                registry_full_name,
                registry_properties,
                rolvaliduntil,
            } = this.props.selectedUser;

            let userInfo = {
                email: registry_email,
                name: registry_full_name,
                properties: registry_properties,
                expiry: rolvaliduntil,
            };
            if (this.props.rowSelected !== prevProps.rowSelected) {
                this.setState({
                    userInfo,
                    selectedUser: userInfo,
                    rowSelected: this.props.rowSelected
                });
            }
        } else {
            if (this.props.rowSelected !== prevProps.rowSelected) {
                this.setState({
                    userInfo: null,
                    selectedUser: null,
                    rowSelected: this.props.rowSelected
                });
            }
        }

    }

    showSelectedDisplay = (boolView, cardDisplay) => {

        if (cardDisplay === 'userRegDisplay') {

            viewCardMode(boolView, 'App');
            return this.setState({ userRegDisplay: boolView });
        }
        if (this.props.rowSelected) {
            switch (cardDisplay) {
                case cardDisplay:
                    this.setState({ [cardDisplay]: boolView });
                    viewCardMode(boolView, 'App');
                    break;
                default:
                    return null;
            }
        }
        if (this.state.rowSelected === false) {

            this.setState({
                userInfo: {
                    registry_id: null,
                    email: "",
                    name: "",
                    properties: "",
                    expiry: "",
                }
            });
        }

    };

    closeUserReg = () => {
        this.setState({ userRegDisplay: false });
    };

    setFocusOff = () => {
        this.setState({
            focusIsSet: false
        });
    };

    setFocusOn = () => {
        this.setState({
            focusIsSet: true
        });
    };

    closeProfile = () => {
        this.setState({
            userRegDisplay: false,
            viewProfileDisplay: false,
            focusIsSet: false
        });
        viewCardMode(false, 'App');
        this.props.loadUserData();
    };

    render() {
        const { systemAdmin, accountAdmin } = this.context.PERMISSION_LEVELS;
        return (
            <div>
                <div className='userListOptionsContainer'>
                    <div className="userButtonOptions">
                        <AppButton
                            enabled={true}
                            securityRequired={systemAdmin}
                            onClick={() => this.showSelectedDisplay(true, 'userRegDisplay')}
                        >
                            Create User
                        </AppButton>
                        <AppButton
                            enabled={this.state.rowSelected}
                            onClick={() => this.showSelectedDisplay(true, 'viewProfileDisplay')}
                        >
                            View User
                        </AppButton>
                        <AppButton
                            enabled={this.state.rowSelected}
                            securityRequired={accountAdmin}
                            onClick={() => this.showSelectedDisplay(true, 'setExpiryDisplay')}
                        >
                            Set Expiry
                        </AppButton>
                        <AppButton
                            enabled={this.state.rowSelected}
                            securityRequired={accountAdmin}
                            onClick={() => this.showSelectedDisplay(true, 'assignAccountCard')}
                        >
                            Assign Account
                        </AppButton>
                        <AppButton
                            enabled={this.state.rowSelected}
                            securityRequired={systemAdmin}
                            onClick={() => this.showSelectedDisplay(true, 'deleteUser')}
                        >
                            Delete User
                        </AppButton>
                    </div>
                </div>
                <div>
                    {this.state.userRegDisplay ?
                        <UserProfile
                            userInfo={{
                                registry_id: null,
                                email: "",
                                name: "",
                                tags: {},
                                expiry: "",
                            }}
                            create={true}
                            myProfile={false}
                            modifySelf={false}
                            closeProfile={this.closeProfile}

                        />
                        : null
                    }
                    {this.state.viewProfileDisplay ?
                        <UserProfile
                            userInfo={this.state.selectedUser}
                            modifySelf={false}
                            closeProfile={this.closeProfile}
                            create={false}
                            myProfile={false} />
                        : null
                    }
                    {this.state.setExpiryDisplay ?
                        <UserExpirySet
                            selectedUser={this.props.selectedUser}
                            showSelectedDisplay={this.showSelectedDisplay}
                            updateUser={this.props.updateUser}
                            reRenderTable={this.props.reRenderTable}
                        />
                        : null
                    }
                    {this.state.assignAccountCard ?
                        <UserAssignAccount
                            assignUser={this.state.assignUser}
                            selectedUser={this.props.selectedUser}
                            showSelectedDisplay={this.showSelectedDisplay}
                            toggleRow={this.props.toggleRow}
                            accountList={this.props.accountList}
                        />
                        : null
                    }
                    {this.state.deleteUser ?
                        <DeleteUser
                            selectedUser={this.props.selectedUser}
                            showSelectedDisplay={this.showSelectedDisplay}
                            loadUserData={this.props.loadUserData}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }


}

export default UserListOptions;

UserListOptions.contextType = AppContext;