import React from 'react';
import { functions } from '../../../../global/fetch';
import './dataRegistry.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';
import FocusLock from 'react-focus-lock';
import { Tags } from '../../../tag/Tags';

class DataRegistry extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            dataName: '',
            dataProperties: {},
            respMessage: null,
            respMessageType: null,
            buttonText: "",
            type: this.props.type,
        }
    }

    componentDidMount() {
        if (this.props.type === "viewEdit") {
            if (this.props.selectedNodes[0].properties) {
                this.setState({ dataProperties: this.props.selectedNodes[0].properties });
            }
            this.setState({ buttonText: "Update", dataName: this.props.selectedNodes[0].label });
        } else {
            this.setState({ buttonText: "Create" });
        }
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    submit = async () => {
        if (this.props.type === "create") {
            this.setState({ buttonText: "Creating..." });
        } else {
            this.setState({ buttonText: "Updating..." });
        }
        const node = this.props.selectedNodes[0];
        let respMessage = '';
        let respError = false;
        if (this.props.type === "create") {
            try {
                const parentId = this.props.groupNode ? this.props.groupNode.id : null;
                let data = await functions.fetch_document_group_insert(
                    this.props.selectedAccount.account_schema_name,
                    this.state.dataName,
                    this.state.dataProperties
                );
    
                if (data.status === 0) {
                    respMessage += data.body.Status.message;
                    if (parentId) {
                        data = await functions.fetch_document_group_parent_link(
                            this.props.selectedAccount.account_schema_name,
                            data.body.Parameters.document_group_id,
                            parentId
                        );
                    }
                }
                
                if (data.status) {
                    respMessage += data.msg;
                    respError = true;
                } else {
                    respMessage += data.body.Status.message;
                }

            } catch (error) {
                respMessage += error.toString();
                respError = true;
            }
        } else {
            try {
                const func = node.type === "dg" ? functions.fetch_edit_document_group : functions.fetch_edit_document;
                const resp = await func(
                    this.props.selectedAccount.account_schema_name, 
                    node.id,
                    this.state.dataName,
                    this.state.dataProperties,
                );

                if (resp.status) {
                    respMessage += resp.msg;
                    respError = true;
                } else {
                    respMessage += resp.body.Status.message;
                }
            } catch (error) {
                respMessage += error.toString();
                respError = true;
            }
        }
        if (this.props.type === "create") {
            this.setState({ buttonText: "Create" });
        } else {
            this.setState({ buttonText: "Update" });
        }
        if (respError) {
            this.setState({ respMessage: respMessage, respMessageType: "Error" });
        } else {
            this.setState({ respMessage: respMessage, respMessageType: "Ok" });
        }
    }

    closeEdit = async () => {
        if (this.props.type === "create") {
            await this.props.toolBarDisplayOption(false, "createGroupWindow");
        } else {
            await this.props.toolBarDisplayOption(false, "dataRegistry");
        }
        await this.props.refresh();
    }

    render() {
        let dataType = "Document Group";
        if (this.props.selectedNodes[0]) {
            if (this.props.selectedNodes[0].type === 'doc') {
                dataType = "Document";
            }
        } 
        return (
            <div className="dataContainer">
                <h1 className="dataNameDisplay">
                    <div>Data Registry</div>
                </h1>
                <FocusLock>
                    <div className="inputContainer">
                        <label>{dataType} Name: </label>
                        <input
                            type="text"
                            placeholder={`Name`}
                            autoComplete="username"
                            name="dataName"
                            value={this.state.dataName}
                            onChange={this.onChangeHandler}
                            tabIndex={0}
                            autoFocus
                            className="inputField"
                        />

                        <Tags tabIndex={[1, 2, 3, 4, 5]} tags={this.state.dataProperties}/>
                    </div>
                    {this.state.respMessage ?
                        <div className={`dataRespMessage ${this.state.respMessageType}`}>
                            {this.state.respMessage}
                        </div>
                        :
                        null
                    }
                    <div className="dataButtonsContainer">
                        <AppButton enabled={true} onClick={this.submit} tabIndex={6}>
                            {this.state.buttonText}
                        </AppButton>
                        <AppButton enabled={true} onClick={this.closeEdit} tabIndex={7}>
                            Close
                        </AppButton>
                    </div>
                </FocusLock>
            </div>
        )
    }
}

export default DataRegistry;