import React from 'react';
import { functions } from '../../../../global/fetch';
import './deleteUser.css';
import { AppButton } from '../../../../global/globalComponents/AppButton';


export class DeleteUser extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            deleteMessage: 'Are you sure you want to delete this user?'
        }
    }

    confirmedDelete = async () => {
        const resp = await functions.fetch_delete_user(this.props.selectedUser.registry_id);
        if (resp.msg === "Ok") {
            this.props.showSelectedDisplay(false, "deleteUser");
            this.props.loadUserData();
        } else {
            this.setState({ deleteMessage: resp.msg })
        }
    }

    canceledDelete = () => {
        this.props.showSelectedDisplay(false, "deleteUser");
    }

    render() {
        return (
            <div className="deleteUserContainer">
                <div className="userNameDisplay">
                    User selected for deletion: {this.props.selectedUser.registry_full_name}
                </div>
                <div className="deleteMessage">
                    {this.state.deleteMessage}
                </div>
                <div className="deleteUserBtnsContainer">
                    <AppButton enabled={true} onClick={this.confirmedDelete}>
                        Yes
                    </AppButton>
                    <AppButton enabled={true} onClick={this.canceledDelete}>
                        No
                    </AppButton>
                </div>
            </div>
        )
    }
}
