import React from 'react'
// import uuid from 'uuid'

class UploadPreview extends React.Component {
    handleUploadClick = e => {
        this.refs.fileUploader.click()
    }

    render() {
        const { selectedFile, previewMessage, preview,
            selectedHeader, headerRow, uploadOnChange,
            nameRef, 
            // selectedGroup, dataGroups, parentRef,
            groupNode
        } = this.props

        return (
            <>
                <div className='fileUploadDiv'>
                    <div className='selectParent'>
                        <h3>{groupNode.label}</h3>
                        {/* <label 
                        className='selectParentLabel'
                        htmlFor='selectedParent'
                    >
                        * Select Parent Data Group
                    </label><br/> */}
                        {/* <select 
                        className='parentSelectMenu'
                        onChange={this.props.selectParentDropDownChange}
                        name='selectedParent'
                        ref={parentRef}
                        required
                    >
                        <option value={!selectedGroup ? 'Select Data Group' : selectedGroup.group_id}>
                            {!selectedGroup ? 'Select Data Group' : selectedGroup.group_name}
                        </option>
                        {dataGroups.map(group => {
                            return (
                                <option 
                                    key={uuid()}
                                    value={group.group_id}
                                >
                                    {group.group_name}
                                </option>
                            )
                        })}
                    </select> */}
                    </div>
                    <div className='selectFileDiv'>
                        <label
                            className='selectFileLabel'
                            htmlFor='file'
                        >
                            * Select File
                    </label><br />
                        <div
                            className='uploadFileSelect'
                            onClick={this.handleUploadClick}
                        >
                            {selectedFile ? selectedFile.name :
                                'Click here to select a file'
                            }
                        </div>
                        <input
                            id='file'
                            name='userFile'
                            type='file'
                            ref='fileUploader'
                            onChange={uploadOnChange}
                            style={{ display: 'none' }}
                        />
                        <p
                            className='uploadError'
                            style={{
                                color: previewMessage === 'LOADING FILE' ?
                                    '#2556E5' : 'red'
                            }}
                        >
                            {previewMessage !== '' ? previewMessage : null}
                        </p>
                    </div>

                </div>
                <div className='previewSection'>
                    * Select Data File Header
                <div className='previewOuterBorder'>
                        <div className='previewListContainer'>
                            {preview.length !== 0 ?
                                <ul className='previewList'>
                                    {preview.map((data, index) => {
                                        // let itemKey = uuid()
                                        // console.log(data,index);
                                        return (
                                            <li
                                                key={index}
                                                onClick={() => headerRow(index)}
                                                style={{
                                                    backgroundColor: selectedHeader === index ?
                                                        '#C1E7F1' : 'white'
                                                }}
                                            >
                                                {data[0] === null ?
                                                    <div className='blankRow'>

                                                    </div>
                                                    : data}
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                            }
                        </div>
                    </div>
                    <div className='previewHeaderSelection'>
                        <p>Header Selected</p>
                        <div className='currentHeader'>
                            <p>{preview[selectedHeader]}</p>
                        </div>
                    </div>
                    <div className='nameFileDiv'>
                        <label htmlFor='fileName'>*Name File</label>
                        <input
                            type='text'
                            ref={nameRef}
                            id='fileName'
                            name='fileName'
                            placeholder='Name Uploaded File'
                            required
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default UploadPreview