import React from 'react';
import { AppButton } from '../globalComponents/AppButton';
import './EditAssignConfirm.css';

class EditAssignConfirm extends React.Component {
    render() {
        const { selectedPermission, responseMessage, 
            errorMessage, disableSubmit } = this.props;
        return (
            <div className='confirmBox'>
                {responseMessage === '' ?
                <>
                    <p>
                        {selectedPermission==='Base' ?
                            `Are you sure you want to change role from
                            Account Admin to Base User?`
                            :
                            `Are you sure you want to change role from
                            Base User to Account Admin?`
                        }
                    </p>
                    <div className='confirmBoxBtnDiv'>
                        <AppButton 
                            enabled={!disableSubmit}
                            onClick={()=>this.props.confirmBoxSubmit(false)}
                        >
                            Cancel
                        </AppButton>
                        <AppButton 
                            enabled={!disableSubmit}
                            onClick={()=>this.props.confirmBoxSubmit(true, 'changeRole')}
                        >
                            {disableSubmit ? 'Changing...' : 'Change'}
                        </AppButton>
                    </div>
                </>
                    :
                    <div className='assignAccountAfterSubmit'>
                        {errorMessage ? 
                        <p>{errorMessage}</p>
                        :
                        null
                        }
                        {
                            responseMessage.success === true ?
                            <p className='assignAccountGranted'>
                                {responseMessage.message}
                            </p>
                            :
                            <p className='assignAccountFailed'>
                                {responseMessage}
                            </p>
                        }
                        <AppButton
                            enabled={!disableSubmit}
                            onClick={()=>this.props.confirmBoxSubmit(false)}
                        >
                            Close
                        </AppButton>

                    </div>
                }
            </div>
        )
    }
}

export default EditAssignConfirm